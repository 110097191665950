import React, { ReactElement } from 'react';

type Props = {
  leftIcon?: ReactElement | null;
  onClick: () => void;
  rightIcon?: ReactElement | null;
  text: string | null;
};

export default function NavLink(props: Props) {
  const { text, onClick, leftIcon, rightIcon } = props;
  return (
    <button onClick={onClick}>
      <div className="flex items-center">
        {leftIcon || null}
        {text && <p>{text}</p>}
        {rightIcon || null}
      </div>
    </button>
  );
}
