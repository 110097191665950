import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';

import { Header, Footer } from '@frontend/web-components';
import ScrollToTop from './ScrollToTop';

import { ReactComponent as RightArrrowSVG } from 'assets/src/svgs/arrow_right.svg';
import { HomeScreens } from '@frontend/common';

export default function Layout() {
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { pathname } = location;

  const isItParam = (pathUrl: string) => {
    return Object.values(params).some((paramValue) => paramValue && pathUrl.includes(paramValue));
  };

  // Filter out breadcrumbs that should not be shown
  const visibleBreadcrumbs = breadcrumbs.filter((breadcrumb) => !isItParam(breadcrumb.key));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderBreadcrumbItem = (item: any, idx: number) => {
    const { breadcrumb, key } = item;
    const screenName = breadcrumb?.props.children;
    const isCurrent = idx === visibleBreadcrumbs.length - 1;

    return (
      <div key={idx} className="flex items-center">
        <button onClick={isCurrent ? () => null : () => navigate(key)} disabled={isCurrent}>
          <p
            className={
              isCurrent
                ? 'text-PLACEHOLDER font-semibold text-xs capitalize'
                : 'text-black underline underline-offset-4 font-semibold text-xs capitalize'
            }>
            {screenName}
          </p>
        </button>
        {!isCurrent && <RightArrrowSVG className="h-[12px] px-1" />}
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    if (pathname === HomeScreens.DASHBOARD) {
      return null;
    }

    return (
      <div className="page-container flex">{visibleBreadcrumbs.map(renderBreadcrumbItem)}</div>
    );
  };

  return (
    <div className="app">
      <ScrollToTop />
      <Header />
      <main>
        {renderBreadcrumbs()}
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
