import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';

// SVGs
import { ReactComponent as AlertSVG } from 'assets/src/svgs/alert.svg';
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close_circle.svg';

import Button from '../Button/Button';
import { FIELD_LABELS } from '@frontend/common';

type Card = {
  alert_id: string;
  message: string;
  property_id: number;
};

type Props = {
  cardStyle?: string;
  cards: Card[];
  goToAlertNotices: (alertId: string, propertyNum: number) => void;
  removeCard: (id: string) => void;
};

const PROGRESS_DURATION = 5000;

function AlertCardCarousel({ cards, removeCard, goToAlertNotices }: Props) {
  const alertCardRef = useRef<HTMLDivElement>(null);
  const [alertCardHeight, setAlertCardHeight] = useState<number>(0);

  const [progress, setProgress] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const parentHeight = alertCardRef.current?.clientHeight;
    // Set the height of the child div based on the parent's height
    if (parentHeight) {
      setAlertCardHeight(parentHeight);
    }
  }, [alertCardRef.current]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 100 / (PROGRESS_DURATION / 100);
        if (newProgress >= 100) {
          clearInterval(timer); // Stop the timer
          moveToNextCard(); // Move to the next card
          return 0; // Reset progress to 0 for the next card
        }
        return newProgress;
      });
    }, 100);

    return () => clearInterval(timer);
  }, [currentIndex]);

  const moveToNextCard = () => {
    const nextCard = currentIndex === cards.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextCard);
  };

  return (
    <div
      className="flex justify-center w-full relative overflow-hidden"
      style={{ height: alertCardHeight }}>
      {cards.map((card, index) => (
        <Transition
          key={card.alert_id}
          show={currentIndex === index}
          enter="transform transition ease-in-out duration-500"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="w-full  absolute">
          <div
            ref={alertCardRef}
            className="h-full p-4 bg-PRIMARY border border-gray-300 shadow-lg rounded-lg">
            <div className="flex items-center mb-4">
              <AlertSVG fill="white" className="w-16 h-16" />
              <div className='ml-4 w-full'>
                <div className="flex items-center justify-between">
                  <p className="text-white font-bold px-2 text-lg">{FIELD_LABELS.ALERT}</p>
                  <button onClick={() => removeCard(card.alert_id)}>
                    <CloseSVG className="w-5" />
                  </button>
                </div>
                <div className={`font-medium text-xs sm:text-sm text-white font-inter px-2 mr-5`}>
                  {card.message}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center sm:ml-20 mr-5">
              <div className="flex items-center gap-1">
                {cards.map((_, index) => (
                  <div key={index} className="bg-gray-200 w-8 h-1 rounded">
                    <div
                      className="bg-white h-1"
                      style={{ width: index === currentIndex ? `${progress}%` : '0%' }}></div>
                  </div>
                ))}
              </div>
              <Button
                variant="secondary"
                customClasses="flex"
                onClick={() => goToAlertNotices(card.alert_id, card.property_id)}>
                {FIELD_LABELS.VIEW}
              </Button>
            </div>
          </div>
        </Transition>
      ))}
    </div>
  );
}

export default AlertCardCarousel;
