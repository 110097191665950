import React, { useEffect } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { dispatch, setSecrets } from '@frontend/redux';
import { useLoader } from './useLoader';

export const useSetAPICreds = ({ apiKey, baseUrl }: { apiKey: string; baseUrl: string }) => {
  const { isLoading, startLoading, endLoading } = useLoader();
  const setApiCreds = async () => {
    startLoading();
    await dispatch(setSecrets({ apiKey, baseUrl }));
    endLoading(true);
  };
  useEffect(() => {
    setApiCreds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    isLoading,
  };
};
