import { PaymentInfo } from '@frontend/redux';

export const getReceiptItem = (item: PaymentInfo) => {

    const dateOfPurchase = item[0].date_of_purchase;
    const totalCredits = item.reduce((acc, curr) => acc + curr.total_credits, 0);
    const noticeCredits = item
        .filter((elem) => elem.credit_type === 'notice')
        .reduce((acc, curr) => acc + curr.total_credits, 0);
    const alertCredits = item
        .filter((elem) => elem.credit_type === 'alert')
        .reduce((acc, curr) => acc + curr.total_credits, 0);
    const totalPayment = item.reduce((acc, curr) => acc + Number(curr.total_amount), 0);
    const paymentResponse = item[0].payment_response;
    const expiry = item[0].expire_month

    return {
        dateOfPurchase,
        totalCredits,
        noticeCredits,
        alertCredits,
        totalPayment: totalPayment.toFixed(2),
        paymentResponse,
        expiry
    };
};