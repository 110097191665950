import { Button, CenterModal, ImageComponent } from '@frontend/web-components';
import { STATIC_TEXT } from '@frontend/common';

// SVG
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';
import { ReactComponent as ViewCreditSVG } from 'assets/src/svgs/view_credit.svg';
import { ReactComponent as AlertCreditSVG } from 'assets/src/svgs/alert_credit.svg';

type Props = {
  closeModal: () => void;
  message: string;
  onPress?: () => void;
  type: 'notice' | 'alert';
  visible: boolean;
};

export default function CreditSuccess(props: Props) {
  const { visible, closeModal, type, message, onPress } = props;

  const renderContent = () => {
    return (
      <div className="flex flex-col items-center justify-center px-6 pt-4 pb-8">
        <div className="flex w-full items-center justify-end">
          <button onClick={closeModal}>
            <CloseSVG />
          </button>
        </div>
        {type === 'notice' && <ViewCreditSVG className="w-[70px] h[70px]" />}
        {type === 'alert' && <AlertCreditSVG className="w-[70px] h[70px]" />}
        <p className="text-xl font-semibold py-4">{message}</p>
        <Button onClick={onPress || closeModal}>
          {type === 'notice' ? STATIC_TEXT.VIEW_NOTICE : STATIC_TEXT.VIEW_ALERTS}
        </Button>
      </div>
    );
  };

  return <CenterModal visible={visible} onClose={closeModal} renderContent={renderContent} />;
}
