import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLazyLoading } from '@frontend/utils-hooks';
import { STATIC_TEXT } from '@frontend/common';
import { Notice, checkIfValidApiResponse } from '@frontend/redux';

import NoticeList from '../Notice/List/NoticeList';

type NoticeResponse = { newData: Notice[]; newLastPage: number };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withNoticeList = (getNoticesFunction: any) => {
  return function NoticeListComponent() {
    const location = useLocation();
    const routeParams = location.state || {};

    const { pageTitle, noticeTypes, alertId } = routeParams;
    const [getNotices, { isLoading }] = getNoticesFunction();

    const getNoticesWrapper = async (page: number): Promise<NoticeResponse> => {
      let payload = { page, notice_types: undefined, alertId: undefined };

      if (noticeTypes) {
        payload = { ...payload, notice_types: noticeTypes };
      }

      if (alertId) {
        payload = { ...payload, alertId };
      }

      const res = await checkIfValidApiResponse(getNotices, payload);

      if (res && res.isSuccess) {
        const data = res.data.data;
        return {
          newData: page === 1 ? data : [...notices, ...data],
          newLastPage: res.data.meta.last_page,
        };
      } else {
        const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
        throw new Error(message);
      }
    };

    const {
      data: notices,
      loading: loadingMore,
      error: loadMoreError,
      loadMore: loadNotices,
      isFirstPage,
      isLastPage,
    } = useLazyLoading(getNoticesWrapper);

    useEffect(() => {
      loadNotices(true);
    }, []);

    return (
      <div className='page-container'>
        <h3>{pageTitle}</h3>
        <NoticeList
          notices={notices as Notice[]}
          loading={isLoading}
          loadMoreNotices={loadNotices}
          loadingMore={loadingMore}
          pagination={true}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          errorMessage={loadMoreError}
        />
      </div>
    );
  };
};

export default withNoticeList;
