import { Middleware } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { ACTION_TYPES } from '../../actionTypes';

// Define a type for the API list parameter, assuming all your APIs conform to a similar structure
type ApiSlice = {
    util: {
        resetApiState: () => AnyAction;
    };
};

// Custom middleware to reset state on logout
export const resetMiddleware = (apiList: ApiSlice[]): Middleware => {
    return ({ dispatch }) => (next) => (action) => {
        if (action.type === ACTION_TYPES.CLEAR_ALL_STATE) {
            // Correctly invoke resetApiState for each API slice
            apiList.forEach(api => {
                // Dispatch the thunk action creator
                dispatch(api.util.resetApiState());
            });
            // No need to dispatch the result of resetApiState
        }

        return next(action);
    };
};