export enum ColorPalette {
  ALT_GREY =  '#EAF2FC',
  BLACK = '#000',
  DISABLED = '#D3D3D3',
  DISABLED_TEXT = '#969696',
  GOLD = '#E59330',
  GRAY_BG = '#D9D9D9',
  GREEN = '#278E44',
  INACTIVE = '#8E8E8F',
  INACTIVE_GREY = 'rgba(0, 0, 0, 0.1)',
  PLACEHOLDER = 'rgba(0, 0, 0, 0.50)',
  PRIMARY = '#E31E24',
  PRIMARY_TINT = 'rgba(227, 30, 36, 0.10)',
  PRIMARY_TINT_BG = 'rgba(227, 30, 36, 0.40)',
  PURPLE = '#E6E6FA',
  RED = '#C84B4B',
  RED_BG = 'rgba(200, 75, 75, 0.25)',
  SECONDARY = '#3C3C3C',
  SECONDARY_TEXT = 'rgba(0, 0, 0, 0.75)',
  TEXT_BLUE = '#356EFF',
  TRANSPARENT = 'transparent',
  WA_GREEN = '#25d366',
  WHITE = '#FFF'
}
