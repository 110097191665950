import React, { useEffect, useState } from 'react';
import noImage from 'assets/src/images/no_image.png';

type Props = {
  blurHeight?: number;
  customImageStyles: string;
  imageResizeMode?: string;
  imageSource: string | null;
  testID?: string;
};

export function ImageComponent({
  imageResizeMode = 'contain',
  customImageStyles,
  imageSource,
  testID,
  blurHeight = 0,
}: Props) {
  const src = !imageSource || imageSource === '' ? noImage : imageSource;
  const [source, setSource] = useState(src);

  useEffect(() => {
    const src = !imageSource || imageSource === '' ? noImage : imageSource;
    setSource(src);
  }, [imageSource]);

  const handleError = () => {
    setSource(noImage); // Set fallback image upon error
  };

  const resizeModeClass = imageResizeMode === 'cover' ? 'object-cover' : 'object-contain';

  const containerStyles = `relative ${customImageStyles}`;
  const blurImageStyles = `${resizeModeClass} filter blur-lg`;
  const blurContainerStyles = `absolute bottom-0 ${blurImageStyles} w-full`;

  if (source === noImage) {
    return <img src={source} style={{ objectFit: 'contain' }} className={customImageStyles} alt="" />;
  }

  return (
    <div className={containerStyles} data-testid={testID}>
      <img
        src={source}
        className={`${customImageStyles} ${resizeModeClass}`}
        alt=""
        onError={handleError}
      />
      {blurHeight > 0 && (
        <img
          src={source}
          className={`${blurContainerStyles} object-cover`}
          style={{ height: `${blurHeight * 100}%` }}
          alt=""
        />
      )}
    </div>
  );
}
