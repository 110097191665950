import React from 'react';

import { CenterModal } from '@frontend/web-components';
import { FIELD_LABELS, PAGE_TITLES, STATIC_TEXT, CONSTANTS } from '@frontend/common';
import { ReceiptType } from '@frontend/redux';

// SVG
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';

import styles from '../Transactions.module.css';

type Props = {
  closeModal: () => void;
  receiptItem: ReceiptType | null;
};

export default function Reciept({ receiptItem, closeModal }: Props) {
  if (!receiptItem) {
    return null;
  }

  const { totalCredits, noticeCredits, alertCredits, totalPayment, paymentResponse, expiry } = receiptItem;

  const renderPaymentDetail = (fieldName: string, value: string) => {
    return (
      <div className={styles.receiptDetailBox}>
        <p className='text-xs font-light mb-2'>{fieldName}:</p>
        <p className='text-xs font-medium'>{value}</p>
      </div>
    );
  };

  const renderCreditDetail = (fieldName: string, value: string) => {
    return (
      <div className='flex justify-between py-1'>
        <p className='text-xs font-light'>{fieldName}:</p>
        <p className='text-xs font-medium'>{value}</p>
      </div>
    );
  };

  const renderReceipt = () => {
    return (
      <div className='flex flex-col w-full items-center p-4'>
        <div className='flex w-full items-center justify-end'>
            <button onClick={closeModal}>
                <CloseSVG className='w-[24px]' />
            </button>
        </div>
        <p className='text-sm mx-auto'>{PAGE_TITLES.YOUR_RECEIPTS}</p>
        <div className='py-4 flex flex-col items-center w-full'>
            <p className='font-semibold text-lg text-black text-center'>{FIELD_LABELS.TOTAL_PAYMENT}</p>
            <p className='font-semibold text-lg text-PRIMARY text-center'>{`\u20B9 ${totalPayment}`}</p>
        </div>

        <div className={styles.receiptDetailsContainer}>
          {renderPaymentDetail(FIELD_LABELS.TRANSACTION_ID, paymentResponse?.txn_id || '-')}
          {renderPaymentDetail(FIELD_LABELS.TRANSACTION_TIME, paymentResponse?.txn_time || '-')}
          {renderPaymentDetail(FIELD_LABELS.PAYMENT_METHOD, paymentResponse?.payment_method || '-')}
          {renderPaymentDetail(
            STATIC_TEXT.TOTAL_CREDITS_BROUGHT,
            `${alertCredits} Alerts + ${noticeCredits} Views`
          )}
        </div>
        <div className='w-full'>
            <p className='text-sm font-bold'>
            {STATIC_TEXT.TOTAL_CREDITS_BROUGHT}: {totalCredits}
            </p>
            {renderCreditDetail(FIELD_LABELS.NOTICE_VIEW_CREDITS, noticeCredits.toString())}
            {renderCreditDetail(FIELD_LABELS.EXPIRY, CONSTANTS.VIEW_CREDITS_EXPIRY)}
            {renderCreditDetail(FIELD_LABELS.ALERT_CREDITS, alertCredits.toString())}
            {(expiry > 0) && renderCreditDetail(FIELD_LABELS.EXPIRY, `${expiry} ${STATIC_TEXT.ALERT_CREDITS_EXPIRY}`)}
        </div>
      </div>
    );
  };

  return <CenterModal visible={!!receiptItem} onClose={closeModal} renderContent={renderReceipt} />;
}
