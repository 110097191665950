import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS } from '../../types/endpoints';
import { FetchMethods } from '../../types/common';
import {
  DashboardResponse,
  DashboardNewsResponse,
  AppVersionResponse,
  DashboardLocsRequest
} from '../../types/dashboard';
import { NoticeListResponse, NoticeSearchParams, NoticeByTypeRequest } from '../../types/notice';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';
import { MasterDataResponse } from '../../types/master';

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: createDynamicBaseQuery(),
    endpoints: (builder) => ({
        getDashboardMain: builder.query<DashboardResponse, void>({
            query: () => ({
                url: STATIC_URL_ENDPOINTS.DASHBOAD_MAIN,
                method: FetchMethods.GET,
            }),
        }),
        getMostViewedNotices: builder.mutation<NoticeListResponse, NoticeSearchParams>({
            query: (params) => ({
                url: STATIC_URL_ENDPOINTS.MOST_VIEWED_NOTICES,
                method: FetchMethods.GET,
                params,
            }),
        }),
        getBookmarkedNotices: builder.mutation<NoticeListResponse, NoticeSearchParams>({
            query: (params) => ({
                url: STATIC_URL_ENDPOINTS.BOOKMARKED_NOTICES,
                method: FetchMethods.GET,
                params
            }),
        }),
        getNoticesFromNewspaper: builder.mutation<NoticeListResponse, NoticeSearchParams>({
            query: (params) => ({
                url: STATIC_URL_ENDPOINTS.NEWSPAPER_NOTICES,
                method: FetchMethods.GET,
                params
            }),
        }),
        getNoticesFromNoticeType: builder.mutation<NoticeListResponse, NoticeByTypeRequest>({
            query: ({page, notice_types}) => ({
                url: STATIC_URL_ENDPOINTS.NOTICE_TYPE_NOTICES,
                method: FetchMethods.POST,
                params: {
                    page
                },
                body: {
                    notice_types: JSON.stringify(notice_types)
                },
            }),
        }),
        getDashboardNews: builder.query<DashboardNewsResponse, void>({
            query: () => ({
                url: STATIC_URL_ENDPOINTS.DASHBOAD_NEWS,
                method: FetchMethods.GET,
            }),
        }),
        getPopularLocations: builder.mutation<MasterDataResponse, DashboardLocsRequest>({
            query: ({cities}) => ({
                url: STATIC_URL_ENDPOINTS.DASHBOARD_POP_LOCS,
                method: FetchMethods.POST,
                body: {
                    city_id: JSON.stringify(cities)
                }
            }),
        }),
        getLatestAppVersion: builder.query<AppVersionResponse, void>({
          query: () => ({
            url: STATIC_URL_ENDPOINTS.APP_VERSION,
            method: FetchMethods.GET,
          }),
        }),
  }),
});

export const {
  useGetDashboardMainQuery,
  useGetMostViewedNoticesMutation,
  useGetBookmarkedNoticesMutation,
  useGetNoticesFromNewspaperMutation,
  useGetNoticesFromNoticeTypeMutation,
  useGetDashboardNewsQuery,
  useGetPopularLocationsMutation,
  useGetLatestAppVersionQuery
} = dashboardApi;
