/* eslint-disable @typescript-eslint/no-explicit-any */
/**
Checks if the API response is valid.
@param {Function} callback - The callback function to be called.
@param {unknown} values - The values to be passed to the callback function.
@returns {Promise<APIResponse>} The promise that resolves to the API response.
*/
export const checkIfValidApiResponse = async (
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  values: unknown
): Promise<any> => {
  const res = await callback(values);
  if (res?.error) {
    const errData = res?.error?.data;
    return { isSuccess: false, data: errData, message: errData?.message };
  } else {
    return { isSuccess: true, data: res.data };
  }
};
