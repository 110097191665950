import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import OtpInput from 'react-otp-input';

import { useSignUp } from '@frontend/utils-hooks';
import { getOrCreateBrowserId } from '@frontend/utils-helpers';
import {
  STATIC_TEXT,
  CONSTANTS,
  FIELD_LABELS,
  FIELD_ERRORS,
  REGEX,
  FIELD_PLACEHOLDERS,
} from '@frontend/common';
import { Button, CustomInput } from '@frontend/web-components';
// SVGs
import { ReactComponent as HeroImg2SVG } from 'assets/src/svgs/hero2.svg';
import { ReactComponent as WhatsappSVG } from 'assets/src/svgs/whatsapp.svg';
import styles from '../Auth.module.css';

function SignUpScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = location.state || {};

  const {
    isOtpSent,
    errorMessage,
    sendOtpHandler,
    validateOtpHandler,
    isSendingEmailOtp,
    isValidatingEmailOtp,
    isRegistering,
    lastOtpSentCount,
    isEmailVerified,
    handleRegister,
    apiOtp, // TEMP: Remove this once the SMS service is ready
  } = useSignUp();

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      age: undefined,
      gender: undefined,
      otp: '',
    },
    mode: 'onChange',
  });

  // State
  const [waSelect, setWaSelect] = useState(true);
  const [fieldInFocus, setFieldInFocus] = useState('');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (apiOtp) {
        setValue('otp', apiOtp);
      }
    }
  }, [apiOtp]);

  const handleSendOtp = async () => {
    const email = getValues('email');
    sendOtpHandler(email);
  };

  const handleValidateOtp = async () => {
    const email = getValues('email');
    const otp = getValues('otp');
    const deviceId = await getOrCreateBrowserId();
    const registerForm = {
      age: getValues('age'),
      device_name: deviceId,
      email,
      first_name: getValues('first_name'),
      gender: getValues('gender'),
      last_name: getValues('last_name'),
      mobile_number: routeParams.mobile,
    };
    await validateOtpHandler(email, otp, registerForm);
  };

  const onHandleRegister = async () => {
    const email = getValues('email');
    const deviceId = await getOrCreateBrowserId();
    const registerForm = {
      age: getValues('age'),
      device_name: deviceId,
      email,
      first_name: getValues('first_name'),
      gender: getValues('gender'),
      last_name: getValues('last_name'),
      mobile_number: routeParams.mobile,
    };
    await handleRegister(registerForm);
  };

  const renderInput = (
    field: string,
    fieldName: string,
    value: string,
    onChange: () => void,
    error: string | undefined
  ) => {
    const isFocused = fieldInFocus === field;
    return (
      <div className={`${styles.formInputContainer} ${isFocused ? 'border-2' : 'border'}`}>
        <label htmlFor={field} className={styles.formLabel}>
          {fieldName}
        </label>
        <input
          id={field}
          className={styles.formInput}
          value={value}
          onChange={onChange}
          onFocus={() => setFieldInFocus(field)}
          onBlur={() => setFieldInFocus('')}
          type={field === 'email' ? 'email' : 'text'}
        />
        {!isEmailVerified && field === 'email' && value && !error && (
          <button className={styles.verifyLink} onClick={handleSendOtp}>
            {FIELD_LABELS.VERIFY}
          </button>
        )}
      </div>
    );
  };

  const renderResendOtp = () => {
    const email = getValues('email');
    return (
      <div className={styles.otpContainer}>
        {lastOtpSentCount === 0 && (
          <>
            <p className={styles.termsText}>{STATIC_TEXT.OTP_NOT_RECEIVED}</p>
            <button
              onClick={() => sendOtpHandler(email)}
              disabled={lastOtpSentCount > 0}
              className={`${styles.termsText} ${styles.emailLink}`}>
              {STATIC_TEXT.RESEND_OTP}
            </button>
          </>
        )}
        {lastOtpSentCount > 0 && (
          <p className={styles.termsText}>{`Resend OTP in ${lastOtpSentCount}s`}</p>
        )}
      </div>
    );
  };

  const renderOtpInput = () => {
    return (
      <>
        <div className={styles.otpContainer}>
          <p>{FIELD_LABELS.ENTER_OTP}</p>
          <Controller
            control={control}
            name="otp"
            rules={{
              required: true,
              minLength: CONSTANTS.OTP_LENGTH,
              maxLength: CONSTANTS.OTP_LENGTH,
            }}
            render={({ field: { onChange, value } }) => (
              <OtpInput
                value={value}
                onChange={onChange}
                numInputs={CONSTANTS.OTP_LENGTH}
                renderInput={(props) => (
                  <input {...props} style={undefined} className={styles.otpInput} />
                )}
                shouldAutoFocus
              />
            )}
          />
        </div>
        {renderResendOtp()}
      </>
    );
  };

  const renderWhatsAppCheck = () => {
    return (
      <label className="text-gray-700 text-xs mb-2 flex items-center">
        <input
          type="checkbox"
          checked={waSelect}
          onChange={() => setWaSelect(!waSelect)}
          className="mr-2 leading-tight"
        />
        {STATIC_TEXT.WHATSAPP_UPDATE}
        <WhatsappSVG />
      </label>
    );
  };

  const renderTermsAndConditions = () => {
    return (
      <div className={styles.termsText}>
        {STATIC_TEXT.AGREE_OUR_TC}{' '}
        <a
          href={CONSTANTS.TC_URL}
          className={styles.emailLink}
          target="_blank"
          rel="noopener noreferrer">
          {STATIC_TEXT.TERMS_AND_CONDITIONS}
        </a>
        <br />
        {STATIC_TEXT.CONTACT_OUR_EMAIL}
        <br />
        <a href={`mailto:${CONSTANTS.CONTACT_EMAIL}`} className={styles.emailLink}>
          {CONSTANTS.CONTACT_EMAIL}
        </a>
      </div>
    );
  };

  return (
    <div className="overflow-hidden relative">
      <HeroImg2SVG className={styles.signUpbgImg} />
      <div className={styles.pageContainer}>
        <div>
          <h1>{STATIC_TEXT.HERO_TITLE}</h1>
          <h6>{STATIC_TEXT.HERO_SUBTITLE}</h6>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputCard}>
            <div className={styles.inputCardContent}>
              <h4>{STATIC_TEXT.REGISTER_ACCOUNT}</h4>
              <Controller
                control={control}
                name="first_name"
                rules={{ required: FIELD_ERRORS.FIRST_NAME_REQUIRED }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <CustomInput
                    fieldName={FIELD_LABELS.FIRST_NAME}
                    placeholder={FIELD_PLACEHOLDERS.GENERIC_INPUT}
                    value={value}
                    onChangeText={onChange}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="last_name"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <CustomInput
                    fieldName={FIELD_LABELS.LAST_NAME}
                    placeholder={FIELD_PLACEHOLDERS.GENERIC_INPUT}
                    value={value}
                    onChangeText={onChange}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                rules={{
                  required: FIELD_ERRORS.EMAIL_REQUIRED,
                  pattern: {
                    value: REGEX.EMAIL,
                    message: FIELD_ERRORS.INVALID_EMAIL,
                  },
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <CustomInput
                    fieldName={FIELD_LABELS.EMAIL}
                    placeholder={FIELD_PLACEHOLDERS.GENERIC_INPUT}
                    value={value}
                    onChangeText={onChange}
                    error={error?.message}
                    rightElement={
                      !isEmailVerified && value && !error ? (
                        <button className={styles.verifyLink} onClick={handleSendOtp}>
                          {FIELD_LABELS.VERIFY}
                        </button>
                      ) : undefined
                    }
                  />
                )}
              />
              {isOtpSent && renderOtpInput()}
              {errorMessage && <p className="error-text">{errorMessage}</p>}
              <Button
                disabled={!isValid || (!isOtpSent && !isEmailVerified)}
                onClick={isEmailVerified ? onHandleRegister : handleValidateOtp}
                loading={isRegistering || isSendingEmailOtp || isValidatingEmailOtp}>
                {isOtpSent ? FIELD_LABELS.VERIFY_EMAIL : FIELD_LABELS.REGISTER}
              </Button>
              {renderWhatsAppCheck()}
              {renderTermsAndConditions()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpScreen;
