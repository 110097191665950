/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import {
  checkIfValidApiResponse,
  useGetAdvanceSearchFiltersMutation,
  useGetTalukasMutation,
  useGetVillagesMutation,
} from '@frontend/redux';
import { FIELD_LABELS, FIELD_PLACEHOLDERS, STATIC_TEXT } from '@frontend/common';
import { transformFiltersToStringParams, isDateRangeValid } from '@frontend/utils-helpers';
import { Spinner, CustomDatePicker, Button, CustomInput } from '@frontend/web-components';

import { FilterValues, SelectedValues } from '../types';

// SVG
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';

import styles from '../Search.module.css';

const filterOptions = [
  {
    key: 'city',
    title: FIELD_LABELS.CITY_DISTRICT,
  },
  {
    key: 'taluka',
    title: FIELD_LABELS.TALUKA,
  },
  {
    key: 'village',
    title: FIELD_LABELS.VILLAGE,
  },
  {
    key: 'notice_type',
    title: FIELD_LABELS.NOTICE_TYPE,
  },
  {
    key: 'published_date',
    title: FIELD_LABELS.PUBLISHED_DATE,
  },
  {
    key: 'property_type',
    title: FIELD_LABELS.PROPERTY_TYPE,
  },
  {
    key: 'details',
    title: FIELD_LABELS.DETAILS,
  },
];

type Props = {
  clearFilters: () => void;
  closeFilterPage: () => void;
  filters: Partial<SelectedValues>;
  onApplyFilters: (filters: SelectedValues) => void;
  searchParams: {city?: string, property_type?: string, query: string};
};

export default function FilterPage(props: Props) {
  const { searchParams, filters, onApplyFilters, clearFilters, closeFilterPage } = props;

  const [getAdvanceSearchFilters, { isLoading: isGettingFilters }] =
    useGetAdvanceSearchFiltersMutation();
  const [getTalukas, { isLoading: isGettingTalukas }] = useGetTalukasMutation();
  const [getVillages, { isLoading: isGettingVillages }] = useGetVillagesMutation();

  const [selectedOption, setSelectedOption] = useState<string>('city');
  const [selectedValues, setSelectedValues] = useState<SelectedValues>({
    city: filters.city || [],
    taluka: filters.taluka || [],
    village: filters.village || [],
    notice_type: filters.notice_type || [],
    published_date: filters.published_date || [],
    property_type: filters.property_type || [],
    details: filters.details || [],
  });
  const [filterValues, setFilterValues] = useState<FilterValues | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [publishDateRange, setPublishDateRange] = useState<string[]>(
    filters.published_date || ['', '']
  );
  const [details, setDetails] = useState<string>(filters.details ? filters.details[0] : '');
  const [noticeCount, setNoticeCount] = useState<number>(0);

  useEffect(() => {
    handleGetAdvanceSearchFilters();
  }, [selectedValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedOption === 'village' && selectedValues.taluka.length) {
      handleGetVillages();
    }

    if (selectedOption === 'taluka' && selectedValues.city.length) {
      handleGetTalukas();
    }
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetAdvanceSearchFilters = async () => {
    let payload = {
      ...searchParams
    };

    if (Object.keys(selectedValues).length) {
      const transformedFilters = transformFiltersToStringParams(selectedValues);
      payload = { ...payload, ...transformedFilters };
    }

    const res = await checkIfValidApiResponse(getAdvanceSearchFilters, payload);
    if (res && res.isSuccess) {
      const data = res.data.data;
      if (!filterValues) {
        const newSelectedValues = {
          cities: data.cities || [],
          notice_types: data.notice_types || [],
          property_types: data.property_types || [],
          talukas: [],
          villages: [],
        };
        setFilterValues(newSelectedValues);
      }
      const count = data.total_records || 0;
      setNoticeCount(count);
    } else {
      setErrorMessage(res.message);
    }
  };

  const handleGetTalukas = async () => {
    const selectedCities = selectedValues.city.join(',');
    const res = await checkIfValidApiResponse(getTalukas, { city: selectedCities });
    if (res && res.isSuccess) {
      setFilterValues({ ...filterValues, talukas: res.data.data });
    } else {
      setErrorMessage(res.message);
    }
  };

  const handleGetVillages = async () => {
    const selectedTalukas = selectedValues.taluka.join(',');
    const res = await checkIfValidApiResponse(getVillages, { taluka: selectedTalukas });
    if (res && res.isSuccess) {
      setFilterValues({ ...filterValues, villages: res.data.data });
    } else {
      setErrorMessage(res.message);
    }
  };

  const isValueSelected = (value: any) => {
    const currentValues = selectedValues[selectedOption] || [];
    return currentValues.indexOf(value) > -1;
  };

  const onFilterOptionValueSelect = (value: string) => {
    const newFilters = { ...selectedValues };
    const currentValues = newFilters[selectedOption] || [];
    const valueIndex = currentValues.indexOf(value);
    if (valueIndex > -1) {
      currentValues.splice(valueIndex, 1);
    } else {
      currentValues.push(value);
    }
    newFilters[selectedOption] = currentValues;

    setSelectedValues(newFilters);
  };

  const onDateRangeSelect = (value: string, field: string) => {
    const currentValues = [...publishDateRange];

    if (field === 'start_date') {
      const endDate = currentValues[1] ? new Date(currentValues[1]) : null;
      const incomingStartDate = new Date(value);

      currentValues[0] = value; // Set the start date

      if (!endDate || incomingStartDate > endDate) {
        currentValues[1] = value; // Update end date if it's empty or earlier than start date
      }
    } else if (field === 'end_date') {
      const startDate = currentValues[0] ? new Date(currentValues[0]) : null;
      const incomingEndDate = new Date(value);

      currentValues[1] = value; // Set the end date

      if (!startDate || incomingEndDate < startDate) {
        currentValues[0] = value; // Update start date if it's empty or later than end date
      }
    }

    setPublishDateRange(currentValues);
  };

  const onSaveDateRange = () => {
    const newFilters = { ...selectedValues };
    newFilters.published_date = publishDateRange;
    setSelectedValues(newFilters);
  };

  const onSaveDetails = () => {
    const newFilters = { ...selectedValues };
    newFilters.details = [details];
    setSelectedValues(newFilters);
  };

  const getValuesForSelectedOption = () => {
    switch (selectedOption) {
      case 'city':
        return filterValues?.cities || [];
      case 'taluka':
        return filterValues?.talukas || [];
      case 'village':
        return filterValues?.villages || [];
      case 'notice_type':
        return filterValues?.notice_types || [];
      case 'property_type':
        return filterValues?.property_types || [];
      default:
        return [];
    }
  };

  const renderFilterOption = (option: any) => {
    const selected = selectedOption === option.key;
    let valuesCount = selectedValues[option.key]?.length || 0;
    if (option.key === 'published_date' || option.key === 'details') {
      valuesCount = selectedValues[option.key]?.length ? 1 : 0;
    }
    let disabled = false;
    if (option.key === 'taluka' && !selectedValues.city.length) {
      disabled = true;
    } else if (option.key === 'village' && !selectedValues.taluka.length) {
      disabled = true;
    }

    return (
      <button
        key={option.key}
        className={`${styles.filterOptionContainer} ${selected ? styles.selected : ''} ${
          disabled ? styles.disabled : ''
        }`}
        onClick={() => setSelectedOption(option.key)}
        disabled={disabled}>
        {option.title}
      </button>
    );
  };

  const renderPublishDateRange = () => {
    const validRange = isDateRangeValid(publishDateRange);
    return (
      <div className="px-4">
        <CustomDatePicker
          fieldName={FIELD_LABELS.START_DATE}
          value={publishDateRange[0]}
          onSelect={(date) => onDateRangeSelect(date, 'start_date')}
        />
        <CustomDatePicker
          fieldName={FIELD_LABELS.END_DATE}
          value={publishDateRange[1]}
          onSelect={(date) => onDateRangeSelect(date, 'end_date')}
        />
        <div className="mt-4 flex justify-center">
          <Button onClick={onSaveDateRange} disabled={!validRange}>
            {FIELD_LABELS.SAVE}
          </Button>
        </div>
      </div>
    );
  };

  const renderDetailsOption = () => {
    return (
      <div className="px-4">
        <CustomInput
          placeholder={FIELD_PLACEHOLDERS.TYPE_DETAILS}
          value={details}
          onChangeText={(text) => setDetails(text)}
        />
        <p className="text-xs font-light py-2">{STATIC_TEXT.FILTER_DETAILS_HELPER}</p>
        <div className="mt-4 flex justify-center">
          <Button onClick={onSaveDetails} disabled={!details}>
            {FIELD_LABELS.SAVE}
          </Button>
        </div>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className="p-2">
        <p className="empty-text">{STATIC_TEXT.NO_DATA_FOUND}</p>
      </div>
    );
  };

  const filtersLoading = isGettingFilters || isGettingTalukas || isGettingVillages;

  const renderSelectedOptionValues = () => {
    if (!selectedOption) {
      return null;
    }

    if (filtersLoading) {
      return (
        <div className="flex h-full items-center justify-center">
          <Spinner size="small" />
        </div>
      );
    }

    if (selectedOption === 'published_date') {
      return renderPublishDateRange();
    }

    if (selectedOption === 'details') {
      return renderDetailsOption();
    }

    const values = getValuesForSelectedOption();

    if (!values.length) {
      return renderEmptyMessage();
    }

    return (
      <>
        {values.map((value) => {
          const selected = isValueSelected(value.name);
          return (
            <button
              key={value.id}
              onClick={() => onFilterOptionValueSelect(value.name)}
              className={`${styles.valueTag} ${selected ? styles.selected : ''}`}>
              {value.name}
            </button>
          );
        })}
      </>
    );
  };

  const renderOptionHeader = () => {
    const option = filterOptions.find((elem) => elem.key === selectedOption);
    return (
      <div className={styles.optionHeader}>
        <h6>{option?.title}</h6>
        <button onClick={closeFilterPage}>
          <CloseSVG className='w-[24px]' />
        </button>
      </div>
    );
  };

  return (
    <div className={styles.advFilterContainer}>
      <div className={styles.advFilterOptions}>{filterOptions.map(renderFilterOption)}</div>
      <div className={styles.advFilterValuesContainer}>
        {renderOptionHeader()}
        <div className="overflow-y-auto">{renderSelectedOptionValues()}</div>
      </div>
      <div className={styles.bottomContainer}>
        <h5 className="text-xs font-semibold">
          {isGettingFilters
            ? FIELD_PLACEHOLDERS.FINDING
            : `${noticeCount} ${FIELD_PLACEHOLDERS.NOTICES_FOUND}`}
        </h5>
        <div>
          <Button
            customClasses="bg-black"
            onClick={() => onApplyFilters(selectedValues)}
            disabled={filtersLoading}>
            {FIELD_LABELS.APPLY}
          </Button>
          <button className="text-xs underline px-4" onClick={clearFilters} disabled={filtersLoading}>
            {FIELD_LABELS.CLEAR_FILTERS}
          </button>
        </div>
      </div>
    </div>
  );
}
