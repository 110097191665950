import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducerKeys } from './keys';
import {CreditStatusResponse} from '../api/types/subscription';

interface AuthReduxState {
  creditStatus: CreditStatusResponse | null;
  newUser?: boolean;
  notificationPermission: boolean | null;
  token: string;
}

export const initialAuthState: AuthReduxState = {
  token: '',
  newUser: undefined,
  creditStatus: null,
  notificationPermission: null
};

export const authSlice = createSlice({
  name: ReducerKeys.AUTH_SLICE,
  initialState: initialAuthState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    setNewUser: (state, { payload }: PayloadAction<boolean>) => {
      state.newUser = payload;
    },
    resetAuthState: (state) => {
      state.token = '';
      state.newUser = undefined;
    },
    updateCreditStatus: (state, { payload }: PayloadAction<CreditStatusResponse>) => {
      state.creditStatus = payload;
    },
    setNotificationPermission: (state, { payload }: PayloadAction<boolean>) => {
      state.notificationPermission = payload;
    },
  },
});

export const AuthActions = authSlice.actions;
export default authSlice.reducer;
