import React, { ReactNode } from 'react';
import { store, persistor } from './store.utils';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

interface Props {
  children: ReactNode;
}
export function ReduxWrapper({ children }: Props) {
  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}

export default ReduxWrapper;
