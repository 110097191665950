import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  AlertsScreens,
  BookmarksScreens,
  FIELD_LABELS,
  NoticeScreens,
  PAGE_TITLES,
  ProfileScreens,
  PropertyScreens,
  STATIC_TEXT,
  SearchScreens,
} from '@frontend/common';
import {
  ACTION_TYPES,
  AuthActions,
  SearchActions,
  checkIfValidApiResponse,
  useUserInfoQuery,
  useUserLogoutMutation,
} from '@frontend/redux';
import { Button, Spinner, ReportConcern } from '@frontend/web-components';

import { ReactComponent as UserSVG } from 'assets/src/svgs/user.svg';
import { ReactComponent as AccountSVG } from 'assets/src/svgs/profile_account.svg';
import { ReactComponent as MyNoticesSVG } from 'assets/src/svgs/profile_notices.svg';
import { ReactComponent as TransactionSVG } from 'assets/src/svgs/profile_money.svg';
import { ReactComponent as PropertiesSVG } from 'assets/src/svgs/profile_props.svg';
import { ReactComponent as InterestsSVG } from 'assets/src/svgs/profile_interests.svg';
import { ReactComponent as BookmarksSVG } from 'assets/src/svgs/profile_bookmarks.svg';
import { ReactComponent as AlertsSVG } from 'assets/src/svgs/profile_alerts.svg';
import { ReactComponent as HistorySVG } from 'assets/src/svgs/profile_history.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/src/svgs/arrow_right.svg';
import { ReactComponent as HelpCenterSVG } from 'assets/src/svgs/help_center.svg';

import styles from '../Profile.module.css';
import PersonalInfo from './PersonalInfo';
import MyInterests from './Interests';

export default function ProfileScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: userInfo,
    isLoading: isGettingUserInfo,
    refetch: refreshUserInfo,
    error: userInfoError,
  } = useUserInfoQuery();
  const [userLogout, { isLoading: isLoggingOut }] = useUserLogoutMutation();

  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [openInterests, setOpenInterests] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const goToTransations = () => navigate(ProfileScreens.TRANSACTIONS);
  const goToMyNotices = () => navigate(NoticeScreens.MY_NOTICES);
  const goToMyProperties = () => navigate(PropertyScreens.MY_PROPERTIES);
  const goToAlerts = () => navigate(AlertsScreens.ALERTS);
  const goToBookmarks = () => navigate(BookmarksScreens.BOOKMARKS);
  const goToSearchHistory = () => navigate(SearchScreens.HISTORY);

  const handleLogout = async () => {
    const res = await checkIfValidApiResponse(userLogout, {});
    if (res.isSuccess) {
      dispatch(AuthActions.resetAuthState());
      dispatch(SearchActions.resetSearchState());
      dispatch({ type: ACTION_TYPES.CLEAR_ALL_STATE });
    }
  };

  const renderMainOptions = () => {
    return (
      <div className={styles.mainOptionContainer}>
        <button className={styles.mainOption} onClick={() => setOpenPersonalInfo(true)}>
          <AccountSVG className={styles.mainOptionIcon} />
          <p>{FIELD_LABELS.PERSONAL_INFO}</p>
        </button>
        <button className={styles.mainOption} onClick={goToTransations}>
          <TransactionSVG className={styles.mainOptionIcon} />
          <p>{FIELD_LABELS.MY_TRANSACTIONS}</p>
        </button>
        <button className={styles.mainOption} onClick={goToMyNotices}>
          <MyNoticesSVG className={styles.mainOptionIcon} />
          <p>{PAGE_TITLES.NOTICES}</p>
        </button>
        <button className={styles.mainOption} onClick={goToMyProperties}>
          <PropertiesSVG className={styles.mainOptionIcon} />
          <p>{FIELD_LABELS.MY_PROPERTIES}</p>
        </button>
      </div>
    );
  };

  const renderSecondaryOptions = () => {
    return (
      <div className="mt-8">
        <button className={styles.secondaryOption} onClick={() => setOpenInterests(true)}>
          <InterestsSVG className={styles.secondaryIcon} />
          <p>{FIELD_LABELS.MY_INTERESTS}</p>
          <ArrowRightSVG className={styles.arrowIcon} />
        </button>
        <button className={styles.secondaryOption} onClick={goToAlerts}>
          <AlertsSVG className={styles.secondaryIcon} />
          <p>{FIELD_LABELS.ALERTS}</p>
          <ArrowRightSVG className={styles.arrowIcon} />
        </button>
        <button className={styles.secondaryOption} onClick={goToBookmarks}>
          <BookmarksSVG className={styles.secondaryIcon} />
          <p>{PAGE_TITLES.BOOKMARKS}</p>
          <ArrowRightSVG className={styles.arrowIcon} />
        </button>
        <button className={styles.secondaryOption} onClick={goToSearchHistory}>
          <HistorySVG className={styles.secondaryIcon} />
          <p>{PAGE_TITLES.SEARCH_HISTORY}</p>
          <ArrowRightSVG className={styles.arrowIcon} />
        </button>
      </div>
    );
  };

  if (isGettingUserInfo) {
    return <Spinner modal />;
  }

  if (userInfoError) {
    return (
      <div className="error-text">
        <p>{STATIC_TEXT.GENERIC_ERROR}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className={styles.profilePage}>
        <div className="flex items-center">
          <UserSVG className="w-[108px] h-[108px] pr-2" />
          <h3>{`Hi ${userInfo?.data?.first_name}, ${STATIC_TEXT.WELCOME_PROFILE}`}</h3>
        </div>
        {renderMainOptions()}
        {renderSecondaryOptions()}
        <Button
          variant="outline"
          size="large"
          customClasses="flex w-full mt-8 items-center justify-center"
          onClick={() => setReportModal(true)}
        >
          <HelpCenterSVG className={styles.secondaryIcon} />
          {FIELD_LABELS.HELP_CENTER}
        </Button>
        <Button
          customClasses="group flex w-full mt-8 items-center justify-center bg-white hover:text-white"
          onClick={handleLogout}>
          <span className="text-PRIMARY group-hover:text-white">{PAGE_TITLES.LOGOUT}</span>
        </Button>
      </div>
      <PersonalInfo
        openPersonalInfo={openPersonalInfo}
        setOpenPersonalInfo={setOpenPersonalInfo}
        userInfo={userInfo}
        refreshUserInfo={refreshUserInfo}
      />
      <MyInterests
        openInterests={openInterests}
        setOpenInterests={setOpenInterests}
        userInfo={userInfo}
        refreshUserInfo={refreshUserInfo}
      />
      <ReportConcern visible={reportModal} closeModal={() => setReportModal(false)} />
    </div>
  );
}
