import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useUserInfoQuery, useAppSelector } from '@frontend/redux';
import {
  AlertsScreens,
  AuthScreens,
  ColorPalette,
  HomeScreens,
  ProfileScreens,
  PropertyScreens,
} from '@frontend/common';

import { ReactComponent as LogoSVG } from 'assets/src/svgs/header_logo.svg';
import { ReactComponent as UserSVG } from 'assets/src/svgs/user.svg';
import { ReactComponent as FreeSVG } from 'assets/src/svgs/free.svg';
import { ReactComponent as AlertSVG } from 'assets/src/svgs/bell.svg';
import { ReactComponent as RupeeSVG } from 'assets/src/svgs/rupee.svg';
import { ReactComponent as MenuSVG } from 'assets/src/svgs/menu.svg';
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';

import NavLink from './NavLink';
import Searchbar from './Searchbar';
import MobileMenu from './MobileMenu';
import styles from './Header.module.css';
import { formatNumberIndian } from '@frontend/utils-helpers';

type Props = {
  customClasses?: string;
};

function Header(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { creditStatus } = useAppSelector((state) => state.subscription);
  const { data: userInfo } = useUserInfoQuery();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isAuthScreen = pathname === AuthScreens.LOGIN || pathname === AuthScreens.SIGN_UP;
  const isDashboard = pathname === HomeScreens.DASHBOARD || pathname === '/';

  const handleNavigation = (screen: string) => {
    setIsMenuOpen(false);
    navigate(screen);
  };

  const goToHomePage = () => handleNavigation(HomeScreens.DASHBOARD);
  const goToProfile = () => handleNavigation(ProfileScreens.PROFILE);
  const goToAlert = () => handleNavigation(AlertsScreens.ALERTS);
  const goToSubscriptions = () => handleNavigation(HomeScreens.SUBSCRIPTION);
  const goToAddProperty = () => handleNavigation(PropertyScreens.ADD_PROPERTY);
  const goToTransactions = () => handleNavigation(ProfileScreens.TRANSACTIONS);

  const renderPendingAmount = () => {
    const pendingAmount =
      (creditStatus?.data.alert_credits.remaning_amount || 0) +
      (creditStatus?.data.notice_credits.remaning_amount || 0);

    if (pendingAmount >= 0) {
      return (
        <div className={styles.pendingAmountContainer}>
          <RupeeSVG width={35} height={20} className={styles.dollarIcon} />
          <div className={styles.pendingAmountTxtContainer}>
            <p>{formatNumberIndian(pendingAmount, 0)}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderMobileMenu = () => {
    return (
      <div className={styles.mobileMenu}>
        <NavLink text="Buy Subscriptions" onClick={goToSubscriptions} />
        <NavLink text="My Transactions" onClick={goToTransactions} />
        <NavLink text="Profile" onClick={goToProfile} />
      </div>
    );
  };

  return (
    <>
      <header>
        <div className={isAuthScreen ? styles.authHeader : styles.header}>
          <nav className={styles.navContainer}>
            <div className="flex items-center justify-between w-full lg:w-auto">
              <button
                onClick={goToHomePage}
                className={isAuthScreen ? styles.authLogoButton : styles.logoButton}
                disabled={isMenuOpen}>
                <LogoSVG style={{ height: 32 }} />
              </button>

              {!isAuthScreen && (
                <button className="lg:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  {!isMenuOpen && <MenuSVG style={{ height: 32 }} fill="white" />}
                  {isMenuOpen && <CloseSVG style={{ height: 32, width: 32 }} fill="white" />}
                </button>
              )}
            </div>

            {!isDashboard && !isAuthScreen && <Searchbar />}
            {!isAuthScreen && (
              <div className={`hidden items-center lg:flex`}>
                <NavLink text="Buy Subscriptions" onClick={goToSubscriptions} />
                {isDashboard && (
                  <NavLink
                    text="List Properties"
                    onClick={goToAddProperty}
                    rightIcon={<FreeSVG className="w-[34px] h-[34px]" />}
                  />
                )}
                <NavLink leftIcon={renderPendingAmount()} onClick={goToTransactions} text="" />
                <NavLink
                  text={userInfo ? `Hi ${userInfo?.data.first_name}` : null}
                  onClick={goToProfile}
                  leftIcon={<UserSVG className="w-[34px] h-[34px]" />}
                />
                <NavLink
                  leftIcon={<AlertSVG className="w-[34px] h-[34px]" fill={ColorPalette.PRIMARY} />}
                  onClick={goToAlert}
                  text=""
                />
              </div>
            )}
            {isAuthScreen && <div style={{ height: 34 }} />}
          </nav>
        </div>
      </header>
      <MobileMenu
        visible={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        renderContent={renderMobileMenu}
      />
    </>
  );
}

export default Header;
