import { useState, useEffect } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useSendOtpMutation,
  useValidateOtpMutation,
  useUserLoginMutation,
  checkIfValidApiResponse,
  AuthActions,
} from '@frontend/redux';
import { useDispatch } from 'react-redux';
import { STATIC_TEXT, CONSTANTS } from '@frontend/common';

const useSignIn = () => {
  const dispatch = useDispatch();
  const [sendOtp, { isLoading: isSendingOtp }] = useSendOtpMutation();
  const [validateOtp, { isLoading: isValidatingOtp }] = useValidateOtpMutation();
  const [userLogin, { isLoading: isUserLogging }] = useUserLoginMutation();

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lastOtpSentCount, setLastOtpSentCount] = useState(0);

  // TEMP: Remove this once the SMS service is ready
  const [apiOtp, setApiOtp] = useState('');

  useEffect(() => {
    // Exit early when we reach 0
    if (lastOtpSentCount === 0) {
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders or unmounts
    const intervalId = setInterval(() => {
      setLastOtpSentCount((prevLastOtpSent) => prevLastOtpSent - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [lastOtpSentCount]);

  const sendOtpHandler = async (phoneNumber: string) => {
    setErrorMessage('');
    const res = await checkIfValidApiResponse(sendOtp, { mobile: phoneNumber });
    if (res.isSuccess && res.data) {
      if (res.data?.data?.message && !res.data?.data?.otp) {
        setErrorMessage(res.data?.data?.message || STATIC_TEXT.GENERIC_ERROR);
      }
      const otp = res.data.data?.otp?.token;
      if (process.env.NODE_ENV === 'development' || phoneNumber === '9930922687') {
        setApiOtp(otp);
      }
      setIsOtpSent(true);
      setLastOtpSentCount(CONSTANTS.RESEND_OTP_TIME);
      setIsNewUser(res.data.data?.new_user);
      dispatch(AuthActions.setNewUser(res.data.data?.new_user));
    } else {
      // Handle error, e.g., show an error message to the user.
      setLastOtpSentCount(0);
      setErrorMessage(res.message || STATIC_TEXT.GENERIC_ERROR);
    }
  };

  const validateOtpHandler = async (
    phoneNumber: string,
    otp: string,
    goToRegisterScreen: () => void
  ) => {
    setErrorMessage('');
    const res = await checkIfValidApiResponse(validateOtp, { mobile: phoneNumber, otp });
    if (res.isSuccess && res.data) {
      // Handle success, e.g., navigate to the next screen, show a success message, etc.
      if (res.data.data?.otp?.otp_status) {
        goToRegisterScreen();
      } else {
        // Handle error, e.g., show an error message to the user.
        setErrorMessage(STATIC_TEXT.GENERIC_ERROR);
      }
    } else {
      // Handle error, e.g., show an error message to the user.
      const message = res.message || res.data.data?.otp?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const userLoginHandler = async (
    phoneNumber: string,
    otp: string,
    deviceName: string,
    onSuccess: () => void
  ) => {
    setErrorMessage('');
    const res = await checkIfValidApiResponse(userLogin, {
      mobile: phoneNumber,
      otp,
      device_name: deviceName,
    });
    if (res.isSuccess && res.data) {
      // Handle login success
      onSuccess();
      dispatch(AuthActions.setToken(res.data.data?.token));
    } else {
      const message = res.message || res.data.data?.otp?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  return {
    isOtpSent,
    isNewUser,
    errorMessage,
    sendOtpHandler,
    validateOtpHandler,
    userLoginHandler,
    isSendingOtp,
    isValidatingOtp,
    isUserLogging,
    lastOtpSentCount,
    apiOtp, // TEMP: Remove this once the SMS service is ready
  };
};

export default useSignIn;
