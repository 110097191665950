import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS, GET_ALERT_NOTICES } from '../../types/endpoints';
import { FetchMethods } from '../../types/common';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';
import { AlertListResponse, AlertNoticeRequest, InAppAlertRemoveRequest, InAppAlertResponse } from '../../types/alert';
import { MasterDataParams } from '../../types/master';
import { NoticeListResponse } from '../../types/notice';

export const alertsApi = createApi({
  reducerPath: 'alertsApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    getAlertList: builder.mutation<AlertListResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.GET_ALERT_LIST,
        method: FetchMethods.GET,
        params
      }),
    }),
    getAlertNotices: builder.mutation<NoticeListResponse, AlertNoticeRequest>({
      query: ({ alertId }) => ({
        url: GET_ALERT_NOTICES(alertId),
        method: FetchMethods.GET
      }),
    }),
    getInappAlertStats: builder.query<InAppAlertResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.INAPP_ALERT_STATS,
        method: FetchMethods.GET
      }),
    }),
    getInappAlertRead: builder.query<void, InAppAlertRemoveRequest>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.INAPP_ALERT_READ,
        method: FetchMethods.GET,
        params
      }),
    }),
  })
});

export const {
  useGetAlertListMutation,
  useGetAlertNoticesMutation,
  useLazyGetInappAlertStatsQuery,
  useLazyGetInappAlertReadQuery
} = alertsApi;