/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Button, CustomSelect, Spinner, CustomInput } from '@frontend/web-components';
import {
  STATIC_TEXT,
  FIELD_LABELS,
  PAGE_TITLES,
  FIELD_PLACEHOLDERS,
  PROPERTY_INPUT_FIELDS,
  PROPERTY_FIELD_LABEL_MAPPINGS,
  PROPERTY_SELECT_FIELDS,
  PropertyScreens,
} from '@frontend/common';
import {
  useAppSelector,
  checkIfValidApiResponse,
  useGetLocationsQuery,
  useLazyGetStatesQQuery,
  useLazyGetPropertyTypesQQuery,
  useLazyGetVillagesByCityQuery,
  useAddPropertyMutation,
  useEditPropertyMutation,
  useGetPropertySystemsQuery,
  useGetPropertyDetailsMutation,
  useGetCreditStatusQuery,
  useUseCreditPropertyMutation,
  MasterItem,
  APIResponse,
  PropertyRecordResponse,
} from '@frontend/redux';
import { formatKeyToNames } from '@frontend/utils-helpers';

import AddImages from './AddImages';
import usePropertyDetailsFromPath from './usePropertyDetailsFromPath';
import styles from '../Properties.module.css';

// SVGs
import { ReactComponent as PlusSVG } from 'assets/src/svgs/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/src/svgs/minus.svg';

// Define a type for your route parameters
type RouteParams = {
  displayOnly?: boolean;
  propertyId?: string;
};

type PropertyInputFieldsType = {
  [K in typeof PROPERTY_INPUT_FIELDS[number]]: string | undefined;
};

type FormData = {
  city_id: string;
  deleted_images?: string;
  image1?: string;
  image2?: string;
  image3?: string;
  others: string;
  property_type_id: string;
  state_id: string;
  system_id: string | undefined;
  taluka_id: string;
  village_id: string;
} & PropertyInputFieldsType;

export default function AddPropertyScreen() {
  const { propertyId, displayOnly } = usePropertyDetailsFromPath();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
    setValue,
  } = useForm({});

  // Master APIs
  const [
    fetchPropertyTypes,
    { data: propertyTypes, error: propertyTypeErr, isLoading: isGettingPropertyTypes },
  ] = useLazyGetPropertyTypesQQuery();
  const [fetchStates, { data: states, error: statesErr, isLoading: isGettingStates }] =
    useLazyGetStatesQQuery();
  const [fetchVillages, { data: villages, error: villagesErr, isFetching: isGettingVillages }] =
    useLazyGetVillagesByCityQuery();
  const {
    data: locations,
    isLoading: isGettingLocations,
    error: locationsError,
  } = useGetLocationsQuery();

  // Form APIs
  const [addProperty, { isLoading: isAddingProperty }] = useAddPropertyMutation();
  const [editProperty, { isLoading: isEditingProperty }] = useEditPropertyMutation();
  const [getPropertyDetails, { isLoading: isGettingPropertyDetails }] =
    useGetPropertyDetailsMutation();

  const {
    data: creditStatus,
    refetch: refreshCreditStatus,
    isLoading: isGettingCreditStatus,
  } = useGetCreditStatusQuery();
  const [useCreditProperty] = useUseCreditPropertyMutation();

  const { data: propertySystems } = useGetPropertySystemsQuery();

  const isCreditAvailable = creditStatus?.data?.alert_credits?.remaning ? creditStatus?.data?.alert_credits?.remaning > 0 : false;

  // STATE
  const [errorMessage, setErrorMessage] = useState('');
  const [errorField, setErrorField] = useState('');
  const [newImages, setNewImages] = useState<string[]>([]);
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [openInputFields, setOpenInputFields] = useState(displayOnly);
  const [notify, setNotify] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [arePropertyDetailsSet, setArePropertyDetailsSet] = useState(!propertyId);
  const [propertyNum, setPropertyNum] = useState('');

  useEffect(() => {
    if (propertyId) {
      getPropertyDetailsData();
    }
  }, [propertyId]);

  useEffect(() => {
    if (states) {
      setValue('STATE', states.data[0]);
    }
  }, [states]);

  useEffect(() => {
    if (displayOnly) {
      setInitialLoading(false);
    } else {
      if (arePropertyDetailsSet) {
        fetchPropertyTypes({});
        fetchStates({});
        if (propertyId) {
          const currentValues = getValues();
          const district = currentValues.DISTRICT;
          fetchVillages({ city: district.name });
        }
        setInitialLoading(false);
      }
    }
  }, [arePropertyDetailsSet, displayOnly]);

  const getPropertyDetailsData = async () => {
    const res = await checkIfValidApiResponse(getPropertyDetails, { propertyId });
    if (res && res.isSuccess) {
      const propertyDetails = res.data.data;
      const { image, ...rest } = propertyDetails;
      setPropertyNum(propertyDetails.property_id);
      setCurrentImages(image || []);
      setNotify(propertyDetails.notify);
      buildPropertyDetails(rest);
      setArePropertyDetailsSet(true);
    } else {
      const message = res?.data?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const buildPropertyDetails = (data: PropertyRecordResponse) => {
    const obj: { [key: string]: any } = {}; // Allow any string as a key with values of any type

    Object.entries(PROPERTY_FIELD_LABEL_MAPPINGS).forEach(([key, field]) => {
      if (field in data) {
        obj[key] = data[field as keyof PropertyRecordResponse];
      }
    });

    PROPERTY_INPUT_FIELDS.forEach((field: string) => {
      if (field in data) {
        obj[field] = data[field as keyof PropertyRecordResponse];
      }
    });

    reset(obj);
  };

  const handleUseCredit = async (id: string) => {
    const res = await checkIfValidApiResponse(useCreditProperty, { propertyId: id });
    if (res && res.isSuccess) {
      refreshCreditStatus();
      navigate(PropertyScreens.MY_PROPERTIES, { state: { refetched: true } });
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const onSubmit = async (data: any) => {
    const protectSystem = propertySystems?.data?.find((system) => system.slug === 'protect');
    // Handle form submission
    const formData: FormData = {
      system_id: protectSystem?.id,
      state_id: data.STATE?.id,
      city_id: data.DISTRICT.id,
      // If village is changed, select taluka_id in Village, else just use the existing
      taluka_id: data.TALUKA.id,
      village_id: data.VILLAGE.id,
      property_type_id: data.TYPE_OF_PROPERTY.id,
      others: data.OTHER_DETAILS,
      deleted_images: undefined,
      image1: undefined,
      image2: undefined,
      image3: undefined,
    };

    const deletedImages: string[] = [];
    newImages.forEach((image, idx) => {
      formData[`image${idx + 1}` as keyof FormData] = image;
      if (currentImages[idx] !== undefined) {
        deletedImages.push(currentImages[idx]);
      }
    });

    if (deletedImages.length > 0) {
      formData.deleted_images = JSON.stringify(deletedImages);
    }

    PROPERTY_INPUT_FIELDS.forEach((field) => {
      formData[field as keyof FormData] = data[field];
    });

    const queryFn = propertyId ? editProperty : addProperty;
    const payload = propertyId ? { propertyId, payload: formData } : formData;

    const res = await checkIfValidApiResponse(queryFn, payload);
    if (res && res.isSuccess) {
      toast.success(STATIC_TEXT.PROPERTY_FORM_SUCCESS);
      if (!propertyId && notify && creditStatus && creditStatus?.data?.alert_credits?.remaning > 0) {
        await handleUseCredit(res.data.data.property_id);
      } else {
        navigate(PropertyScreens.MY_PROPERTIES, { state: { refetched: true } });
      }
    } else {
      if (res.data?.errors) {
        const [field, messages] = Object.entries(res.data.errors as APIResponse)[0];
        setErrorField(field);
        setErrorMessage(messages[0]);
      } else {
        setErrorMessage(res.data?.message || STATIC_TEXT.GENERIC_ERROR);
      }
    }
  };

  const getOptions = (field: string) => {
    switch (field) {
      case 'TYPE_OF_PROPERTY':
        return propertyTypes?.data || [];
      case 'STATE':
        return states?.data || [];
      case 'DISTRICT':
        return locations?.data || [];
      case 'VILLAGE':
        return villages?.data || [];
      default:
        return [];
    }
  };

  const getLoadingState = (field: string) => {
    switch (field) {
      case 'TYPE_OF_PROPERTY':
        return isGettingPropertyTypes;
      case 'STATE':
        return isGettingStates;
      case 'DISTRICT':
        return isGettingLocations;
      case 'VILLAGE':
        return isGettingVillages;
      default:
        return false;
    }
  };

  const handleSetAlert = () => {
    setNotify((prevState) => !prevState);
  };

  const handleOnSelect = (
    field: string,
    value: Partial<MasterItem> | null,
    onChange: (value: Partial<MasterItem> | null) => void
  ) => {
    const currentValues = getValues();
    const currValue = currentValues[field];
    const isValueSame = currValue && value ? currValue.id === value.id : currValue === value;

    if (isValueSame) {
      return;
    }

    // Updating the selected field
    onChange(value);

    // If DISTRICT is updated, clear VILLAGE and get VILLAGE MASTER
    if (field === 'DISTRICT') {
      setValue('VILLAGE', null);
      setValue('TALUKA', null);

      if (value) {
        fetchVillages({ city: value.name });
      }
    }

    // if VILLAGE is updated, change TALUKA
    if (field === 'VILLAGE') {
      const taluka = {
        id: value?.taluka_id,
        name: value?.taluka,
        slug: '',
      };
      setValue('TALUKA', taluka);
    }
  };

  const getPageTitle = () => {
    if (displayOnly) {
      return `Property # ${propertyNum}`;
    } else {
      if (propertyId) {
        return `${PAGE_TITLES.EDIT_PROPERTY} # ${propertyNum}`;
      } else {
        return PAGE_TITLES.ADD_PROPERTY;
      }
    }
  };

  const renderSelectField = (field: string) => {
    let disabled = displayOnly;
    if (!disabled && field === 'VILLAGE') {
      const districtSelected = watch('DISTRICT');
      disabled = !districtSelected;
    }

    return (
      <Controller
        key={field}
        control={control}
        name={field}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <CustomSelect
            key={field}
            fieldName={FIELD_LABELS[field]}
            placeholder={
              disabled ? 'Please select the district' : FIELD_PLACEHOLDERS.GENERIC_TYPE_SELECT
            }
            value={value}
            onSelectItem={(val) => handleOnSelect(field, val, onChange)}
            options={getOptions(field)}
            error={error ? error.message : undefined}
            loading={getLoadingState(field)}
            required
            disabled={disabled}
          />
        )}
      />
    );
  };

  const getFieldName = (field: string) => {
    let name = formatKeyToNames(field);
    if (field === 'hisaa_number') {
      name = 'Hissa Number';
    } else if (field === 'survey_name') {
      name = 'Survey Number';
    }
    return name;
  }

  const renderInputFields = () => {
    if (displayOnly) {
      return PROPERTY_INPUT_FIELDS.map((field) => {
        const value = getValues(field);
        if (value) {
          return (
            <CustomInput
              key={field}
              fieldName={getFieldName(field)}
              placeholder={FIELD_PLACEHOLDERS.GENERIC_INPUT}
              value={value}
              onChangeText={() => null}
              editable={false}
            />
          );
        }
        return null;
      });
    }

    return (
      <div className={styles.formFieldsContainer}>
        {PROPERTY_INPUT_FIELDS.map((field) => (
          <Controller
            key={field}
            control={control}
            name={field}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <CustomInput
                key={field}
                fieldName={getFieldName(field)}
                placeholder={FIELD_PLACEHOLDERS.GENERIC_INPUT}
                value={value}
                onChangeText={(value) => onChange(value)}
                error={error ? error.message : undefined}
              />
            )}
          />
        ))}
      </div>
    );
  };

  if (initialLoading) {
    return <Spinner modal />;
  }

  return (
    <div className="page-container">
      <div className={styles.pageContainer}>
        <h3>{getPageTitle()}</h3>
        <div className={styles.formFieldsContainer}>
          {renderSelectField('TYPE_OF_PROPERTY')}
          <CustomSelect
            fieldName={FIELD_LABELS['STATE']}
            placeholder={FIELD_PLACEHOLDERS.GENERIC_TYPE_SELECT}
            value={watch('STATE')}
            onSelectItem={() => null}
            options={[]}
            disabled
          />
          {renderSelectField('DISTRICT')}
          {renderSelectField('VILLAGE')}
          <CustomSelect
            fieldName={FIELD_LABELS['TALUKA']}
            placeholder={'Please select the village'}
            value={watch('TALUKA')}
            onSelectItem={() => null}
            options={[]}
            disabled
          />
        </div>
        <div className={styles.otherDetailsHeader}>
          <h5>{FIELD_LABELS.OTHER_DETAILS}</h5>
          <hr className="border border-black my-4 mx-4 flex flex-grow" />
          <button
            className={styles.iconContainer}
            onClick={() => setOpenInputFields((prevState) => !prevState)}>
            {openInputFields ? <MinusSVG /> : <PlusSVG />}
          </button>
        </div>
        {openInputFields && renderInputFields()}
        <div className={styles.imagesContainer}>
          <AddImages
            newImages={newImages}
            setNewImages={setNewImages}
            currentImages={currentImages}
            displayOnly={displayOnly}
          />
        </div>
        <div className="mt-8">
          <Controller
            control={control}
            name="OTHER_DETAILS"
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <CustomInput
                fieldName={FIELD_LABELS.OTHER_DETAILS}
                placeholder={FIELD_LABELS.OTHER_DETAILS}
                multiline
                numberOfLines={4}
                onChangeText={(value) => onChange(value)}
                value={value}
                error={error ? error.message : undefined}
                editable={!displayOnly}
              />
            )}
          />
        </div>
        {!displayOnly && <div className="flex flex-col justify-center items-center mt-8">
          {!propertyId && isCreditAvailable && <div className="flex mb-4 items-center">
            <button onClick={handleSetAlert} className={styles.checkBoxButton}>
              <div
                className={`${styles.checkBoxCircle} ${notify ? 'bg-GREEN' : 'bg-transparent'}`}
              />
            </button>
            <h5 className="text-sm font-semibold">{STATIC_TEXT.ADD_ALERT}</h5>
          </div>}
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            loading={isAddingProperty || isEditingProperty}>
            {FIELD_LABELS.SAVE}
          </Button>
        </div>}
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
    </div>
  );
}
