/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import {
  useNoticeDetailsMutation,
  checkIfValidApiResponse,
  Notice,
  useUseCreditNoticeMutation,
  useGetCreditStatusQuery,
  useAddBookmarkMutation,
  useRemoveBookmarkMutation,
  SubscriptionStatusName,
  useShareNoticePDFMutation,
} from '@frontend/redux';
import { useSubscriptionWeb } from '../../../hooks/useSubscriptionWeb';
import {
  ColorPalette,
  FIELD_LABELS,
  STATIC_TEXT,
  CONSTANTS,
  NOTICE_HIDE_FIELDS,
} from '@frontend/common';
import { formatKeyToNames } from '@frontend/utils-helpers';

import {
  Button,
  CenterModal,
  CreditSuccess,
  ImageComponent,
  PaymentSuccess,
  Spinner,
} from '@frontend/web-components';

// SVG
import { ReactComponent as BookmarkFilledSVG } from 'assets/src/svgs/bookmark_filled.svg';
import { ReactComponent as DownloadSVG } from 'assets/src/svgs/download.svg';
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';

import styles from '../Notice.module.css';

// Define a type for your route parameters
type RouteParams = {
  noticeId: string;
};

const LOCATION_FIELDS = ['plot_number', 'survey_name', 'gat_number', 'cts_number'];
const FIELDS_TO_IGNORE = [...LOCATION_FIELDS, ...NOTICE_HIDE_FIELDS];

export default function NoticeDetails() {
  const location = useLocation();
  const { pathname } = location;
  const noticeId = pathname.split('/').pop();

  const [noticeDetails, { isLoading: isFetchingNoticeDetails }] = useNoticeDetailsMutation();
  const [useCreditNotice, { isLoading: isUsingCredit }] = useUseCreditNoticeMutation();
  const { data: creditStatus, refetch: refetchCreditStatus } = useGetCreditStatusQuery();
  const [addBookmark] = useAddBookmarkMutation();
  const [removeBookmark] = useRemoveBookmarkMutation();
  const [shareNoticePDF] = useShareNoticePDFMutation();
  const {
    handlePayment,
    isLoading: isBuyingCredit,
    subscriptionCredits,
    updateStatus: paymentStatus,
    updateMessage,
  } = useSubscriptionWeb();

  const [notice, setNotice] = useState<Notice | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [useCreditSuccess, setUseCreditSuccess] = useState(false);
  const [useCreditSuccessMessage, setUseCreditSuccessMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const isNoticePurchased = notice?.is_purchased === CONSTANTS.YES;

  useEffect(() => {
    if (noticeId) {
      getNoticeDetails();
    }
  }, [noticeId]);

  useEffect(() => {
    // When Payment is completed, show success modal and initiate use credit
    if (paymentStatus === SubscriptionStatusName.COMPLETED) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleUseCredit();
      }, 1000);
    }
  }, [paymentStatus]);

  const getNoticeDetails = async () => {
    const res = await checkIfValidApiResponse(noticeDetails, { noticeId });
    if (res && res.isSuccess) {
      const noticeDetails = res.data.data as Notice;
      setNotice(noticeDetails);
    } else {
      const message = res?.data?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
      setNotice(null);
    }
  };

  const handleUseCredit = async () => {
    const res = await checkIfValidApiResponse(useCreditNotice, { noticeId });
    if (res && res.isSuccess) {
      setOpenSuccessModal(false);
      setUseCreditSuccess(true);
      setUseCreditSuccessMessage(res.data.data?.message);
      refetchCreditStatus();
      getNoticeDetails();
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const handleBuyCredit = async () => {
    const noticeCredit = subscriptionCredits.find((item) =>
      item.type.toLowerCase().includes('notice')
    );
    const payload = {
      total_alert: 0,
      total_notice: 1,
      amount: Number(noticeCredit?.amount),
    };
    await handlePayment(payload);
  };

  const downloadAndShareFile = (url: string) => {
    // Attempt to open the URL in a new tab
    window.open(url, '_blank');
  };

  const handleSharePDF = async () => {
    const res = await checkIfValidApiResponse(shareNoticePDF, { noticeId });
    if (res && res.isSuccess && res.data.data) {
      const url = res.data.data[0];
      downloadAndShareFile(url);
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const handleAddBookmark = async () => {
    const res = await checkIfValidApiResponse(addBookmark, { noticeId });
    if (res && res.isSuccess) {
      getNoticeDetails();
      toast.success(STATIC_TEXT.BOOKMARK_ADDED);
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const handleRemoveBookmark = async () => {
    const res = await checkIfValidApiResponse(removeBookmark, { noticeId });
    if (res && res.isSuccess) {
      getNoticeDetails();
      toast.success(STATIC_TEXT.BOOKMARK_REMOVED);
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const handleBookmarking = async () => {
    if (notice && notice.is_bookmarked === CONSTANTS.YES) {
      await handleRemoveBookmark();
    } else {
      await handleAddBookmark();
    }
  };

  const renderNoticeDetail = (key: string, value: any, idx: number) => {
    if (!value || FIELDS_TO_IGNORE.includes(key)) {
      return null;
    }

    const formatKey = formatKeyToNames(key);

    return renderDetailRow(formatKey, value, idx);
  };

  const isLocationInfoAvailable = () => {
    return LOCATION_FIELDS.some((field) => (notice ? !!notice[field as keyof Notice] : false));
  };

  const renderLocationDetail = (key: string, value: any, idx: number) => {
    if (LOCATION_FIELDS.includes(key)) {
      const formatKey = formatKeyToNames(key);

      return renderDetailRow(formatKey, value, idx);
    }
    return null;
  };

  const renderDetailRow = (key: string, value: any, idx: number) => {
    return (
      <div key={idx} className="flex justify-between relative">
        <div className="w-[48%]">
          <p className="font-medium">{key}:</p>
        </div>
        <div className={styles.separator} />
        <div className="w-[48%]">
          <p className="font-normal truncate">{value}</p>
        </div>
      </div>
    );
  };

  const renderActionBar = () => {
    return (
      <div className={styles.actionBar}>
        {!isNoticePurchased && (
          <button onClick={handleBookmarking} className="flex flex-col items-center">
            <BookmarkFilledSVG
              fill={
                notice?.is_bookmarked === CONSTANTS.YES ? ColorPalette.BLACK : ColorPalette.WHITE
              }
              width={26}
              height={26}
            />
            <p className="font-medium">{FIELD_LABELS.BOOKMARK}</p>
          </button>
        )}
        {isNoticePurchased && (
          <button onClick={handleSharePDF} className="flex flex-col items-center">
            <DownloadSVG fill={ColorPalette.BLACK} width={32} height={32} />
            <p>{FIELD_LABELS.DOWNLOAD}</p>
          </button>
        )}
      </div>
    );
  };

  const renderNoticeImage = () => {
    const remainingCredits = creditStatus?.data?.notice_credits.remaning;
    return (
      <div className={styles.imageContainer}>
        <button
          onClick={() => setShowFullScreenImage(true)}
          disabled={!notice?.image || !isNoticePurchased}>
          {!isNoticePurchased && <div className={styles.hiddenImageOverlay} />}
          <ImageComponent
            imageSource={notice?.image || null}
            customImageStyles={styles.noticeImage}
            imageResizeMode={'cover'}
            blurHeight={notice?.image && !isNoticePurchased ? 0.5 : 0}
          />
        </button>
        {!isNoticePurchased && remainingCredits !== undefined && (
          <Button
            onClick={remainingCredits > 0 ? handleUseCredit : handleBuyCredit}
            loading={isUsingCredit || isBuyingCredit}
            disabled={isUsingCredit || isBuyingCredit}>
            {remainingCredits > 0 ? STATIC_TEXT.USE_CREDIT : STATIC_TEXT.BUY_CREDIT}
          </Button>
        )}
      </div>
    );
  };

  ///

  if (isFetchingNoticeDetails && !notice) {
    return <Spinner modal />;
  }

  if (errorMessage) {
    return (
      <div className="flex items-center justify-center">
        <p className="empty-text">{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="flex justify-end pb-4">{renderActionBar()}</div>
      <div className="flex flex-col sm:flex-row">
        <div className="p-4 w-full sm:w-1/2">{renderNoticeImage()}</div>
        <div className="p-4 w-full sm:w-1/2">
          <div className={styles.noticeDetailsContainer}>
            {notice &&
              Object.keys(notice).map((key, idx) =>
                renderNoticeDetail(key, notice[key as keyof Notice], idx)
              )}
          </div>
          {isLocationInfoAvailable() && notice && (
            <div className={styles.locationDetailsContainer}>
              {Object.keys(notice).map((key, idx) =>
                renderLocationDetail(key, notice[key as keyof Notice], idx)
              )}
            </div>
          )}
        </div>
      </div>
      <CreditSuccess
        visible={useCreditSuccess}
        closeModal={() => setUseCreditSuccess(false)}
        type="notice"
        message={useCreditSuccessMessage}
      />
      <PaymentSuccess
        visible={openSuccessModal}
        closeModal={() => setOpenSuccessModal(false)}
        message={updateMessage}
      />
      {notice?.image && (
        <Lightbox
          open={showFullScreenImage}
          close={() => setShowFullScreenImage(false)}
          slides={[{ src: notice?.image }]}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          plugins={[Zoom]}
          zoom={{ scrollToZoom: true }}
        />
      )}
    </div>
  );
}
