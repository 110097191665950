import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSearchNoticesMutation, checkIfValidApiResponse, Notice } from '@frontend/redux';
import { FIELD_LABELS, STATIC_TEXT } from '@frontend/common';
import { useLazyLoading } from '@frontend/utils-hooks';
import { transformFiltersToStringParams } from '@frontend/utils-helpers';
import { Button, CenterModal } from '@frontend/web-components';

import { SelectedValues } from '../types';
import NoticeList from '../../Notice/List/NoticeList';
import styles from '../Search.module.css';
import FilterPage from './FilterPage';

// SVG
import { ReactComponent as FilterSVG } from 'assets/src/svgs/filter.svg';

export default function SearchResultScreen() {
  const [searchParams] = useSearchParams();

  // Use `get` method to retrieve parameters by their names
  const searchText = searchParams.get('searchText') || '';
  const propertyType = searchParams.get('propertyType') || undefined;
  const city = searchParams.get('city') || undefined;

  const [searchNotices, { isLoading: isSearchingNotices }] = useSearchNoticesMutation();

  const [errorMessage, setErrorMessage] = useState('');
  const [filters, setFilters] = useState<Partial<SelectedValues>>({});
  const [showFilterPage, setShowFilterPage] = useState(false);

  const getNotices = async (page: number) => {
    let payload = { query: searchText, page, city, property_type: propertyType };

    if (Object.keys(filters).length) {
      const transformedFilters = transformFiltersToStringParams(filters);
      payload = { ...payload, ...transformedFilters };
    }

    const res = await checkIfValidApiResponse(searchNotices, payload);
    if (res && res.isSuccess) {
      setErrorMessage('');
      const data = res.data.data;
      return {
        newData: page === 1 ? data : [...notices, ...data],
        newLastPage: res.data.meta.last_page,
      };
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      throw new Error(message);
    }
  };

  const {
    data: notices,
    loading: loadingMore,
    error: loadMoreError,
    loadMore: loadNotices,
    isFirstPage,
    isLastPage,
  } = useLazyLoading(getNotices);

  useEffect(() => {
    // Clear filters for new searchText
    loadNotices(true);
  }, [filters]);

  useEffect(() => {
    // Clear filters for new searchText
    clearFilters();
  }, [searchText]);

  useEffect(() => {
    if (loadMoreError) {
      setErrorMessage(loadMoreError);
    }
  }, [loadMoreError]);

  const openFilterPage = () => {
    setShowFilterPage(true);
  };

  const closeFilterPage = () => {
    setShowFilterPage(false);
  };

  const onApplyFilters = (selectedValues: SelectedValues) => {
    setFilters(selectedValues);
    closeFilterPage();
  };

  const clearFilters = () => {
    if (Object.keys(filters).length > 0) {
      setFilters({});
    }
    closeFilterPage();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRemoveFilter = (key: keyof SelectedValues, value: any) => {
    const newFilters: Partial<SelectedValues> = { ...filters };
    const filterValues = newFilters[key];
    if (key === 'published_date') {
      newFilters[key] = [];
    } else {
      newFilters[key] = filterValues
        ? filterValues.filter((filterValue) => filterValue !== value)
        : [];
    }
    setFilters(newFilters);
  };

  const renderAdvanceFilters = () => {
    return (
      <FilterPage
        searchParams={{
          query: searchText,
          city,
          property_type: propertyType
        }}
        filters={filters}
        onApplyFilters={onApplyFilters}
        clearFilters={clearFilters}
        closeFilterPage={closeFilterPage}
      />
    );
  };

  const pillTags = Object.keys(filters).flatMap((key, idx) => {
    if (key === 'published_date') {
      const dateRange = filters[key];
      if (dateRange && dateRange.length === 2) {
        return (
          <button
            key={`${key}-${idx}`}
            className={`${styles.valueTag}`}
            onClick={() => onRemoveFilter(key, filters[key])}>
            {`${dateRange[0]} - ${dateRange[1]} x`}
          </button>
        );
      }
      return null;
    } else {
      const obj = filters[key] || [];
      return obj.map((value, valueIdx) => (
        <button
          key={`${key}-${valueIdx}`}
          className={`${styles.valueTag}`}
          onClick={() => onRemoveFilter(key, value)}>
          {`${value} x`}
        </button>
      ));
    }
  });

  return (
    <div className="page-container">
      <div className={styles.titleContainer}>
        <h6>
          {searchText
            ? `${STATIC_TEXT.SHOWING_NOTICES} "${searchText}"`
            : STATIC_TEXT.SHOWING_ALL_NOTICES}
        </h6>
        <Button variant="outline" size="small" onClick={openFilterPage}>
          <div className="flex items-center">
            <FilterSVG className="mr-2" />
            <h6>{FIELD_LABELS.FILTERS}</h6>
          </div>
        </Button>
      </div>
      <div className={styles.pillTagContainer}>{pillTags}</div>
      <NoticeList
        notices={notices as Notice[]}
        errorMessage={errorMessage}
        loading={isSearchingNotices}
        pagination={true}
        loadMoreNotices={loadNotices}
        loadingMore={loadingMore}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
      />
      <CenterModal
        visible={showFilterPage}
        onClose={closeFilterPage}
        renderContent={renderAdvanceFilters}
      />
    </div>
  );
}
