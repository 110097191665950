export const STATIC_URL_ENDPOINTS = {
  REQUEST_OTP: '/v1/user/otp/request/mobile',
  VALIDATE_OTP: '/v1/user/otp/validate/mobile',
  REQUEST_EMAIL_OTP: '/v1/user/otp/request/email',
  VALIDATE_EMAIL_OTP: '/v1/user/otp/validate/email',
  USER_LOGIN: '/v1/user/login',
  USER_LOGOUT: '/v1/user/logout',
  REGISTER_USER: '/v1/user/register',
  USER_INFO: '/v1/user/information',
  NOTICE_TYPES: '/v1/notice/types',
  MASTER_LOCATIONS: '/v1/master/locations',
  MASTER_STATES: '/v1/master/states',
  MASTER_TALUKAS: '/v1/master/talukas',
  MASTER_VILLAGES: '/v1/master/villages',
  MASTER_CITIES: '/v1/master/cities',
  MASTER_PROPERTY_TYPES: '/v1/master/property-types',
  MASTER_NOTICE_TYPES: '/v1/master/notice-types',
  USER_INTERESTED: '/v1/user/user-interested',
  GET_PAYMENT_PARAMS: 'v1/payment/credits-devices-status',
  POST_STRIPE_INTENT: 'v1/payment/stripe/intent',
  POST_RAZORPAY_INTENT: 'v1/payment/razorpay/intent',
  PAYMENT_STATUS: 'v1/payment',
  CUSTOM_PACKAGE: 'v1/payment/customized/package/inquiry',
  SEARCH_NOTICES: '/v1/notice/search',
  CREDIT_STATUS: '/v1/credit/status',
  SHOW_PROPERTIES: '/v1/property/listing',
  ADD_PROPERTY: '/v1/property',
  SEARCH_FILTERS: '/v1/notice/search/filters',
  SEARCH_HISTORY: '/v1/notice/history',
  PROPERTY_SYSTEMS: '/v1/property/systems',
  GET_BOOKMARK_LIST: '/v1/bookmark/show',
  GET_NOTICES_LIST: '/v1/notice/listing',
  MASTER_GENDERS: '/v1/master/genders',
  REPORT_CONCERN: '/v1/user/report-concern',
  DASHBOAD_MAIN: '/v1/dashboard/main-config',
  MOST_VIEWED_NOTICES: '/v1/dashboard/mostviewed-notices',
  BOOKMARKED_NOTICES: '/v1/dashboard/bookmarked-notices',
  NEWSPAPER_NOTICES: '/v1/dashboard/leadingnewspaper-notices',
  NOTICE_TYPE_NOTICES: '/v1/dashboard/notices-bytype',
  GET_ALERT_LIST: '/v1/alert/listing',
  CREDIT_TRANSACTIONS: '/v1/credit/transactions',
  TODAY_NOTICES_LIST: '/v1/notice/today-notices',
  DASHBOAD_NEWS: '/v1/dashboard/news',
  DASHBOARD_POP_LOCS: '/v1/dashboard/popular-location',
  VILLAGES_BY_CITY: '/v1/master/cities-villages',
  CLEAR_HISTORY: '/v1/notice/clear-history',
  INAPP_ALERT_STATS: '/v1/dashboard/inapp-alert-stats',
  INAPP_ALERT_READ: '/v1/dashboard/inapp-alert-read',
  DEVICE_TYPES: '/v1/device/types',
  DEVICE_TOKEN: '/v1/device/token',
  PRICES: '/v1/credit',
  WAITLIST: '/v1/user/join-waitinglist',
  APP_VERSION: '/v1/dashboard/app-version',
  DELETE_ACCOUNT: '/v1/user/delete-account'
};

export const UPDATE_USER_INFO = (userId: string) => `/v1/user/${userId}`;
export const GET_NOTICE_DETAILS = (noticeId: string) => `v1/notice/details/${noticeId}`;
export const UPDATE_PAYMENT_STATUS = (paymentId: string) => `v1/payment/${paymentId}`;
export const USE_CREDIT_NOTICE = (noticeId: string) => `v1/notice/use-credit/${noticeId}`;
export const USE_CREDIT_PROPERTY = (propertyId: string) => `/v1/property/use-credit/${propertyId}`;
export const EDIT_PROPERTY = (propertyId: string) => `/v1/property/${propertyId}`;
export const GET_PROPERTY = (propertyId: string) => `/v1/property/details/${propertyId}`;
export const ADD_BOOKMARK = (noticeId: string) => `/v1/bookmark/${noticeId}`;
export const REMOVE_BOOKMARK = (bookmarkId: string) => `/v1/bookmark/${bookmarkId}`;
export const GET_ALERT_NOTICES = (alertId: string) => `/v1/alert/getnotice/${alertId}`;
export const SHARE_NOTICE_PDF = (noticeId: string) => `/v1/notice/share-pdf/${noticeId}`;
