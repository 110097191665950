import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  Spinner,
  CenterModal,
  CustomInput,
  CustomDatePicker,
  CustomSelect,
} from '@frontend/web-components';
import { FIELD_LABELS, FIELD_PLACEHOLDERS, STATIC_TEXT } from '@frontend/common';
import {
  useGetGenderMasterQuery,
  useUpdateUserInfoMutation,
  checkIfValidApiResponse,
  UserInfoResponse,
  UserInfoPayload,
  MasterItem,
} from '@frontend/redux';
import { calculateAge } from '@frontend/utils-helpers';

// SVG
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';
import styles from '../Profile.module.css';

type Props = {
  openPersonalInfo: boolean;
  refreshUserInfo: () => void;
  setOpenPersonalInfo: (visible: boolean) => void;
  userInfo: UserInfoResponse | undefined;
};

type FormFieldsType = {
  age: number | null;
  dob: string;
  email: string;
  gender: MasterItem | null;
  mobile_number: string | number;
  name: string;
};

export default function PersonalInfo(props: Props) {
  const { openPersonalInfo, setOpenPersonalInfo, userInfo, refreshUserInfo } = props;

  const { data: genderMaster } = useGetGenderMasterQuery();
  const [updateUserInfo, { isLoading: isUpdatingUser, error }] = useUpdateUserInfoMutation();

  const [errorMessage, setErrorMessage] = useState('');

  const getDefaultName = () => {
    if (userInfo?.data?.first_name || userInfo?.data?.last_name) {
      return userInfo.data?.first_name + ' ' + userInfo.data?.last_name;
    }

    return '';
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: getDefaultName(),
      mobile_number: userInfo?.data?.mobile_number || '',
      email: userInfo?.data?.email || '',
      dob: userInfo?.data?.dob || '',
      age: userInfo?.data?.age || null,
      gender: userInfo?.data?.gender || null,
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'dob') {
        // Provide a default date string if value.dob is undefined
        const dob = value.dob ?? '';
        const age = calculateAge(dob);
        setValue('age', age);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleUpdateUserInfo = async (payload: UserInfoPayload) => {
    if (!userInfo) {
      return;
    }

    const requestPayload = {
      userId: userInfo.data?.id,
      payload,
    };
    const res = await checkIfValidApiResponse(updateUserInfo, requestPayload);
    if (res && res.isSuccess) {
      refreshUserInfo();
      closeModal();
    } else {
      const message = res.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const closeModal = () => {
    reset();
    setOpenPersonalInfo(false);
  };

  const onSubmit = (data: FormFieldsType) => {
    const payload = {
      first_name: data.name.split(' ')[0],
      last_name: data.name.split(' ')[1],
      mobile_number: data.mobile_number,
      email: data.email,
      dob: data.dob,
      age: data.age,
      gender_id: data.gender?.id,
    };
    handleUpdateUserInfo(payload);
  };

  const renderForm = () => {
    return (
      <div className="p-4">
        <div className="flex justify-between">
          <h5 className="font-medium underline underline-offset-8">{FIELD_LABELS.PERSONAL_INFO}</h5>
          <button onClick={closeModal}>
            <CloseSVG className="w-[24px]" />
          </button>
        </div>
        <div className="mt-4">
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInput
                fieldName={FIELD_LABELS.NAME}
                placeholder={FIELD_PLACEHOLDERS.NAME}
                value={value}
                onChangeText={(value) => onChange(value)}
              />
            )}
          />
          <div className={styles.formFieldsContainer}>
            <CustomInput
              fieldName={FIELD_LABELS.PHONE_NUMBER}
              placeholder={FIELD_PLACEHOLDERS.MOBILE}
              value={getValues('mobile_number')}
              editable={false}
              onChangeText={() => null}
            />
            <CustomInput
              fieldName={FIELD_LABELS.EMAIL_ID}
              placeholder={FIELD_PLACEHOLDERS.EMAIL}
              value={getValues('email')}
              editable={false}
              onChangeText={() => null}
            />
          </div>
          <div className={styles.formFieldsContainer}>
            <Controller
              control={control}
              name="dob"
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomDatePicker
                  fieldName={FIELD_PLACEHOLDERS.DATE_OF_BIRTH}
                  value={value}
                  onSelect={(value) => onChange(value)}
                />
              )}
            />
            <CustomInput
              fieldName={FIELD_LABELS.AGE}
              placeholder=""
              value={watch('age')?.toString()}
              editable={false}
              onChangeText={() => null}
            />
          </div>
          <div className={styles.formFieldsContainer}>
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomSelect
                  fieldName={FIELD_PLACEHOLDERS.GENDER}
                  onSelectItem={(value) => onChange(value)}
                  options={genderMaster?.data || []}
                  value={value}
                  loading={false}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center">
          <Button onClick={handleSubmit(onSubmit)}>{FIELD_LABELS.SAVE}</Button>
        </div>
      </div>
    );
  };

  return <CenterModal visible={openPersonalInfo} onClose={closeModal} renderContent={renderForm} />;
}
