import React from 'react';

interface CheckBoxProps {
  customContainerStyles?: React.CSSProperties;
  desc?: string;
  id?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  isRoundCheckbox?: boolean;
  onClickDisabled?: boolean;
  onSelect: (id?: string) => void;
  title: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  desc,
  title,
  isChecked,
  isDisabled,
  isRoundCheckbox,
  onSelect,
  id,
  onClickDisabled,
  customContainerStyles,
}) => {
  return (
    <button
      className="flex flex-row items-center mb-2"
      style={customContainerStyles}
      onClick={() => onSelect(id)}
      disabled={onClickDisabled}>
      <div
        className={`flex items-center justify-center bg-white border border-black rounded-md ${
          isRoundCheckbox && 'rounded-2xl'
        } w-6 h-6 mr-4`}>
        <div
          className={`rounded-full w-4 h-4 ${isChecked ? 'bg-green-500' : ''} ${
            isDisabled ? 'bg-gray-300' : ''
          } ${isRoundCheckbox ? 'w-4 h-4' : ''}`}
        />
      </div>
      <div className="text-left">
        <p className="text-base text-black">{title}</p>
        {desc && <p className="text-sm text-black">{desc}</p>}
      </div>
    </button>
  );
};

export default CheckBox;
