import React, { useState } from 'react';

import { Button, CenterModal, CustomInput } from '@frontend/web-components';
import { useReportConcernMutation, checkIfValidApiResponse } from '@frontend/redux';
import { FIELD_LABELS, STATIC_TEXT } from '@frontend/common';

import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';

type Props = {
  closeModal: () => void;
  visible: boolean;
};

export default function ReportConcern(props: Props) {
  const { visible, closeModal } = props;

  const [reportConcern, { isLoading: isReportingConcern }] = useReportConcernMutation();

  const [report, setReport] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async () => {
    const payload = {
      concern: report,
    };
    const res = await checkIfValidApiResponse(reportConcern, payload);
    if (res && res.isSuccess) {
      closeModal();
    } else {
      const message = res.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const renderContent = () => {
    return (
      <div className="p-4">
        <div className="flex justify-between mb-4">
          <h5 className="font-medium underline underline-offset-8">{FIELD_LABELS.REPORT}</h5>
          <button onClick={closeModal}>
            <CloseSVG className="w-[24px]" />
          </button>
        </div>
        <CustomInput
          placeholder="Enter your concern"
          multiline
          onChangeText={(text) => setReport(text)}
        />
        <div className="mt-4 flex justify-center">
          <Button
            variant="secondary"
            onClick={onSubmit}
            disabled={report.length === 0}
            loading={isReportingConcern}>
            {FIELD_LABELS.SUBMIT}
          </Button>
        </div>
      </div>
    );
  };
  return <CenterModal visible={visible} onClose={closeModal} renderContent={renderContent} />;
}
