import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducerKeys } from './keys';
import {CreditStatusResponse} from '../api/types/subscription';

interface SubscriptionReduxState {
  creditStatus: CreditStatusResponse | null;
}

export const initialSubscriptionState: SubscriptionReduxState = {
  creditStatus: null
};

export const subscriptionSlice = createSlice({
  name: ReducerKeys.AUTH_SLICE,
  initialState: initialSubscriptionState,
  reducers: {
    updateCreditStatus: (state, { payload }: PayloadAction<CreditStatusResponse>) => {
      state.creditStatus = payload;
    },
  },
});

export const SubscriptionActions = subscriptionSlice.actions;
export default subscriptionSlice.reducer;