import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FlatList from 'flatlist-react';

import {
  STATIC_TEXT,
  PAGE_TITLES,
  NoticeScreens,
  NOTICE_HIDE_FIELDS,
  FIELD_LABELS,
} from '@frontend/common';
import { Notice, getUseQueryError, useGetBookmarkListQuery } from '@frontend/redux';
import { Button, ImageComponent, Spinner } from '@frontend/web-components';
import { formatKeyToNames } from '@frontend/utils-helpers';

import styles from './Bookmarks.module.css';

const renderDetailRows = (notice: Notice) => {
  const detailRows = Object.keys(notice).map((key, idx) => {
    const value = notice[key as keyof Notice];

    if (NOTICE_HIDE_FIELDS.includes(key)) {
      return null;
    }

    const formatKey = formatKeyToNames(key);
    return (
      <p key={`${key}-${idx}`} className="text-sm font-normal leading-6 truncate">
        {`${formatKey}: ${value || STATIC_TEXT.NO_DATA_AVAILABLE}`}
      </p>
    );
  });
  return detailRows;
};

export default function BookmarksScreen() {
  const navigate = useNavigate();

  const {
    data: bookmarks,
    error,
    isLoading,
    refetch: refreshBookmarks,
  } = useGetBookmarkListQuery();

  const [notices, setNotices] = useState<Notice[]>([]);

  useEffect(() => {
    if (bookmarks) {
      const data = bookmarks.data.map((elem) => elem.notice_info[0]);
      setNotices(data);
    }
  }, [bookmarks]);

  const goToNoticeDetails = (item: Notice) => {
    const path = `${NoticeScreens.NOTICE_DETAILS}/${item.id}`;
    navigate(path);
  };

  const renderNoticeItem = (item: Notice) => {
    return (
      <div key={item.id} className={styles.bookmarkItemContainer}>
          <ImageComponent
            imageSource={item.image}
            customImageStyles={styles.bookmarkItemImage}
            imageResizeMode="cover"
            blurHeight={item.image && item.is_purchased === 'No' ? 0.5 : 0}
          />
          <div className="items-start mt-2 mb-4">
            <p className="text-xs">
              {item.added_on ? `Bookmark added on: ${item.added_on.substring(0, 10)}` : ''}
            </p>
            <h6 className="text-sm font-semibold py-1">{item.property_type.toUpperCase()}</h6>
            {renderDetailRows(item)}
          </div>
          <Button size="small" customClasses="w-full" onClick={() => goToNoticeDetails(item)}>
            {FIELD_LABELS.VIEW_DETAIL}
          </Button>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="empty-text">{STATIC_TEXT.NO_BOOKMARKS}</p>
      </div>
    );
  };

  if (isLoading) {
    return <Spinner modal />;
  }

  if (error) {
    return (
      <div className="error-text">
        <p>{getUseQueryError(error)}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div>
        <h3>{PAGE_TITLES.BOOKMARKS}</h3>
        <p className="text-xs italic">{STATIC_TEXT.SHOW_BOOKMARKS}</p>
      </div>
      <div className={styles.listContainer}>
        <FlatList
          list={notices}
          renderItem={(item: Notice) => renderNoticeItem(item)}
          keyExtractor={(item: Notice) => item.id}
          showsVerticalScrollIndicator={false}
          renderWhenEmpty={renderEmptyMessage}
        />
      </div>
    </div>
  );
}
