import { useLocation, matchPath } from 'react-router-dom';
import { PropertyScreens } from '@frontend/common';

const usePropertyDetailsFromPath = () => {
    const { pathname } = useLocation();

    // Define patterns to match against the current pathname
    const patterns = [
        { path: `${PropertyScreens.EDIT_PROPERTY}/:propertyId`, displayOnly: false },
        { path: `${PropertyScreens.ADD_PROPERTY}/:propertyId`, displayOnly: true },
        { path: PropertyScreens.ADD_PROPERTY, displayOnly: false }, // Exact match for ADD_PROPERTY path
    ];

    let propertyId = null;
    let displayOnly = false; // Default to false

    for (const pattern of patterns) {
        const match = matchPath({ path: pattern.path, end: true }, pathname);
        if (match) {
            // For the exact ADD_PROPERTY path, propertyId will remain null
            propertyId = match.params.propertyId || null;
            displayOnly = pattern.displayOnly;
            break; // Exit the loop once a match is found
        }
    }

    return { propertyId, displayOnly };
};

export default usePropertyDetailsFromPath;