import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS, ADD_BOOKMARK, REMOVE_BOOKMARK } from '../../types/endpoints';
import { APIResponse, FetchMethods } from '../../types/common';
import {
  BookmarkListResponse,
} from '../../types/bookmark';
import { NoticeDetailsParams } from '../../types/notice';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';

export const bookmarksApi = createApi({
  reducerPath: 'bookmarksApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    getBookmarkList: builder.query<BookmarkListResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.GET_BOOKMARK_LIST,
        method: FetchMethods.GET,
      }),
    }),
    addBookmark: builder.mutation<APIResponse, NoticeDetailsParams>({
      query: ({ noticeId }) => ({
        url: ADD_BOOKMARK(noticeId),
        method: FetchMethods.POST,
      }),
    }),
    removeBookmark: builder.mutation<APIResponse, NoticeDetailsParams>({
      query: ({ noticeId }) => ({
        url: REMOVE_BOOKMARK(noticeId),
        method: FetchMethods.DELETE,
      }),
    }),
  }),
});

export const {
  useGetBookmarkListQuery,
  useAddBookmarkMutation,
  useRemoveBookmarkMutation,
} = bookmarksApi;
