import React from 'react';

type Props = {
  className?: string;
  editable?: boolean;
  error?: string;
  fieldName?: string;
  inputClassName?: string;
  multiline?: boolean;
  numberOfLines?: number;
  onChangeText: (text: string) => void;
  placeholder?: string;
  required?: boolean;
  rightElement?: React.ReactElement,
  value?: string;
};

const CustomInput = ({
  className = '',
  inputClassName = '',
  fieldName,
  placeholder,
  value,
  error,
  required,
  editable = true,
  multiline,
  onChangeText,
  numberOfLines,
  rightElement
}: Props) => {
  // Base classes that are always applied
  const baseContainerClasses = 'p-3 rounded-lg flex items-center';
  const baseInputClasses = 'w-full text-xs font-normal focus:outline-none';

  // Conditional classes based on `editable` and `error` props
  const editableContainerClasses = editable ? 'bg-white border border-GRAY_BG' : 'bg-GRAY_BG';
  const errorContainerClasses = error ? 'border-red-500' : 'bg-GRAY_BG';
  const editableInputClasses = editable
    ? 'bg-white text-INPUT_TEXT'
    : 'bg-GRAY_BG text-gray-500';
  const textAreaContainerClasses = multiline ? 'bg-white border border-black' : ''
  const textAreaInputClasses = multiline ? 'bg-white' : ''

  // Combining the classes with additional `className` or `inputClassName` props
  const containerClasses = `${baseContainerClasses} ${editableContainerClasses} ${errorContainerClasses} ${textAreaContainerClasses} ${className}`;
  const inputClasses = `${baseInputClasses} ${editableInputClasses} ${textAreaInputClasses} ${inputClassName}`;

  const TagName = multiline ? 'textarea' : 'input';

  return (
    <div>
      {fieldName && (
        <label className="block text-sm font-semibold text-gray-700 mb-2">
          {fieldName} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className={containerClasses}>
        <TagName
          value={value}
          placeholder={editable ? placeholder : '-'}
          className={inputClasses}
          disabled={!editable}
          rows={multiline ? numberOfLines : undefined}
          onChange={(e) => onChangeText(e.target.value)}
        />
        {rightElement}
      </div>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default CustomInput;
