import { STATIC_TEXT } from '@frontend/common';
import { Notice, AlertData } from '@frontend/redux';
import { useState, useCallback, useEffect } from 'react';

type DataType = { newData: Notice[] | AlertData[]; newLastPage: number }

const useLazyLoading = (
    fetchData: (page: number) => Promise<DataType>,
) => {
    const [data, setData] = useState<Notice[] | AlertData[]>([]);
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isFirstPage, setIsFirstPage] = useState(true);
    const [isLastPage, setIsLastPage] = useState(false);

    useEffect(() => {
        setIsLastPage(page >= lastPage);
    }, [page, lastPage])

    const loadMore = useCallback(async (reset: boolean) => {
        if (!reset && (page >= lastPage || loading || error)) {
            return;
        }

        setIsFirstPage(reset);
        setLoading(true);
        setError('');
        try {
            const newPage = reset ? 1 : page + 1;
            const { newData, newLastPage } = await fetchData(newPage);
            setData(newData);
            if (newData.length > 0) {
                setPage(newPage);
                setLastPage(newLastPage);
            } else {
                setPage(newPage);
                setLastPage(newPage);
            }
        } catch (err) {
            let errorMessage = STATIC_TEXT.GENERIC_ERROR;
            if (err instanceof Error) {
                errorMessage = err.message;
            }
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    }, [page, lastPage, loading, error, fetchData]);

    return { data, loading, error, loadMore, isFirstPage, isLastPage };
};

export default useLazyLoading;
