import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Props = {
  onClose: () => void;
  renderContent: () => React.ReactNode;
  visible: boolean;
};

export default function CenterModal(props: Props) {
  const { visible, onClose, renderContent } = props;
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all max-h-[80vh] overflow-y-auto">
              <div>{renderContent()}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
