import React, { useState } from 'react';

export enum LoadingState {
  FAILED = 'failed',
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
}

/**
 * A custom React hook for managing loading states.
 *
 * @param {LoadingState} [initialState=LoadingState.INITIAL] - The initial loading state.
 * @returns {{
 *   endLoading: (isSuccess: boolean) => void,
 *   isLoading: boolean,
 *   loadingState: LoadingState,
 *   makeLoaderCall: (functionToExecute: (value?: unknown) => Promise<void>, delay?: number) => void,
 *   resetLoadingState: () => void,
 *   startLoading: () => void
 * }} An object containing functions and values to manage loading states.
 * - `endLoading`: Call this function to end loading, specifying whether it was successful.
 * - `isLoading`: A boolean indicating if the component is in a loading state.
 * - `loadingState`: The current loading state (enum value).
 * - `makeLoaderCall`: A function to execute a loader call with optional delay.
 * - `resetLoadingState`: Call this function to reset the loading state to the initial state.
 * - `startLoading`: Call this function to start the loading process.
 *
 * @example
 * const {
 *   endLoading,
 *   isLoading,
 *   loadingState,
 *   makeLoaderCall,
 *   resetLoadingState,
 *   startLoading,
 * } = useLoader(LoadingState.INITIAL);
 */
export function useLoader(initialState?: LoadingState) {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    initialState || LoadingState.INITIAL
  );
  const isLoading = loadingState === LoadingState.LOADING;

  const startLoading = () => {
    setLoadingState(LoadingState.LOADING);
  };
  const endLoading = (isSuccess: boolean) => {
    setLoadingState(isSuccess ? LoadingState.SUCCESS : LoadingState.FAILED);
  };
  const resetLoadingState = () => {
    setLoadingState(LoadingState.INITIAL);
  };

  const makeLoaderCall = async (functionToExecute: (value?: unknown) => void, delay?: number) => {
    setLoadingState(LoadingState.LOADING);
    try {
      await functionToExecute();
      if (delay) {
        const timer = setTimeout(() => {
          endLoading(true);
          clearTimeout(timer);
        }, delay);
      } else {
        endLoading(true);
      }
    } catch (error) {
      endLoading(false);
    }
  };

  return {
    endLoading,
    isLoading,
    loadingState,
    makeLoaderCall,
    resetLoadingState,
    startLoading,
  };
}
