import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Code } from 'react-content-loader';

// Project
import { Button, ImageComponent, Spinner, BannerView, AlertCard } from '@frontend/web-components';
import {
  STATIC_TEXT,
  PAGE_TITLES,
  FIELD_LABELS,
  HomeScreens,
  SearchScreens,
  PropertyScreens,
  FIELD_PLACEHOLDERS,
  NoticeScreens,
  AlertsScreens,
} from '@frontend/common';
import {
  useUserInfoQuery,
  useGetDashboardMainQuery,
  useGetSearchHistoryQuery,
  useGetNoticesFromNoticeTypeMutation,
  Notice,
  checkIfValidApiResponse,
  useGetDashboardNewsQuery,
  MasterItem,
  DashboardNewsItem,
  useGetPopularLocationsMutation,
  useGetLocationsQuery,
  useLazyGetPropertyTypesQQuery,
  useLazyGetInappAlertStatsQuery,
  useLazyGetInappAlertReadQuery,
} from '@frontend/redux';
import { createQueryParamString, formatNumberIndian } from '@frontend/utils-helpers';

// SVGs
import { ReactComponent as BookmarkedSVG } from 'assets/src/svgs/db_bookmarked.svg';
import { ReactComponent as ViewedSVG } from 'assets/src/svgs/db_viewed.svg';
import { ReactComponent as OthersSVG } from 'assets/src/svgs/db_others.svg';
import { ReactComponent as NewspaperSVG } from 'assets/src/svgs/db_newspaper.svg';
import { ReactComponent as PostPropertySVG } from 'assets/src/svgs/post_prop_banner.svg';
import { ReactComponent as AlertSVG } from 'assets/src/svgs/alert.svg';
// Local
import OnboardingScreen from './Onboarding';
import styles from '../Home.module.css';

interface IOption {
  label: string;
  value: string;
}

type ListingNotices = {
  [key: string]: Notice[];
};

///

const renderNotices = (
  title: string,
  notices: Notice[],
  goToViewAll: () => void,
  goToNoticeDetails: (id: string) => void
) => {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionTitleContainer}>
        <h6>{title}</h6>
        <button onClick={goToViewAll}>{FIELD_LABELS.VIEW_ALL}</button>
      </div>
      <div className={styles.sectionItemsContainer}>
        {notices.map((item, idx) => {
          return (
            <button
              key={idx}
              className={styles.noticeImgContainer}
              onClick={() => goToNoticeDetails(item.id)}>
              <ImageComponent
                imageSource={item.image}
                customImageStyles={styles.noticeImgStyles}
                blurHeight={item.image && item.is_purchased === 'No' ? 0.5 : 0}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export function DashboardScreen() {
  const navigate = useNavigate();

  const {
    data: userInfo,
    isLoading: isGettingUserInfo,
    refetch: updateUserInfo,
    error,
  } = useUserInfoQuery();
  const {
    data: dashboardMain,
    isLoading: isGettingDashboardMain,
    refetch: refreshDashboard,
  } = useGetDashboardMainQuery();
  const {
    data: dashboardNews,
    isLoading: isLoadingNews,
    refetch: refreshNews,
  } = useGetDashboardNewsQuery();
  const {
    data: historyData,
    isLoading: isGettingHistoryData,
    refetch: refreshHistoryData,
  } = useGetSearchHistoryQuery();
  const [getNoticesFromNoticeType, { isLoading: isGettingDynamicLists }] =
    useGetNoticesFromNoticeTypeMutation();
  const [fetchPropertyTypes, { data: propertyTypes }] = useLazyGetPropertyTypesQQuery();
  const [getPopularLocations] = useGetPopularLocationsMutation();
  const { data: locations } = useGetLocationsQuery();
  const [fetchAlertStats, { data: alertStats, isFetching: isFetchingInappAlerts }] =
    useLazyGetInappAlertStatsQuery();
  const [removeAlertStat] = useLazyGetInappAlertReadQuery();

  const [dynamicListingNotices, setDynamicListingNotices] = useState<ListingNotices>({});

  const [selectedPropertyType, setSelectedPropertyType] = useState<SingleValue<IOption>>(null);
  const [selectedCity, setSelectedCity] = useState<MasterItem | null>(null);
  const [searchText, setSearchText] = useState('');
  const [popLocations, setPopLocations] = useState([]);

  useEffect(() => {
    fetchPropertyTypes({});
    fetchAlertStats();
  }, []);

  useEffect(() => {
    if (propertyTypes) {
      const firstPropetyType = propertyTypes.data.find((elem) => elem.name === 'Land');
      if (firstPropetyType) {
        const val = {
          label: firstPropetyType.name,
          value: firstPropetyType.id,
        };
        setSelectedPropertyType(val);
      }
    }
  }, [propertyTypes]);

  useEffect(() => {
    if (locations) {
      const firstCity = locations.data[0];
      setSelectedCity(firstCity);
    }
  }, [locations]);

  useEffect(() => {
    if (selectedCity) {
      handleGetPopularLocations();
    } else {
      setPopLocations([]);
    }
  }, [selectedCity]);

  useEffect(() => {
    const fetchNotices = async () => {
      if (dashboardMain?.data.dynamic_notice_listing) {
        // Map each item to a promise
        const promises = dashboardMain.data.dynamic_notice_listing.map((item) => {
          return getNoticesFromNoticeTypeWrapper(item.slug);
        });

        // Wait for all promises to resolve
        const results = await Promise.all(promises);

        // Combine results into a single object
        const newDynamicListingNotices = results.reduce((acc, result, index) => {
          if (result) {
            const noticeType = dashboardMain.data.dynamic_notice_listing
              ? dashboardMain?.data?.dynamic_notice_listing[index].slug
              : null;
            if (noticeType) {
              acc[noticeType] = result;
            }
          }
          return acc;
        }, {});

        // Update state once with all the new data
        setDynamicListingNotices(newDynamicListingNotices);
      }
    };

    fetchNotices();
  }, [dashboardMain, userInfo]);

  const refreshData = () => {
    updateUserInfo();
    refreshDashboard();
    refreshHistoryData();
    refreshNews();
  };

  const getNoticesFromNoticeTypeWrapper = async (notice_type: string) => {
    const payload = { notice_types: [notice_type] };
    const res = await checkIfValidApiResponse(getNoticesFromNoticeType, payload);
    return res && res.isSuccess ? res.data.data : null;
  };

  const handleGetPopularLocations = async () => {
    if (!selectedCity) {
      return;
    }

    const payload = {
      cities: [selectedCity.id],
    };
    const res = await checkIfValidApiResponse(getPopularLocations, payload);
    if (res && res.isSuccess) {
      setPopLocations(res.data.data);
    }
  };

  const getDynamicSpotlights = () => {
    if (dashboardMain?.data.spotlight_noticetype?.length === 0) {
      return null;
    }

    return dashboardMain?.data?.spotlight_noticetype
      ?.map((item: MasterItem) => item.name)
      ?.join(', ');
  };

  const onSelectPropertyType = (option: SingleValue<IOption>) => {
    setSelectedPropertyType(option);
  };

  const goToSearch = (popLocation?: string) => {
    const trimmedQuery = searchText.trim();
    const text = popLocation || trimmedQuery;
    const queryParams = createQueryParamString({
      searchText: text,
      propertyType: selectedPropertyType?.label,
      city: selectedCity?.name,
    });
    navigate(`${SearchScreens.SEARCH}?${queryParams}`);
  };

  const goToDynamicSpotlightNotices = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const noticeTypes = dashboardMain?.data?.spotlight_noticetype?.map((item: any) => item.slug);
    const pageTitle = getDynamicSpotlights();
    navigate(HomeScreens.NOTICES_BY_TYPES, {
      state: {
        noticeTypes,
        pageTitle,
      },
    });
  };

  const renderSelectPropertyType = () => {
    const options = propertyTypes?.data.map((item) => ({ value: item.id, label: item.name }));
    return (
      <div className={styles.selectContainer}>
        <Select
          defaultValue={selectedPropertyType}
          value={selectedPropertyType}
          onChange={onSelectPropertyType}
          options={options}
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              flex: 1,
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 0,
              boxShadow: 'none',
              fontSize: 12,
              fontWeight: '500',
            }),
            indicatorSeparator: () => ({
              display: 'none', // Hide the indicator separator
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '17vw',
              maxWidth: 150,
              fontSize: 12,
            }),
          }}
        />
      </div>
    );
  };

  const renderSelectPropertyTypeForMobile = () => {
    const options = propertyTypes?.data.map((item) => ({ value: item.id, label: item.name }));
    return (
      <div className={styles.xsSelectContainer}>
        <Select
          defaultValue={selectedPropertyType}
          value={selectedPropertyType}
          onChange={onSelectPropertyType}
          options={options}
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              flex: 1,
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 0,
              borderRadius: 0,
              boxShadow: 'none',
              fontSize: 12,
              fontWeight: '500',
            }),
            indicatorSeparator: () => ({
              display: 'none', // Hide the indicator separator
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              fontSize: 12,
              zIndex: 999,
            }),
          }}
        />
      </div>
    );
  };

  const renderSelectCity = () => {
    return (
      locations &&
      locations.data.map((city: MasterItem, idx: number) => {
        const isSelected = selectedCity?.id === city.id;
        return (
          <button
            key={idx}
            className={`${styles.cityOption} ${isSelected ? styles.selectedCityOption : ''}`}
            onClick={() => setSelectedCity(city)}>
            {city.name}
          </button>
        );
      })
    );
  };

  const renderPopularLocations = () => {
    if (popLocations.length === 0) {
      return null;
    }

    return (
      <div className={styles.popLocContainer}>
        <p>{`${STATIC_TEXT.POPULAR_LOCATIONS}:`}</p>
        <div>
          {popLocations.map((item: MasterItem, idx) => (
            <Button
              key={idx}
              variant="secondary"
              size="small"
              onClick={() => goToSearch(item.name)}>
              {item.name}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  const renderSpotlights = () => {
    return (
      <div className={styles.spotlightsContainer}>
        <button
          className={styles.spotlightsItem}
          onClick={() =>
            navigate(HomeScreens.MOST_BOOKMARKED_NOTICES, {
              state: { pageTitle: PAGE_TITLES.MOST_BOOKMARKED_NOTICES },
            })
          }>
          <div className={styles.spotlightIconContainer}>
            <BookmarkedSVG fill="white" className={styles.spotlightIcon} />
          </div>
          <p>{PAGE_TITLES.MOST_BOOKMARKED_NOTICES}</p>
        </button>
        <button
          className={styles.spotlightsItem}
          onClick={() =>
            navigate(HomeScreens.MOST_VIEWED_NOTICES, {
              state: { pageTitle: PAGE_TITLES.MOST_VIEWED_NOTICES },
            })
          }>
          <div className={styles.spotlightIconContainer}>
            <ViewedSVG fill="white" className={styles.spotlightIcon} />
          </div>
          <p>{PAGE_TITLES.MOST_VIEWED_NOTICES}</p>
        </button>
        <button className={styles.spotlightsItem} onClick={goToDynamicSpotlightNotices}>
          <div className={styles.spotlightIconContainer}>
            <OthersSVG fill="white" className={styles.spotlightIcon} />
          </div>
          <p>{getDynamicSpotlights()}</p>
        </button>
        <button
          className={styles.spotlightsItem}
          onClick={() =>
            navigate(HomeScreens.NEWSPAPER_NOTICES, {
              state: { pageTitle: PAGE_TITLES.NEWSPAPER_NOTICES },
            })
          }>
          <div className={styles.spotlightIconContainer}>
            <NewspaperSVG fill="white" className={styles.spotlightIcon} />
          </div>
          <p>{PAGE_TITLES.NEWSPAPER_NOTICES}</p>
        </button>
      </div>
    );
  };

  const renderNoticeSummary = () => {
    const todaysNotices = dashboardMain?.data.today_notices_count || 0;
    const allNotices = dashboardMain?.data.total_notices_count || 0;
    return (
      <div className={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <h2>{formatNumberIndian(todaysNotices, 0)}</h2>
          <p>{STATIC_TEXT.TODAY_NOTICES}</p>
          <Button
            onClick={() => navigate(HomeScreens.TODAY_NOTICES)}
            disabled={todaysNotices === 0}>
            {FIELD_LABELS.VIEW}
          </Button>
        </div>
        <div className={styles.summaryItem}>
          <h2>{formatNumberIndian(allNotices)}</h2>
          <p>{STATIC_TEXT.TOTAL_NOTICES}</p>
          <Button onClick={() => navigate(SearchScreens.SEARCH, { state: { searchText: '' } })}>
            {FIELD_LABELS.VIEW}
          </Button>
        </div>
      </div>
    );
  };

  const onGoToHistoryPage = () => navigate(SearchScreens.HISTORY);
  const goToNoticeDetails = (id: string) => {
    const path = `${NoticeScreens.NOTICE_DETAILS}/${id}`;
    navigate(path);
  };

  const handleRemoveAlertStat = async (alertId: string) => {
    await removeAlertStat({ alert_id: alertId });
    await fetchAlertStats();
  };

  const renderLastViewedNotices = () => {
    if (historyData?.data.viewed_notices?.length === 0) {
      return null;
    }
    const notices: Notice[] = historyData?.data.viewed_notices.slice(0, 5) || [];
    return renderNotices(
      PAGE_TITLES.LAST_VIEWED_NOTICES,
      notices,
      onGoToHistoryPage,
      goToNoticeDetails
    );
  };

  const renderDynamicListing = (index: number) => {
    if (!dashboardMain?.data.dynamic_notice_listing) {
      return;
    }

    if (!(dashboardMain?.data?.dynamic_notice_listing?.length > index)) {
      return null;
    }

    const item = dashboardMain?.data.dynamic_notice_listing[index];

    if (isGettingDynamicLists) {
      return (
        <div className={styles.sectionContainer}>
          <Code />
        </div>
      );
    }

    if (!dynamicListingNotices[item.slug]) {
      return null;
    }

    const notices: Notice[] = dynamicListingNotices[item.slug].slice(0, 5) || [];
    const goToNoticeTypePage = () =>
      navigate(HomeScreens.NOTICES_BY_TYPES, {
        state: {
          noticeTypes: [item.slug],
          pageTitle: item.name,
        },
      });
    return renderNotices(item.name, notices, goToNoticeTypePage, goToNoticeDetails);
  };

  const renderAddPropertyBanner = () => {
    return (
      <div className={styles.propBannerContainer}>
        <PostPropertySVG className={styles.propBannerImg} />
        <div className={styles.propBannerContent}>
          <p>{STATIC_TEXT.POST_NOTICE_TEXT}</p>
          <Button
            customClasses={styles.propBannerButton}
            onClick={() => navigate(PropertyScreens.ADD_PROPERTY)}>
            {STATIC_TEXT.POST_NOTICE}
          </Button>
        </div>
      </div>
    );
  };

  const renderAddAlertBanner = () => {
    return (
      <BannerView
        variant="black"
        bannerText={FIELD_LABELS.ADD_ALERT}
        bannerIcon={<AlertSVG fill="white" className={styles.alertIcon} />}
        subBannerText={STATIC_TEXT.NOTIFY_ALERT}
        buttonText={FIELD_LABELS.CREATE_ALERT}
        onButtonPress={() => navigate(PropertyScreens.MY_PROPERTIES)}
      />
    );
  };

  const renderNewsItem = (item: DashboardNewsItem) => {
    return (
      <div key={item.id} className={styles.newsItemContainer}>
        <img src={item.image} alt={item.title} className={styles.newsItemImg} />
        <h6>{item.title}</h6>
        <p>{item.published_on}</p>
      </div>
    );
  };

  const renderNewsArticles = () => {
    const items = dashboardNews ? dashboardNews.data : [];
    return (
      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitleContainer}>
          <div>
            <h6>{PAGE_TITLES.NEWS_ARTICLES}</h6>
            <p>{STATIC_TEXT.READ_NEWS}</p>
          </div>
        </div>
        <div className={styles.sectionItemsContainer}>{items.map(renderNewsItem)}</div>
      </div>
    );
  };

  const goToAlertNotices = (alertId: string, propertyNum: number) => {
    navigate(`${AlertsScreens.ALERT_NOTICES}/${alertId}`, {
      state: {
        alertId,
        pageTitle: `Matched Notices for Property - ${propertyNum}`,
      },
    });
  };

  const renderAlertCards = () => {
    if (isFetchingInappAlerts) {
      return (
        <div className="my-8">
          <Code />
        </div>
      );
    }

    if (!alertStats) {
      return null;
    }

    return (
      <div className="my-8">
        <AlertCard
          cards={alertStats.data}
          goToAlertNotices={goToAlertNotices}
          removeCard={handleRemoveAlertStat}
        />
      </div>
    );
  };

  const renderHeroSection = () => {
    return (
      <div className={styles.heroContainer}>
        <img
          src={require('assets/src/images/dashboard.jpeg')}
          alt="house"
          className={styles.heroImage}
        />
        <div className={styles.heroContent}>
          <h2
            className={
              styles.heroTitle
            }>{`${STATIC_TEXT.DASHBOARD_HERO_TITLE} ${selectedCity?.name}...`}</h2>
          <div className={styles.citiesContainer}>{renderSelectCity()}</div>
          {renderSelectPropertyTypeForMobile()}
          <div className={styles.searchContainer}>
            {renderSelectPropertyType()}
            <div className={styles.separator} />
            <input
              className={styles.searchInput}
              placeholder={FIELD_PLACEHOLDERS.SEARCH}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  goToSearch();
                }
              }}
            />
            <Button customClasses={styles.searchButton} onClick={() => goToSearch()}>
              {FIELD_LABELS.SEARCH}
            </Button>
          </div>
          {renderPopularLocations()}
        </div>
      </div>
    );
  };

  if (isGettingUserInfo || isGettingDashboardMain) {
    return <Spinner modal />;
  }

  if (error) {
    return (
      <div className="error-text">
        <p>{STATIC_TEXT.GENERIC_ERROR}</p>
      </div>
    );
  }

  if (!userInfo?.data.notice_type_interest?.length || !userInfo?.data.location_interest?.length) {
    return <OnboardingScreen updateUserInfo={refreshData} />;
  }

  return (
    <div className={styles.pageContainer}>
      {renderHeroSection()}
      <div className={styles.contentContainer}>
        {renderSpotlights()}
        {renderAlertCards()}
        {renderNoticeSummary()}
        {renderDynamicListing(0)}
        {renderLastViewedNotices()}
        {renderAddPropertyBanner()}
        {renderDynamicListing(1)}
        {renderAddAlertBanner()}
        {renderDynamicListing(2)}
        {renderNewsArticles()}
        {renderDynamicListing(3)}
      </div>
    </div>
  );
}

export default DashboardScreen;
