import React, { useEffect, useState, useRef } from 'react';
import Select, { components, SingleValue } from 'react-select';

// Assuming CloseSVG and DownArrowSVG are available as React components
import { ReactComponent as ArrowDownSVG } from 'assets/src/svgs/down_circle.svg';
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';
import { MasterItem } from '@frontend/redux';
import { ColorPalette } from '@frontend/common';

interface IOption extends MasterItem {
  label: string;
  value: string;
}

type Props = {
  disabled?: boolean;
  error?: string;
  fieldName?: string;
  loading?: boolean;
  onSelectItem: (item: IOption | null) => void;
  options: MasterItem[];
  placeholder?: string;
  required?: boolean;
  value?: any;
};

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    border: `1px solid ${ColorPalette.GRAY_BG}`,
    padding: '0.5rem',
    backgroundColor: state.isDisabled ? ColorPalette.GRAY_BG : ColorPalette.WHITE,
    borderRadius: '0.5rem',
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  clearIndicator: () => ({
    padding: 0,
    paddingRight: 10,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
    margin: 0,
    fontSize: '0.75rem',
  }),
  input: (base: any) => ({
    ...base,
    width: '100%',
    fontSize: '0.75rem',
    color: ColorPalette.BLACK,
  }),
  placeholder: (base: any) => ({
    ...base,
    width: '100%',
    fontSize: '0.75rem',
    color: ColorPalette.PLACEHOLDER,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

function CustomSelect({
  fieldName,
  value,
  error,
  onSelectItem,
  options,
  placeholder,
  loading,
  required,
  disabled,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<SingleValue<IOption>>(null);

  useEffect(() => {
    if (value) {
      const val = { ...value, label: value.name };
      setSelectedOption(val);
    } else {
      setSelectedOption(null);
    }
  }, [value]);

  const handleChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedOption(selectedOption);
    onSelectItem(selectedOption || null);
  };

  const clearItem = () => {
    onSelectItem(null);
  };

  // Customize components for react-select
  const DropdownIndicator = (props: any) => {
    if (disabled) {
      return null;
    }

    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownSVG style={{ width: 15 }} />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props} onClick={clearItem}>
        <CloseSVG style={{ width: 15 }} />
      </components.ClearIndicator>
    );
  };

  return (
    <div>
      {fieldName && (
        <label className="block text-sm font-semibold text-gray-700 mb-2">
          {fieldName} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Select
        menuPortalTarget={document.body}
        value={selectedOption}
        onChange={handleChange}
        options={
          loading
            ? []
            : options.map((option) => ({ ...option, value: option.id, label: option.name }))
        }
        styles={customStyles}
        isDisabled={disabled}
        isLoading={loading}
        isClearable
        placeholder={placeholder}
        components={{ DropdownIndicator, ClearIndicator }}
      />
    </div>
  );
}

export default CustomSelect;
