import React, { useState } from 'react';
import Lightbox, { label } from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import { TodayNoticeList, TodayNoticeType, useGetTodayNoticesListQuery } from '@frontend/redux';
import { ImageComponent, Spinner } from '@frontend/web-components';
import { STATIC_TEXT } from '@frontend/common';
import { toKeyableText } from '@frontend/utils-helpers';

import styles from './Home.module.css';

const images = {
  mumbai_metropolitan_region_mmr_: require('assets/src/images/mumbai.png'),
  pune: require('assets/src/images/pune.png'),
  thane: require('assets/src/images/thane.png'),
  raigad: require('assets/src/images/raigad.png'),
  palghar: require('assets/src/images/palghar.png'),
};

export default function TodayNotices() {
  const { data, isLoading } = useGetTodayNoticesListQuery();
  const [selectedCity, setSelectedCity] = useState<{id: string; label: string}>({
    id: 'pune',
    label: 'Pune'
  });
  const [selectImage, setSelectImage] = useState<string | null>(null);

  const renderCityOption = (city: string) => {
    const cityAsKey = toKeyableText(city);
    const isSelected = cityAsKey === selectedCity.id;

    return (
      <button
        key={cityAsKey}
        onClick={() => setSelectedCity({id: cityAsKey, label: city})}
        className={styles.onbCityOption}
      >
        {!isSelected && <div className={styles.onbImageMask} />}
        <img
          src={images[cityAsKey as keyof typeof images]}
          alt={city}
        />
        <p className={isSelected ? 'text-black' : 'text-gray-400'}>{city}</p>
      </button>
    );
  };

  const renderNoticeItem = (notice: TodayNoticeType) => {
    return (
      <button
        key={notice.id}
        className={styles.noticeItemContainer}
        onClick={() => setSelectImage(notice.notice_url)}>
        <ImageComponent
          imageSource={notice.notice_url}
          customImageStyles={styles.noticeImage}
          imageResizeMode="cover"
        />
      </button>
    );
  };

  const renderEmptyMessage = () => {
    return <p className="empty-text">{STATIC_TEXT.NO_NOTICES_FOUND}</p>;
  };

  const renderNotices = () => {
    const notices = data?.data[selectedCity.label] || [];

    return (
      <>
        <h6 className="font-semibold">{`Showing Today's Added Notices for ${selectedCity.label}`}</h6>
        {notices.length === 0 && renderEmptyMessage()}
        <div className={styles.noticeImagesContainer}>
          {notices.map(renderNoticeItem)}
        </div>
      </>
    );
  };

  if (isLoading) {
    return <Spinner modal />;
  }

  if (!data || !Object.keys(data.data).length) {
    return renderEmptyMessage();
  }

  return (
    <div className="page-container">
      <div className={styles.tn_citiesContainer}>
        {Object.keys(data.data).map(renderCityOption)}
      </div>
      {renderNotices()}
      {selectImage && (
        <Lightbox
          open={!!selectImage}
          close={() => setSelectImage(null)}
          slides={[{ src: selectImage }]}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          plugins={[Zoom]}
          zoom={{ scrollToZoom: true }}
        />
      )}
    </div>
  );
}
