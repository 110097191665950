import React from 'react';

import { ImageComponent } from '@frontend/web-components';
import { FIELD_LABELS, FIELD_PLACEHOLDERS, STATIC_TEXT } from '@frontend/common';

import styles from '../Properties.module.css';

import { ReactComponent as DefaultImageSVG } from 'assets/src/svgs/default_image.svg';
import { ReactComponent as PlusSVG } from 'assets/src/svgs/plus.svg';

const IMG_NUM = 3;

type Props = {
  currentImages: string[];
  displayOnly: boolean;
  newImages: string[];
  setNewImages: (images: string[]) => void;
};

const AddImages = (props: Props) => {
  const { newImages, setNewImages, currentImages, displayOnly } = props;

  const handleSelectImage = async (idx: number) => {
    // Creating an input element for selecting files
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Accept only images

    input.onchange = async (e) => {
      const target = e.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;
      if (!file) {
        // Handle case where no file is selected
        return;
      }

      try {
        // Converting selected file to base64
        const base64 = await fileToBase64(file);
        const imgType = file.type;
        const newImageUri = `data:${imgType};base64,${base64}`;
        const currNewImages = [...newImages];
        currNewImages[idx] = newImageUri;
        setNewImages(currNewImages);
      } catch (error) {
        if (error instanceof Error) {
          // Now TypeScript knows `error` has a `message` property
          alert('Error: ' + error.message);
        } else {
          // Fallback error handling if the error is not an Error object
          alert('An unknown error occurred');
        }
      }
    };

    // Triggering the file input
    input.click();
  };

  // Function to convert file to base64
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Asserting `reader.result` as a string
        const result = reader.result as string;
        // Now we can safely use string methods like `split`
        resolve(result.split(',')[1]); // This removes the 'data:image/*;base64,' part
      };
      reader.onerror = (error) => {
        if (error instanceof Error) {
          reject(error);
        } else {
          reject(new Error('An unknown error occurred during file reading'));
        }
      };
      // Use readAsDataURL to ensure `reader.result` is a Base64 encoded string
      reader.readAsDataURL(file);
    });
  };

  const renderAddImageItem = (idx: number) => {
    const imageUri = newImages[idx] || currentImages[idx] || null;
    return (
      <button
        key={idx}
        className={styles.addImageContainer}
        onClick={() => handleSelectImage(idx)}
        disabled={displayOnly}>
        {imageUri && (
          <ImageComponent
            imageSource={imageUri}
            customImageStyles={styles.addImage}
            imageResizeMode="cover"
          />
        )}
        {!imageUri && (
          <>
            <DefaultImageSVG className={styles.defaultImage} />
            {!displayOnly && (
              <div className={styles.imagePlusContainer}>
                <PlusSVG className="w-3 h-3" />
              </div>
            )}
          </>
        )}
      </button>
    );
  };

  const hasImages = currentImages?.length > 0;

  // Decide the label based on the mode
  const label = displayOnly ? FIELD_LABELS.IMAGES : FIELD_PLACEHOLDERS.ADD_IMAGES;

  // Function to render image items based on the context
  const renderImageItems = () => {
    if (displayOnly) {
      // Render all current images in display only mode
      return currentImages.map((_, index) => renderAddImageItem(index));
    } else {
      // Render placeholders to add images
      return Array.from({ length: IMG_NUM }).map((_, index) => renderAddImageItem(index));
    }
  };

  return (
    <div className="flex justify-center items-center">
      {hasImages && <p className="text-xs font-semibold mr-2">{label}</p>}
      {renderImageItems()}
    </div>
  );
};

export default AddImages;
