import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReduxWrapper } from '@frontend/redux';
import 'yet-another-react-lightbox/styles.css';

import NavigationWrapper from './routes/navigationWrapper';

export function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Code to run only in production build
      const handleContextmenu = (e: Event) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextmenu);
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu);
      };
    }
  }, []);

  return (
    <ReduxWrapper>
      <NavigationWrapper />
      <Toaster position="bottom-center" />
    </ReduxWrapper>
  );
}

export default App;
