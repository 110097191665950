/* eslint-disable @nx/enforce-module-boundaries */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useEffect, useRef, useState } from 'react';

import { STATIC_TEXT } from '@frontend/common';
import {
  useGetPaymentParamsMutation,
  useGetPricesMutation,
  usePostPaymentStatusMutation,
  usePutPaymentStatusMutation,
  checkIfValidApiResponse,
} from '@frontend/redux';
import {
  SubscriptionDevice,
  SubscriptionStatus,
  SubscriptionCredits,
  SubscriptionStatusName,
  PostPaymentStatusRequest,
  UpdatePaymentStatusRequest,
  PriceResponse,
  PriceItem,
} from 'redux/src/api/types/subscription';

interface PriceObject {
  [key: number]: {
    amount: string;
    gst: string;
  };
}

export interface PriceData {
  alertPrices: PriceObject;
  noticePrice: {
    amount: string;
    gst: string;
  };
}

const getFilteredCredits = (credits: SubscriptionCredits[]) => {
  let firstPropertyAlertCreditFound = false;
  return credits
    .filter((credit) => {
      if (credit.type === 'Property Alert Credits') {
        if (!firstPropertyAlertCreditFound) {
          firstPropertyAlertCreditFound = true;
          return true;
        }
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.type === 'Property Alert Credits' && b.type !== 'Property Alert Credits') {
        return -1; // a comes first
      }
      if (a.type !== 'Property Alert Credits' && b.type === 'Property Alert Credits') {
        return 1; // b comes first
      }
      return 0; // no change in order if both are the same type or neither is "Property Alert Credits"
    });
};

const getAlertPrices = (prices: PriceItem[]) => {
  const priceObject: PriceObject = {};

  prices.forEach((item) => {
    for (let i = item.min_quantity; i <= item.max_quantity; i++) {
      priceObject[i] = {
        amount: item.amount,
        gst: item.gst_percentage,
      };
    }
  });
  return priceObject;
};

export const useSubscriptionState = (platform: string) => {
  const [getPaymentParams] = useGetPaymentParamsMutation();
  const [getPrices] = useGetPricesMutation();
  const [postPaymentStatus] = usePostPaymentStatusMutation();
  const [putPaymentStatus] = usePutPaymentStatusMutation();

  const subscriptionDevices = useRef<SubscriptionDevice[] | null>(null);
  const subscriptionStatuses = useRef<SubscriptionStatus[] | null>(null);

  const [subscriptionCredits, setSubscriptionCredits] = useState<SubscriptionCredits[]>([]);
  const paymentId = useRef<string>('');

  const [updateStatus, setUpdateStatus] = useState(SubscriptionStatusName.INITIATED);
  const [updateMessage, setUpdateMessage] = useState('');
  const [prices, setPrices] = useState<PriceData>({
    alertPrices: [],
    noticePrice: { amount: '0', gst: '0' },
  });

  const fetchPaymentParams = async () => {
    const priceResponse = await checkIfValidApiResponse(getPrices, undefined);
    if (priceResponse.data && priceResponse.data.data) {
      const pricesWithAlerts = (priceResponse.data as PriceResponse).data.filter(
        (item) => item.credit_type_name !== 'Notice Credits'
      );
      const pricesWithNotice = (priceResponse.data as PriceResponse).data.filter(
        (item) => item.credit_type_name === 'Notice Credits'
      )[0];
      const alertPrices = getAlertPrices(pricesWithAlerts);
      const noticePrice = {
        amount: pricesWithNotice.amount,
        gst: pricesWithNotice.gst_percentage,
      };
      setPrices({ alertPrices, noticePrice });
    }
    const res = await checkIfValidApiResponse(getPaymentParams, undefined);
    if (res.data && res.data.data && res.isSuccess) {
      subscriptionDevices.current = res.data.data.devices;
      subscriptionStatuses.current = res.data.data.status;
      setSubscriptionCredits(getFilteredCredits(res.data.data.credits));
    }
  };

  const postPaymentUpdate = async ({
    status,
    amount,
    paymentObj,
    total_alert = 0,
    total_notice = 0,
    payment_method,
  }: {
    amount: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentObj?: Record<string, string> | any;
    payment_method: string;
    status: SubscriptionStatusName;
    total_alert?: number;
    total_notice?: number;
  }) => {
    const isUpdateOperation = status !== SubscriptionStatusName.INITIATED && paymentId.current;
    const updateObj = isUpdateOperation ? { paymentId: paymentId.current } : {};
    const responseObj = paymentObj ? { payment_response: paymentObj } : {};
    const body: PostPaymentStatusRequest | UpdatePaymentStatusRequest = {
      ...updateObj,
      ...responseObj,
      payment_device_id:
        subscriptionDevices?.current?.find((item) => item.name.toLowerCase() === platform)?.id ||
        '',
      payment_status_id:
        subscriptionStatuses.current?.find((item) => item.name === status)?.id || '',
      total_amount: amount,
      total_notice,
      total_alert,
      payment_method,
    };

    const res = await checkIfValidApiResponse(
      isUpdateOperation ? putPaymentStatus : postPaymentStatus,
      body
    );

    if (res.isSuccess) {
      if (isUpdateOperation && paymentId.current) {
        paymentId.current = '';
      } else if (!isUpdateOperation) {
        paymentId.current = res.data.data?.id;
      }

      setUpdateMessage(res.data.data?.message);
      setUpdateStatus(status);
    } else {
      const message = res.data.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setUpdateMessage(message);
      setUpdateStatus(SubscriptionStatusName.FAILED);
    }
  };

  useEffect(() => {
    fetchPaymentParams();
  }, []);

  return {
    subscriptionDevices: subscriptionDevices.current,
    subscriptionStatuses: subscriptionStatuses.current,
    subscriptionCredits,
    postPaymentUpdate,
    updateStatus,
    updateMessage,
    prices,
  };
};
