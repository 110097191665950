import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { ReactComponent as ArrowDownSVG } from 'assets/src/svgs/down_circle.svg';

// Make sure to import the CSS
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css'

interface Props {
  fieldName?: string;
  onSelect: (date: string) => void;
  placeholder?: string;
  value?: string;
}

const CustomDatePicker = ({
  value,
  placeholder = 'Select date',
  onSelect,
  fieldName,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : null);

  const containerClasses = `p-2 rounded-lg bg-white border border-GRAY_BG flex items-center`;
  const inputClasses = `w-full bg-white font-sm focus:outline-none font-normal text-INPUT_TEXT text-xs`;

  const handleDateChange = (date: Date) => {
    if (date) {
      setSelectedDate(date);
      onSelect(format(date, 'yyyy-MM-dd'));
    } else {
      setSelectedDate(null);
      onSelect('');
    }
  };

  return (
    <div className="mb-2">
      {fieldName && <div className="text-sm font-semibold text-gray-700 mb-2">{fieldName}</div>}
      <div className={containerClasses}>
        <DatePicker
          portalId="app"
          selected={selectedDate}
          onChange={handleDateChange}
          placeholderText={placeholder}
          className={inputClasses}
          showPopperArrow={false}
          wrapperClassName="w-full"
          dateFormat="MMMM d, yyyy"
        />
        <button>
          <ArrowDownSVG style={{ width: 15 }} />
        </button>
      </div>
    </div>
  );
};

export default CustomDatePicker;
