import { SubscriptionCredits } from 'redux/src/api/types/subscription';
import CounterComponent from '../SubscriptionCounter/SubscriptionCounter';
import { PriceData } from '@frontend/utils-hooks';
import { STATIC_TEXT } from '@frontend/common';
import { getDynamicText } from '@frontend/utils-helpers';
interface WebSubscriptionListProps extends SubscriptionCredits {
  onCounterUpdate: (key: 'alert' | 'notice', value: number) => void;
  prices: PriceData;
}
export const WebSubscriptionListItem = ({
  type,
  amount,
  quantity,
  onCounterUpdate,
  prices,
}: WebSubscriptionListProps) => {
  const isAlertCredit = type.includes('Alert');

  return (
    <div className="flex flex-col p-4 md:p-8 bg-GRAY_BG rounded-2xl w-full">
      <div className="flex items-center">
        <div className="flex bg-black items-center justify-center w-[40px] h-[40px] rounded-full p-2">
          <img
            loading="lazy"
            src={require(`../../../../../../assets/src/images/${
              type.toLocaleLowerCase().includes('alert') ? 'alert-credit' : 'notice'
            }.png`)}
            className="w-[20px]"
            alt="credit"
          />
        </div>

        <p className="ml-2 font-semibold md:text-lg">{type}</p>
      </div>

      <div className="mt-6 text-base leading-6 text-black grow">
        {isAlertCredit
          ? 'Use Alert Credits to setup an alert notification against your added property whenever there is an mention regarding your property in any notice.'
          : 'Use your credits to view any notice from leading newspapers of your interest location. '}
      </div>
      <div>
        <div className="mt-7 text-base text-black">Every credit is </div>
        <div className="mt-4 text-7xl text-black max-md:text-4xl">
          <span className="">₹</span>
          <span className="font-medium">
            {isAlertCredit
              ? prices.alertPrices[!quantity ? 1 : quantity].amount
              : prices.noticePrice?.amount}
          </span>
        </div>
      </div>

      <CounterComponent
        onValueChange={(newQty) =>
          onCounterUpdate(type.toLowerCase().includes('notice') ? 'notice' : 'alert', newQty)
        }
        value={quantity}
      />
    </div>
  );
};

export default WebSubscriptionListItem;
