import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import FlatList from 'flatlist-react';

import {
  useGetAlertListMutation,
  checkIfValidApiResponse,
  AlertData,
  Notice,
} from '@frontend/redux';
import { AlertsScreens, ColorPalette, FIELD_LABELS, STATIC_TEXT } from '@frontend/common';
import { useLazyLoading } from '@frontend/utils-hooks';
import { formatDateStringToHoursDDMMYY } from '@frontend/utils-helpers';

import styles from './Alerts.module.css';
import { Button, ImageComponent, Spinner, BannerView } from '@frontend/web-components';

export default function AlertsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const match = matchPath({ path: `${AlertsScreens.ALERTS}/:propertyId` }, pathname);
  const propertyId = match?.params?.propertyId;

  const [getAlertList, { isLoading: isGettingAlertList }] = useGetAlertListMutation();

  const [totalAlerts, setTotalAlerts] = useState(0);

  const handleGetAlertList = async (page: number) => {
    const payload = { query: propertyId || undefined };
    const res = await checkIfValidApiResponse(getAlertList, payload);
    if (res && res.isSuccess) {
      const data = res.data.data;
      return {
        newData: page === 1 ? data : [...alerts, ...data],
        newLastPage: res.data.meta.last_page,
      };
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      throw new Error(message);
    }
  };

  const getTotalAlerts = async () => {
    const res = await checkIfValidApiResponse(getAlertList, {});
    if (res && res.isSuccess) {
      setTotalAlerts(res.data.meta.total);
    }
  };

  const {
    data: alerts,
    loading: loadingMore,
    error: loadMoreError,
    loadMore: loadAlerts,
    isFirstPage,
    isLastPage,
  } = useLazyLoading(handleGetAlertList);

  useEffect(() => {
    loadAlerts(true);
    if (propertyId) {
      getTotalAlerts();
    }
  }, []);

  const handleLoadMoreItems = () => {
    if (loadAlerts) {
      loadAlerts(false);
    }
  };

  const goToAlertNotices = (alertId: string, propertyNum: number) => {
    navigate(`${AlertsScreens.ALERT_NOTICES}/${alertId}`, {
      state: {
        alertId,
        pageTitle: `Matched Notices for Property - ${propertyNum}`,
      },
    });
  };

  const goToAllAlerts = () => {
    navigate(AlertsScreens.ALERTS);
    window.location.reload();
  };

  const renderFooter = () => {
    if (isLastPage || loadMoreError) {
      return null;
    }

    return (
      <div className={styles.centerContainer}>
        <Button onClick={handleLoadMoreItems} loading={loadingMore}>
          {FIELD_LABELS.LOAD_MORE}
        </Button>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className={styles.centerContainer}>
        <p className="empty-text">{STATIC_TEXT.NO_ALERTS}</p>
      </div>
    );
  };

  const renderTotalAlerts = () => {
    return (
      <div className="flex w-auto">
        <BannerView
          bannerText={`You have total ${totalAlerts} alerts in the past`}
          buttonText={FIELD_LABELS.VIEW_ALL}
          onButtonPress={goToAllAlerts}
        />
      </div>
    );
  };

  const renderAlertItem = (item: AlertData, key: string) => {
    return (
      <div key={key} className={styles.alertItemContainer}>
        <div>
          <ImageComponent
            imageSource={require('assets/src/images/alert.png')}
            customImageStyles={styles.alertImage}
            imageResizeMode="contain"
          />
        </div>
        <div className="w-full ml-2">
          <div className={styles.alertInfoHeader}>
            <h6 className="text-sm font-semibold">{STATIC_TEXT.IMPORTANT_ALERT}</h6>
            <p className="text-xs italic">{`${FIELD_LABELS.PROPERTY_ID}: ${item.property_id}`}</p>
          </div>
          <p>{STATIC_TEXT.YOU_HAVE_RECEIVED_ALERT}</p>
          <p className="text-sm font-semibold">{`${item.notices.length} ${STATIC_TEXT.MATCHING_NOTICES}`}</p>
          <div className="flex justify-between items-center">
            <p className="text-xs">{`${FIELD_LABELS.ALERT_ON}: ${formatDateStringToHoursDDMMYY(
              item.alert_on
            )}`}</p>
            <Button variant="secondary" onClick={() => goToAlertNotices(item.id, item.property_id)}>
              {FIELD_LABELS.VIEW}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (isGettingAlertList) {
    return <Spinner modal />;
  }

  if (loadMoreError) {
    return (
      <div className="error-text">
        <p>{loadMoreError}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <h6 className="font-semibold">
        {propertyId && alerts?.length > 0
          ? `Showing alerts for Property: ${alerts[0].property_id}`
          : 'Showing all alerts'}
      </h6>
      <div className={styles.listContainer}>
        <FlatList
          list={alerts.slice(0, 4) as AlertData[]}
          renderItem={(item: AlertData, key: string) => renderAlertItem(item, key)}
          keyExtractor={(item: AlertData) => item.id}
          showsVerticalScrollIndicator={false}
          renderWhenEmpty={() => <div />}
        />
      </div>
      {propertyId && alerts?.length > 0 && renderTotalAlerts()}
      {alerts.length > 4 && (
        <div className={styles.listContainer}>
          <FlatList
            list={alerts.slice(4) as AlertData[]}
            renderItem={(item: AlertData, key: string) => renderAlertItem(item, key)}
            keyExtractor={(item: AlertData) => item.id}
            showsVerticalScrollIndicator={false}
            renderWhenEmpty={() => <div />}
          />
        </div>
      )}
      {alerts.length === 0 && renderEmptyMessage()}
      {renderFooter()}
    </div>
  );
}
