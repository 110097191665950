type paramObj = { [key: string]: string | undefined }

export const createQueryParamString = (params: paramObj) => {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach(key => {
        const val = params[key];
        if (val !== undefined && val !== null) {
            searchParams.append(key, encodeURIComponent(val));
        }
    });

    return searchParams.toString();
};