import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS } from '../../types/endpoints';
import { FetchMethods } from '../../types/common';
import {
  MasterDataResponse,
  MasterDataParams,
  TalukaParams,
  VillageParams,
} from '../../types/master';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';

export const masterApi = createApi({
  reducerPath: 'masterApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    getLocations: builder.query<MasterDataResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.MASTER_LOCATIONS,
        method: FetchMethods.GET
      }),
    }),
    getStates: builder.mutation<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_STATES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getStatesQ: builder.query<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_STATES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getTalukas: builder.mutation<MasterDataResponse, TalukaParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_TALUKAS,
        method: FetchMethods.GET,
        params
      }),
    }),
    getVillages: builder.mutation<MasterDataResponse, VillageParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_VILLAGES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getCities: builder.mutation<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_CITIES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getCitiesQ: builder.query<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_CITIES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getMasterNoticeTypes: builder.mutation<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_NOTICE_TYPES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getPropertyTypes: builder.mutation<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_PROPERTY_TYPES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getPropertyTypesQ: builder.query<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.MASTER_PROPERTY_TYPES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getGenderMaster: builder.query<MasterDataResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.MASTER_GENDERS,
        method: FetchMethods.GET,
      }),
    }),
    getVillagesByCity: builder.query<MasterDataResponse, MasterDataParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.VILLAGES_BY_CITY,
        method: FetchMethods.GET,
        params
      }),
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetCitiesMutation,
  useGetStatesMutation,
  useGetTalukasMutation,
  useGetVillagesMutation,
  useGetMasterNoticeTypesMutation,
  useGetPropertyTypesMutation,
  useGetGenderMasterQuery,
  useLazyGetVillagesByCityQuery,
  useLazyGetCitiesQQuery,
  useLazyGetStatesQQuery,
  useLazyGetPropertyTypesQQuery
} = masterApi;
