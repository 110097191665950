import React, { useState, useEffect } from 'react';

import {
  Button,
  Spinner,
  CenterModal,
  CustomInput,
  CustomDatePicker,
  CustomSelect,
} from '@frontend/web-components';
import { PAGE_TITLES, FIELD_LABELS, FIELD_PLACEHOLDERS, STATIC_TEXT } from '@frontend/common';
import {
  useGetGenderMasterQuery,
  useUpdateUserInfoMutation,
  checkIfValidApiResponse,
  UserInfoResponse,
  UserInfoPayload,
  MasterItem,
  useUserInterestedMutation,
  useGetNoticeTypesQuery,
  useGetLocationsQuery,
} from '@frontend/redux';

// SVG
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';
import { ReactComponent as CloseCircleSVG } from 'assets/src/svgs/close_circle.svg';
import styles from '../Profile.module.css';

type Props = {
  openInterests: boolean;
  refreshUserInfo: () => void;
  setOpenInterests: (visible: boolean) => void;
  userInfo: UserInfoResponse | undefined;
};

export default function PersonalInfo(props: Props) {
  const { openInterests, setOpenInterests, userInfo, refreshUserInfo } = props;

  const [userInterested, { isLoading: isPostingUserInterests }] = useUserInterestedMutation();
  const {
    data: noticeTypes,
    isLoading: isGettingNoticeTypes,
    error: noticeTypesError,
  } = useGetNoticeTypesQuery();
  const {
    data: locations,
    isLoading: isGettingLocations,
    error: locationsError,
  } = useGetLocationsQuery();

  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => {
    setOpenInterests(false);
  };

  const addInterest = async (interest: Partial<MasterItem> | null) => {
    if (!interest || !userInfo) {
      return;
    }

    const interestId = interest.id;
    const payload = {
      notice_types: userInfo.data?.notice_type_interest?.map((type) => type.id) || [],
      locations: userInfo.data?.location_interest?.map((loc) => loc.id) || [],
    };

    const isItNoticeType = noticeTypes?.data.find((type) => type.id === interestId);
    if (isItNoticeType) {
      if (payload.notice_types && interestId) {
        payload.notice_types = [...payload.notice_types, interestId];
      }
    } else {
      if (payload.locations && interestId) {
        payload.locations = [...payload.locations, interestId];
      }
    }

    if (payload.notice_types.length === 0 && payload.locations.length === 0) {
      return;
    }

    const res = await checkIfValidApiResponse(userInterested, payload);

    if (res.isSuccess) {
      refreshUserInfo();
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const removeInterest = async (interestId: string) => {
    const payload = {
      notice_types: userInfo?.data.notice_type_interest?.map((type) => type.id),
      locations: userInfo?.data.location_interest?.map((loc) => loc.id),
    };

    if (userInfo?.data.notice_type_interest?.find((type) => type.id === interestId)) {
      payload.notice_types = payload.notice_types?.filter((type) => type !== interestId);
    } else {
      payload.locations = payload.locations?.filter((loc) => loc !== interestId);
    }

    const res = await checkIfValidApiResponse(userInterested, payload);

    if (res.isSuccess) {
      refreshUserInfo();
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const renderOptionItem = (item: MasterItem) => {
    return (
      <Button
        key={item.id}
        variant="secondary"
        size="small"
        customClasses="mr-2 mb-2"
        onClick={() => removeInterest(item.id)}>
        <div className="flex justify-between items-center">
          <span className="mr-2">{item.name}</span>
          <CloseCircleSVG className='w-[12px]' />
        </div>
      </Button>
    );
  };

  const renderContent = () => {
    const locationInterest = userInfo?.data.location_interest || [];
    const noticeTypesInterest = userInfo?.data.notice_type_interest || [];
    const interests = [...locationInterest, ...noticeTypesInterest];
    const selectedLocationIds = locationInterest.map((elem) => elem.id);
    const selectedNoticeTypeIds = noticeTypesInterest.map((elem) => elem.id);
    const otherLocations =
      locations?.data.filter((elem) => !selectedLocationIds.includes(elem.id)) || [];
    const otherNoticeTypes =
      noticeTypes?.data.filter((elem) => !selectedNoticeTypeIds.includes(elem.id)) || [];

    if (isPostingUserInterests) {
      return (
        <div className="p-4">
          <Spinner size="small" />
        </div>
      );
    }

    return (
      <div className="p-4">
        <div className="flex justify-between">
          <h5 className="font-medium underline underline-offset-8">{FIELD_LABELS.MY_INTERESTS}</h5>
          <button onClick={closeModal}>
            <CloseSVG className="w-[24px]" />
          </button>
        </div>
        <div className="mt-4">{interests.map(renderOptionItem)}</div>
        <div className="grid grid-cols-2 gap-2 mt-4">
          <CustomSelect
            options={otherLocations}
            placeholder="Add City"
            onSelectItem={addInterest}
          />
          <CustomSelect
            options={otherNoticeTypes}
            placeholder="Add Notice Type"
            onSelectItem={addInterest}
          />
        </div>
      </div>
    );
  };

  return <CenterModal visible={openInterests} onClose={closeModal} renderContent={renderContent} />;
}
