import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ImageComponent } from '@frontend/web-components';
import { NoticeScreens, STATIC_TEXT, NOTICE_HIDE_FIELDS, FIELD_LABELS } from '@frontend/common';
import { Notice } from '@frontend/redux';
import { formatKeyToNames } from '@frontend/utils-helpers';

import styles from '../Notice.module.css';

type DetailRowType = {
  idx: number;
  keyProp: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

const RenderDetailRow = ({ keyProp, value, idx }: DetailRowType) => {
  if (NOTICE_HIDE_FIELDS.includes(keyProp)) {
    return null;
  }

  const formatKey = formatKeyToNames(keyProp);
  return <p key={idx}>{`${formatKey}: ${value || STATIC_TEXT.NO_DATA_AVAILABLE}`}</p>;
};

const NoticeItem = React.memo<{ item: Notice }>(({ item }) => {
  const navigate = useNavigate();
  const detailRows = useMemo(
    () =>
      Object.keys(item).map((key, idx) => {
        if (!NOTICE_HIDE_FIELDS.includes(key)) {
          const value = item[key as keyof Notice];
          return <RenderDetailRow key={`${key}-${idx}`} keyProp={key} value={value} idx={idx} />;
        }
        return null;
      }),
    [item]
  );

  const goToNoticeDetails = () => {
    const path = `${NoticeScreens.NOTICE_DETAILS}/${item.id}`;
    navigate(path);
  };

  return (
    <div className={styles.noticeItemContainer}>
      <ImageComponent
        imageSource={item.image}
        customImageStyles={styles.noticeItemImage}
        imageResizeMode="cover"
        blurHeight={item.image && item.is_purchased === 'No' ? 0.5 : 0}
      />
      <div className={styles.noticeItemInfoContainer}>
        <div className={styles.noticeItemInfoTitleContainer}>
          <h5>{item.property_type}</h5>
        </div>
        <div>{detailRows}</div>
        <Button customClasses={styles.detailsButton} onClick={goToNoticeDetails}>
          {FIELD_LABELS.VIEW_DETAIL}
        </Button>
      </div>
    </div>
  );
});

export default NoticeItem;
