import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS, UPDATE_USER_INFO } from '../../types/endpoints';
import { APIResponse, FetchMethods } from '../../types/common';
import {
  UserLoginResponse,
  UserLoginRequest,
  RegisterUserRequest,
  RegisterUserResponse,
  UserInfoResponse,
  UserInterestedRequest,
  UpdateUserInfoRequest,
  DeviceTokenRequest,
} from '../../types/auth';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';
import { MasterDataResponse } from '../../types/master';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    userLogin: builder.mutation<UserLoginResponse, UserLoginRequest>({
      query: ({ mobile, otp, device_name }) => ({
        url: STATIC_URL_ENDPOINTS.USER_LOGIN,
        method: FetchMethods.POST,
        body: {
          mobile,
          otp,
          device_name,
        },
      }),
    }),
    registerUser: builder.mutation<RegisterUserResponse, RegisterUserRequest>({
      query: ({ mobile_number, email, device_name, first_name, last_name, age, gender }) => ({
        url: STATIC_URL_ENDPOINTS.REGISTER_USER,
        method: FetchMethods.POST,
        body: {
          mobile_number,
          email,
          device_name,
          first_name,
          last_name,
          age,
          gender,
        },
      }),
    }),
    userInfo: builder.query<UserInfoResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.USER_INFO,
        method: FetchMethods.GET,
      }),
    }),
    updateUserInfo: builder.mutation<UserInfoResponse, UpdateUserInfoRequest>({
      query: ({ userId, payload }) => ({
        url: UPDATE_USER_INFO(userId),
        method: FetchMethods.PUT,
        body: payload,
      }),
    }),
    userLogout: builder.mutation<void, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.USER_LOGOUT,
        method: FetchMethods.GET,
      }),
    }),
    userInterested: builder.mutation<void, UserInterestedRequest>({
      query: ({ notice_types, locations }) => ({
        url: STATIC_URL_ENDPOINTS.USER_INTERESTED,
        method: FetchMethods.POST,
        body: {
          locations: JSON.stringify(locations),
          notice_types: JSON.stringify(notice_types),
        },
      }),
    }),
    reportConcern: builder.mutation<void, { concern: string }>({
      query: ({ concern }) => ({
        url: STATIC_URL_ENDPOINTS.REPORT_CONCERN,
        method: FetchMethods.POST,
        body: {
          concern,
        },
      }),
    }),
    getDeviceTypes: builder.query<MasterDataResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.DEVICE_TYPES,
        method: FetchMethods.GET,
      }),
    }),
    addDeviceToken: builder.mutation<APIResponse, DeviceTokenRequest>({
      query: (payload) => ({
        url: STATIC_URL_ENDPOINTS.DEVICE_TOKEN,
        method: FetchMethods.POST,
        body: payload,
      }),
    }),
    deleteAccount: builder.mutation<APIResponse, null>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.DELETE_ACCOUNT,
        method: FetchMethods.GET,
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useRegisterUserMutation,
  useUserInfoQuery,
  useUserLogoutMutation,
  useUserInterestedMutation,
  useUpdateUserInfoMutation,
  useReportConcernMutation,
  useAddDeviceTokenMutation,
  useGetDeviceTypesQuery,
  useDeleteAccountMutation
} = authApi;
