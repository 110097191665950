import { useGetDashboardMainQuery, useJoinWaitlistMutation } from '@frontend/redux';
import { useLoader } from '@frontend/utils-hooks';
import { Button } from '@frontend/web-components';
import React from 'react';

const items = [
  {
    img: require('assets/src/images/sub-1.png'),
    title: 'Unlimited Notices',
    desc: 'Search from over 11 lakhs notices from all over India',
  },
  {
    img: require('assets/src/images/sub-2.png'),
    title: 'Zero Hassles',
    desc: 'No need to buy credits every time to view notices',
  },
  {
    img: require('assets/src/images/sub-3.png'),
    title: 'No commitments',
    desc: 'Cancel any time you want',
  },
];

function PromotionalBanner() {
  const { data, isLoading } = useGetDashboardMainQuery();
  const [joinWaitlist] = useJoinWaitlistMutation();
  const { startLoading, endLoading } = useLoader();
  const handleButtonPress = async () => {
    startLoading();
    const res = await joinWaitlist();
    // @ts-ignore
    if (res?.data?.data?.message) {
      // @ts-ignore
      alert(res.data?.data?.message);
    }
    endLoading(true);
  };
  return (
    <>
      <div className="flex mt-12 justify-center items-center">
        <span className="mr-4 text-2xl font-bold">Coming soon</span>
        <img src={require('assets/src/images/infinite_plan.png')} alt="" />
      </div>
      <div className="rounded w-full relative border mt-12 mb-8">
        <div className='grid grid-cols-1 md:grid-cols-2'>
          <div className="flex flex-col justify-center px-4 md:px-20 mt-8">
            {items.map((item, index) => (
              <div key={index} className="flex items-center my-4">
                <img src={item.img} alt="" />
                <div className="flex flex-col ml-4">
                  <span className="text-lg font-bold">{item.title}</span>
                  <span className=" text-sm">{item.desc}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="py-12 px-4 flex flex-col items-center bg-PRIMARY_TINT rounded justify-center text-center">
            <span className="text-2xl font-bold">₹99</span>
            <span className=" text-lg">per month</span>
            <Button
              onClick={handleButtonPress}
              customClasses="w-40 mt-4"
              disabled={isLoading || data?.data.join_waiting_list}>
              {'Join the waitlist'}
            </Button>
            <span className="text-sm mt-4 cursor-pointer">
              {data?.data?.join_waiting_list
                ? 'You have already expressed your interest & joined the waitlist. We will update you as soon as we launch this plan.'
                : 'By continuing you agree to out Terms of use and acknowledge that you have read our privacy policy. per month'}
            </span>
          </div>
        </div>
        <div className="px-6 py-2 bg-PRIMARY absolute top-0 left-20 rounded-b-md">
          <span className="text-white">Infinite benefits</span>
        </div>
      </div>
    </>
  );
}

export default PromotionalBanner;
