import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useRouteFocusEffect(effect: () => void, deps?: React.DependencyList) {
  const location = useLocation();

  useEffect(() => {
    effect();
  }, [location, ...(deps ?? [])]);
}

export default useRouteFocusEffect;
