/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Carousel, Button } from '@frontend/web-components';
import {
  PropertyScreens,
  STATIC_TEXT,
  ColorPalette,
  AlertsScreens,
  FIELD_LABELS,
  PROPERTY_HIDE_FIELDS
} from '@frontend/common';
import { formatKeyToNames } from '@frontend/utils-helpers';
import { PropertyRecord, CreditStatusResponse } from '@frontend/redux';

import { ItemAction } from './types';
import styles from '../Properties.module.css';

// SVGs
import { ReactComponent as DefaultImageSVG } from 'assets/src/svgs/default_image.svg';

const RenderDetailRow = ({ keyProp, value, idx }: { idx: number; keyProp: string; value: any }) => {
  if (PROPERTY_HIDE_FIELDS.includes(keyProp)) {
    return null;
  }

  const formatKey = formatKeyToNames(keyProp);
  return <p key={idx}>{`${formatKey}: ${value || STATIC_TEXT.NO_DATA_AVAILABLE}`}</p>;
};

const renderPropertyImages = (propertyId: string, images: string[]) => {
  if (images.length === 0) {
    return (
      <div className={styles.propertyImage}>
        <DefaultImageSVG fill={ColorPalette.BLACK} className="h-[50%]" />
      </div>
    );
  }

  return (
    <Carousel
      images={images}
      imageStyle={styles.propertyImage}
    />
  );
};

type Props = {
  creditStatus?: CreditStatusResponse;
  isProcessing?: boolean;
  item: PropertyRecord;
  setItemAction: (action: ItemAction) => void;
};

const PropertyItem = React.memo<Props>(({ item, setItemAction, creditStatus, isProcessing }) => {
  const navigate = useNavigate();
  // const [activeImages, setActiveImages] = useState({});

  const isAlertSet = item.notify === 1;
  const remainingCredits = creditStatus?.data?.alert_credits.remaning;

  const goToPropertyDetails = () => {
    const path = isAlertSet
      ? `${PropertyScreens.ADD_PROPERTY}/${item.id}`
      : `${PropertyScreens.EDIT_PROPERTY}/${item.id}`;
    navigate(path);
  };

  const detailRows = useMemo(
    () =>
      Object.keys(item).map((key, idx) => {
        const value = item[key as keyof PropertyRecord];
        if (value && !PROPERTY_HIDE_FIELDS.includes(key)) {
          return <RenderDetailRow key={`${key}-${idx}`} keyProp={key} value={value} idx={idx} />;
        }
        return null;
      }),
    [item]
  );

  const getButtonText = () => {
    let text = '';
    if (isAlertSet) {
      text =
        item.alert_count && item.alert_count > 0
          ? `${STATIC_TEXT.VIEW_ALERTS} (${item.alert_count})`
          : STATIC_TEXT.NO_ALERTS;
    } else {
      if (remainingCredits || remainingCredits === 0) {
        text = remainingCredits > 0 ? STATIC_TEXT.USE_CREDIT : STATIC_TEXT.BUY_CREDIT;
      }
    }
    return text;
  };

  const handleItemPress = () => {
    if (isAlertSet) {
      const path = `${AlertsScreens.ALERTS}/${item.id}`
      navigate(path);
    } else {
      if (remainingCredits || remainingCredits === 0) {
        remainingCredits > 0
          ? setItemAction({
              itemId: item.id,
              type: 'useCredit',
            })
          : setItemAction({
              itemId: item.id,
              type: 'buyCredit',
            });
      }
    }
  };

  return (
    <div className={styles.propertyItemContainer}>
      {renderPropertyImages(item.id, item.image || [])}
      <div className="my-2">
        <h5>{`Property ID: ${item.property_id}`}</h5>
        {isAlertSet && item.expiry_date && <h6>{`Alert expiring on: ${item.expiry_date}`}</h6>}
        {!isAlertSet && <h6>{STATIC_TEXT.ALERT_NOT_SET}</h6>}
      </div>
      <div className="my-2">{detailRows}</div>
      <div className="flex justify-between items-center">
        <Button
          size="small"
          onClick={handleItemPress}
          loading={isProcessing}
          disabled={isProcessing || (isAlertSet && item.alert_count === 0)}>
          {getButtonText()}
        </Button>
        <button className="text-xs underline" onClick={goToPropertyDetails}>
          {isAlertSet ? FIELD_LABELS.VIEW : FIELD_LABELS.EDIT}
        </button>
      </div>
    </div>
  );
});

export default PropertyItem;
