import { Action, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import configureAppStore from './store';

// Store configuration
export const store = configureAppStore();
export const persistor = persistStore(store);
export const getStore = () => store;

// Types for redux usage
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Save in slice and dispatch functions
export const useAppDispatch = () => useDispatch<AppDispatch>(); // used for accessing dispatch in components
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; // used for accessing redux data in components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatch: AppDispatch = (action: any) => {
  return store.dispatch(action);
}; // Direct dispatch when we cannot use the hook ( in functions )
