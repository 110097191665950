export enum FetchMethods {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

type Errors = {
  [key: string]: string[];
};

export interface APIResponse {
  data: Record<string, string> | null;
  errors: Errors;
  isSuccess: boolean;
  message?: string;
}

export type FileData = {
  name: string;
  type: string;
  uri: string;
};