/* eslint-disable @nx/enforce-module-boundaries */
import { useState } from 'react';
import { STATIC_TEXT } from '@frontend/common';
import { checkIfValidApiResponse, usePostCustomEnquiryMutation } from '@frontend/redux';
import Button from '../../Button/Button';
import CheckBox from '../../Checkbox/Checkbox';
const { title, desc, additionalDesc, placeholder, button } =
  STATIC_TEXT.screens.subscription.packages.custom;

export const CustomPackage = () => {
  const [callbackStatus, toggleCallback] = useState(false);
  const [customPackageText, setCustomPackageText] = useState('');
  const [postCustomPackage] = usePostCustomEnquiryMutation();
  const resetState = () => {
    if (callbackStatus) {
      toggleCallback(false);
    }
    setCustomPackageText('');
  };
  const handlePostCustomPackage = async () => {
    const res = await checkIfValidApiResponse(postCustomPackage, {
      requirement: customPackageText,
      callback: callbackStatus,
    });
    if (res.data && res.isSuccess && res.data?.data?.message) {
      alert(res.data.data?.message);
      resetState();
    }
  };

  return (
    <div className="flex flex-col mb-20">
      <span className="mt-12 text-3xl font-bold text-black max-md:mt-10">{title}</span>

      <div className="border-b-[1px] border-SECONDARY mb-10">
        <textarea
          placeholder={placeholder}
          value={customPackageText}
          onChange={(e) => setCustomPackageText(e.target.value)}
          className="mt-10  h-20 border-none outline-none w-full resize-none"
        />
      </div>
      <div className="flex">
        <CheckBox
          isChecked={callbackStatus}
          title={desc}
          desc={additionalDesc}
          onSelect={() => toggleCallback(!callbackStatus)}
        />
        <Button
          onClick={handlePostCustomPackage}
          disabled={!customPackageText}
          customClasses="ml-10">
          {button}
        </Button>
      </div>
    </div>
  );
};

export default CustomPackage;
