/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useGetSearchHistoryQuery,
  getUseQueryError,
  useLazyClearSearchHistoryQuery,
} from '@frontend/redux';
import { Button, Spinner } from '@frontend/web-components';
import { FIELD_LABELS, PAGE_TITLES, STATIC_TEXT, SearchScreens } from '@frontend/common';
import { createQueryParamString } from '@frontend/utils-helpers';
import { useRouteFocusEffect } from '@frontend/utils-hooks';

import NoticeList from '../../Notice/List/NoticeList';
import styles from '../Search.module.css';

// SVG
import { ReactComponent as ArrowRightSVG } from 'assets/src/svgs/right_circle.svg';
import { ReactComponent as DeleteSVG } from 'assets/src/svgs/delete.svg';

export default function SearchHistoryScreen() {
  const {
    data: historyData,
    error,
    isFetching,
    refetch: refreshHistoryData,
  } = useGetSearchHistoryQuery();
  const [clearHistory, { isFetching: isClearing }] = useLazyClearSearchHistoryQuery();

  const navigate = useNavigate();

  useRouteFocusEffect(() => {
    if (!isFetching) {
      refreshHistoryData();
    }
  }, []);

  const goToSearchResults = (query: string) => {
    const queryParams = createQueryParamString({ searchText: query });
    navigate(`${SearchScreens.SEARCH}?${queryParams}`);
  };

  const handleClearHistory = async () => {
    await clearHistory();
    await refreshHistoryData();
  }

  const renderSearchHistory = () => {
    const queries = historyData ? historyData.data.searched_notices : [];
    if (queries.length === 0) {
      return <div className="empty-text">{STATIC_TEXT.NO_SEARCH_HISTORY}</div>;
    }

    return (
      <div className="flex flex-wrap mt-2">
        {queries.map((item: any, index: number) => (
          <Button
            key={index}
            variant="secondary"
            customClasses="mr-2 mb-2"
            onClick={() => goToSearchResults(item.query)}>
            <div className="flex justify-between items-center">
              <span className="mr-2">{item.query}</span>
              <ArrowRightSVG fill="white" className="w-[18px] h-[18px]" />
            </div>
          </Button>
        ))}
      </div>
    );
  };

  const renderViewedNotices = () => {
    const notices = historyData ? historyData.data.viewed_notices : [];

    if (notices.length === 0) {
      return <div className="empty-text">{STATIC_TEXT.NO_VIEWED_NOTICES}</div>;
    }

    return <NoticeList notices={notices} loading={isFetching} pagination={false} />;
  };

  if (isFetching) {
    return <Spinner modal />;
  }

  if (error) {
    return (
      <div className="error-text">
        <p>{getUseQueryError(error)}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className={styles.titleContainer}>
        <h3>{PAGE_TITLES.SEARCH_HISTORY}</h3>
        <Button
          size="small"
          variant="outline"
          customClasses={styles.clearButton}
          onClick={handleClearHistory}
          loading={isClearing}>
          <div className="flex items-center">
            <p>{FIELD_LABELS.CLEAR_HISTORY}</p>
            <DeleteSVG className="w=[20px] h-[20px] ml-2" />
          </div>
        </Button>
      </div>
      <div className="mb-10">{renderSearchHistory()}</div>
      <div className={styles.titleContainer}>
        <h3>{PAGE_TITLES.LAST_VIEWED_NOTICES}</h3>
      </div>
      <div>{renderViewedNotices()}</div>
    </div>
  );
}
