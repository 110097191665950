import Button from '../Button/Button';
import styles from './Styles.module.css';

type BannerViewProps = {
  bannerIcon?: React.ReactNode;
  bannerText: string;
  buttonText: string;
  customClassName?: string;
  onButtonPress: () => void;
  subBannerText?: string;
  variant?: 'black' | 'primary';
};

const BannerView = (props: BannerViewProps) => {
  const {
    bannerText,
    buttonText,
    onButtonPress,
    subBannerText,
    bannerIcon,
    variant = 'primary',
  } = props;

  return (
    <div
      className={`${styles.alertBannerContainer} ${
        variant === 'black' ? 'bg-SECONDARY' : 'bg-PRIMARY'
      }`}>
      <div className={styles.alertBannerText}>
        <div className="flex items-center">
          {bannerIcon && bannerIcon}
          <h5>{bannerText}</h5>
        </div>
        <p>{subBannerText}</p>
      </div>
      <Button variant={variant === 'black' ? 'primary' : 'secondary'} onClick={onButtonPress}>
        <p className="font-bold text-white">{buttonText}</p>
      </Button>
    </div>
  );
};

export default BannerView;
