import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store.utils';
import { AuthActions, SearchActions } from '../../slices';
import { ACTION_TYPES } from '../../actionTypes';

export const createDynamicBaseQuery = () => {
  // Return a function that accepts args, api, and extraOptions
  return async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions?: Record<string, never> | undefined
  ) => {
    // Access the Redux state
    const state = api.getState() as RootState;
    const { token } = state.auth;
    const { apiKey, baseUrl } = state.secrets;

    // Ensure baseUrl is defined
    if (!baseUrl) {
      throw new Error('Base URL is not defined in the state.');
    }

    // Call the original fetchBaseQuery with the dynamic baseUrl and prepareHeaders
    const baseQuery = fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers) => {
        headers.set('Accept', 'application/json');
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('X-API-Key', apiKey || '');
        return headers;
      },
    });

    const result = await baseQuery(args, api, extraOptions || {});
    if (token && result.error && 'status' in result.error && result.error.status === 401) {
      // Trigger all Logout actions
      api.dispatch(AuthActions.resetAuthState());
      api.dispatch(SearchActions.resetSearchState());
      api.dispatch({ type: ACTION_TYPES.CLEAR_ALL_STATE });
    }
    return result;
  };
};
