import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducerKeys } from './keys';

interface SearchReduxState {
    recentSearches: string[];
}

export const initialSearchState: SearchReduxState = {
    recentSearches: [],
};

export const searchSlice = createSlice({
    name: ReducerKeys.SEARCH_SLICE,
    initialState: initialSearchState,
    reducers: {
        addRecentSearch: (state, action: PayloadAction<string>) => {
            if (state.recentSearches.includes(action.payload)) {
                return;
            }
            state.recentSearches = [...state.recentSearches, action.payload];
        },
        resetSearchState: (state) => {
            state.recentSearches = [];
        }
    },
});

export const SearchActions = searchSlice.actions;
export default searchSlice.reducer;