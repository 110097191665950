import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useGetNoticesListQuery,
  useGetCreditStatusQuery,
  getUseQueryError,
  Notice,
  PaymentInfo,
  ReceiptType,
} from '@frontend/redux';
import { FIELD_LABELS, PAGE_TITLES, STATIC_TEXT, NoticeScreens } from '@frontend/common';
import { getReceiptItem } from '@frontend/utils-helpers';
import { Button, ImageComponent, Spinner, BannerView } from '@frontend/web-components';

// SVG
import { ReactComponent as NoteStackSVG } from 'assets/src/svgs/note_stack.svg';
import { ReactComponent as CalendarSVG } from 'assets/src/svgs/calendar.svg';
import { ReactComponent as ReceiptSVG } from 'assets/src/svgs/receipt.svg';

import Reciept from '../../Transactions/List/Receipt';
import styles from '../Notice.module.css';

export default function MyNoticesScreen() {
  const navigate = useNavigate();

  const {
    data: notices,
    isLoading: isGettingNotices,
    error,
    refetch: refreshNotices,
  } = useGetNoticesListQuery();
  const { data: creditStatus, refetch: refreshCreditStatus } = useGetCreditStatusQuery();

  const [openReceiptItem, setOpenReceiptItem] = useState<ReceiptType | null>(null);

  const goToNoticeDetails = (item: Notice) => {
    const path = `${NoticeScreens.NOTICE_DETAILS}/${item.id}`;
    navigate(path);
  };

  const goToSubscriptionPage = () => {};

  const renderEmptyNotices = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <NoteStackSVG />
        <p className="empty-text">{STATIC_TEXT.NO_MY_NOTICES}</p>
      </div>
    );
  };

  const renderNoticeItem = (item: Notice) => {
    const firstPaymentInfo = item.payment_info ? Object.values(item.payment_info)[0] : null;
    const paymentInfo: PaymentInfo | null =
      firstPaymentInfo && typeof firstPaymentInfo === 'object' && firstPaymentInfo
        ? (firstPaymentInfo as unknown as PaymentInfo)
        : null;

    // @ts-ignore
    const receiptItem = paymentInfo ? getReceiptItem(paymentInfo) : null;

    return (
      <div key={item.id} className={styles.myNoticeItemContainer}>
        <div className="flex items-center">
          <CalendarSVG />
          <div className="ml-2">
            <p className="text-xs">{FIELD_LABELS.DATE_OF_PURCHASE}</p>
            <h6 className="text-sm">{item.purchased_on}</h6>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <ImageComponent
            imageSource={item.image}
            customImageStyles={styles.myNoticeImage}
            imageResizeMode="cover"
          />
          <Button size="small" customClasses="w-full" onClick={() => goToNoticeDetails(item)}>
            {FIELD_LABELS.VIEW_DETAIL}
          </Button>
          <Button
            size="small"
            customClasses={styles.purchaseButton}
            onClick={() => setOpenReceiptItem(receiptItem)}>
            <ReceiptSVG />
            <span className="ml-1">{FIELD_LABELS.PURCHASE_DETAIL}</span>
          </Button>
        </div>
      </div>
    );
  };

  const renderNoticeItemList = (items: Notice[]) => {
    return <div className="flex flex-wrap -m-2">{items.map((item) => renderNoticeItem(item))}</div>;
  };

  const renderCreditsBanner = () => {
    const noticeCredits = creditStatus?.data?.notice_credits.remaning;

    return (
      <BannerView
        bannerText={`You have ${noticeCredits} remaining credits`}
        buttonText="Buy Credits"
        onButtonPress={goToSubscriptionPage}
        variant="black"
      />
    );
  };

  if (isGettingNotices) {
    return <Spinner modal />;
  }

  if (error) {
    return (
      <div className="error-text">
        <p>{getUseQueryError(error)}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div>
        <h3>{PAGE_TITLES.NOTICES}</h3>
        <p className="text-xs italic">{STATIC_TEXT.SHOW_NOTICES}</p>
      </div>
      <div className="mt-4">
        {notices && notices.data.length === 0 && renderEmptyNotices()}
        {notices && notices.data.length > 0 && (
          <>
            {renderNoticeItemList(notices.data.slice(0, 4))}
            {renderCreditsBanner()}
            {notices.data.length > 4 && renderNoticeItemList(notices.data.slice(4))}
          </>
        )}
      </div>
      {openReceiptItem && (
        <Reciept receiptItem={openReceiptItem} closeModal={() => setOpenReceiptItem(null)} />
      )}
    </div>
  );
}
