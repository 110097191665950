import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FlatList from 'flatlist-react';

import { FIELD_LABELS, PropertyScreens, STATIC_TEXT } from '@frontend/common';
import { Spinner, Button, PaymentSuccess, CreditSuccess } from '@frontend/web-components';
import {
  useShowPropertiesQuery,
  useUseCreditPropertyMutation,
  useGetCreditStatusQuery,
  checkIfValidApiResponse,
  SubscriptionStatusName,
  PropertyRecord,
} from '@frontend/redux';
import { useSubscriptionWeb } from '../../../hooks/useSubscriptionWeb';

import PropertyItem from './PropertyItem';
import { ItemAction } from './types';
import styles from '../Properties.module.css';

export default function MyPropertiesScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: properties,
    isLoading: isLoadingProperties,
    refetch: refetchProperties,
    error: propertiesError,
  } = useShowPropertiesQuery();
  const [useCreditProperty, { isLoading: isUsingCredit }] = useUseCreditPropertyMutation();
  const {
    data: creditStatus,
    refetch: refetchCreditStatus,
    isLoading: isGettingCreditStatus,
  } = useGetCreditStatusQuery();

  const {
    handlePayment,
    isLoading: isBuyingCredit,
    subscriptionCredits,
    updateStatus: paymentStatus,
    updateMessage,
  } = useSubscriptionWeb();

  const [errorMessage, setErrorMessage] = useState('');
  const [useCreditSuccess, setUseCreditSuccess] = useState(false);
  const [useCreditSuccessMessage, setUseCreditSuccessMessage] = useState('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [itemAction, setItemAction] = useState<ItemAction | null>(null);

  useEffect(() => {
    if (location.state?.refetched) {
      refetchProperties();
    }
  }, [location.state?.refetched]);

  useEffect(() => {
    if (itemAction) {
      if (itemAction.type === 'useCredit') {
        handleUseCredit(itemAction.itemId);
      } else {
        handleBuyCredit();
      }
    }
  }, [itemAction]);

  const refreshData = () => {
    refetchProperties();
    refetchCreditStatus();
  };

  useEffect(() => {
    // When Payment is completed, show success modal and initiate use credit
    if (paymentStatus === SubscriptionStatusName.COMPLETED) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        if (itemAction?.itemId) {
          handleUseCredit(itemAction.itemId);
        }
      }, 1000);
    }
  }, [paymentStatus]);

  const closeCreditSucess = () => {
    setItemAction(null);
    setUseCreditSuccess(false);
    setUseCreditSuccessMessage('');
  };

  const handleUseCredit = async (itemId: string) => {
    const res = await checkIfValidApiResponse(useCreditProperty, { propertyId: itemId });
    if (res && res.isSuccess) {
      setOpenSuccessModal(false);
      setUseCreditSuccess(true);
      setUseCreditSuccessMessage(res.data.data?.message);
      refreshData();
    } else {
      const message = res?.data?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const handleBuyCredit = async () => {
    const alertCredit = subscriptionCredits.find((item) =>
      item.type.toLowerCase().includes('alert')
    );
    const payload = {
      total_alert: 1,
      total_notice: 0,
      amount: Number(alertCredit?.amount),
    };
    await handlePayment(payload);
  };

  const renderPropertyItem = (item: PropertyRecord, key: string) => {
    return (
      <PropertyItem
        key={key}
        item={item}
        creditStatus={creditStatus}
        setItemAction={setItemAction}
        isProcessing={itemAction?.itemId === item.id && (isBuyingCredit || isUsingCredit)}
      />
    );
  };

  const renderItem = useCallback(renderPropertyItem, [
    properties,
    creditStatus,
    isBuyingCredit,
    isUsingCredit,
  ]);

  if (isLoadingProperties || isGettingCreditStatus) {
    return <Spinner modal />;
  }

  if (properties?.data.length === 0) {
    return (
      <div className={styles.centerContainer}>
        <div className='flex flex-col'>
          <p className="empty-text mb-4">{STATIC_TEXT.NO_PROPERTIES_FOUND}</p>
          <Button onClick={() => navigate(PropertyScreens.ADD_PROPERTY)}>
            {FIELD_LABELS.ADD_PROPERTY}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="flex items-center justify-between">
        <h3>{STATIC_TEXT.MY_PROPERTIES}</h3>
        <Button onClick={() => navigate(PropertyScreens.ADD_PROPERTY)}>
          {FIELD_LABELS.ADD_PROPERTY}
        </Button>
      </div>
      <div className={styles.listContainer}>
        <FlatList
          list={properties ? properties.data : []}
          renderItem={renderItem}
          keyExtractor={(item: PropertyRecord) => item.id}
          renderWhenEmpty={() => <div />}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.propertyListContainer}
        />
      </div>
      <CreditSuccess
        visible={useCreditSuccess}
        closeModal={() => setUseCreditSuccess(false)}
        type="alert"
        message={useCreditSuccessMessage}
        onPress={closeCreditSucess}
      />
      <PaymentSuccess
        visible={openSuccessModal}
        closeModal={() => setOpenSuccessModal(false)}
        message={updateMessage}
      />
    </div>
  );
}
