import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { ImageComponent } from '../ImageComponent/ImageComponent';

import { ReactComponent as LeftCircleSVG } from 'assets/src/svgs/leftcircle.svg';
import { ReactComponent as RightCircleSVG } from 'assets/src/svgs/right_circle.svg';

type Props = {
  imageStyle: string;
  images: string[];
};

function Carousel({ images, imageStyle }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState<number | null>(null);

  const nextImage = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const renderLeftButton = () => {
    if (currentIndex === 0) {
        return null;
    };

    return (
      <button onClick={prevImage} className="absolute left-2 z-10">
        <LeftCircleSVG fill="white" className="w-[24px]" />
      </button>
    );
  };

  const renderRightButton = () => {
    if (currentIndex === images.length - 1) {
        return null;
    };

    return (
      <button onClick={nextImage} className="absolute right-2 z-10">
        <RightCircleSVG fill="white" className="w-[24px]" />
      </button>
    );
  };

  const movingRight =
    (prevIndex !== null && currentIndex > prevIndex) ||
    (prevIndex === images.length - 1 && currentIndex === 0);
  const movingLeft =
    (prevIndex !== null && currentIndex < prevIndex) ||
    (prevIndex === 0 && currentIndex === images.length - 1);

  return (
    <div className="flex flex-col items-center">
      <div className="overflow-hidden w-full max-w-3xl relative">
        {images.map((image, index) => (
          <Transition
            key={image}
            show={currentIndex === index}
            enter="transform transition ease-in-out duration-500"
            enterFrom={movingRight ? 'translate-x-full' : movingLeft ? '-translate-x-full' : ''}
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo={movingRight ? '-translate-x-full' : movingLeft ? 'translate-x-full' : ''}
            className={`inset-0 w-full h-full flex items-center justify-center ${
              currentIndex === index ? '' : 'hidden'
            }`}>
            <div className="relative w-full h-full flex items-center">
              {renderLeftButton()}
              <ImageComponent imageSource={image} customImageStyles={imageStyle} />
              {renderRightButton()}
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
