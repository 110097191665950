import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS, USE_CREDIT_PROPERTY, EDIT_PROPERTY, GET_PROPERTY } from '../../types/endpoints';
import { FetchMethods, APIResponse } from '../../types/common';
import { PropertyRecordResponse, NewPropertyRequest, PropertyDetailsParams, EditPropertyRequest } from '../../types/property';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';
import { MasterDataResponse } from '../../types/master';

export const propertyApi = createApi({
    reducerPath: 'propertyApi',
    baseQuery: createDynamicBaseQuery(),
    endpoints: (builder) => ({
        showProperties: builder.query<PropertyRecordResponse, void>({
            query: () => ({
                url: STATIC_URL_ENDPOINTS.SHOW_PROPERTIES,
                method: FetchMethods.GET
            }),
        }),
        getPropertySystems: builder.query<MasterDataResponse, void>({
            query: () => ({
                url: STATIC_URL_ENDPOINTS.PROPERTY_SYSTEMS,
                method: FetchMethods.GET
            }),
        }),
        addProperty: builder.mutation<APIResponse, NewPropertyRequest>({
            query: (payload) => ({
                url: STATIC_URL_ENDPOINTS.ADD_PROPERTY,
                method: FetchMethods.POST,
                body: payload
            })
        }),
        editProperty: builder.mutation<APIResponse, EditPropertyRequest>({
            query: ({ payload, propertyId }) => ({
                url: EDIT_PROPERTY(propertyId),
                method: FetchMethods.PUT,
                body: payload,
            })
        }),
        getPropertyDetails: builder.mutation<PropertyRecordResponse, PropertyDetailsParams>({
            query: ({ propertyId }) => ({
                url: GET_PROPERTY(propertyId),
                method: FetchMethods.GET
            }),
        }),
        useCreditProperty: builder.mutation<void, PropertyDetailsParams>({
            query: ({ propertyId }) => ({
                url: USE_CREDIT_PROPERTY(propertyId),
                method: FetchMethods.GET
            }),
        }),
    }),
});

export const {
    useShowPropertiesQuery,
    useAddPropertyMutation,
    useGetPropertySystemsQuery,
    useUseCreditPropertyMutation,
    useEditPropertyMutation,
    useGetPropertyDetailsMutation
} = propertyApi;