export enum STACKS {
  ALERTS = '/alerts',
  AUTH_STACK = '/auth',
  BOOKMARKS = '/bookmarks',
  HISTORY = '/history',
  HOME_STACK = '/',
  NOTICES = '/notices',
  PROFILE = '/profile',
  PROPERTIES = '/properties'
}

export enum AuthScreens {
  INTEREST = '/auth/interest',
  LOGIN = '/auth/sign-in',
  SIGN_UP = '/auth/register'
}

export enum HomeScreens {
  DASHBOARD = '/',
  MOST_BOOKMARKED_NOTICES = '/most-bookmarked-notices',
  MOST_VIEWED_NOTICES = '/most-viewed-notices',
  NEWSPAPER_NOTICES = '/newspaper-notices',
  NOTICES_BY_TYPES = '/notices-by-types',
  SUBSCRIPTION = '/subscription',
  TODAY_NOTICES = '/today-notices',
}

export enum NoticeScreens {
  MY_NOTICES = '/profile/notices',
  NOTICE_DETAILS = '/profile/notices/details'
}

export enum PropertyScreens {
  ADD_PROPERTY = '/profile/properties/add',
  EDIT_PROPERTY = '/profile/properties/edit',
  MY_PROPERTIES = '/profile/properties'
}

export enum SearchScreens {
  HISTORY = '/search-history',
  SEARCH = '/search-results'
}

export enum BookmarksScreens {
  BOOKMARKS = '/profile/bookmarks'
}

export enum ProfileScreens {
  PROFILE = '/profile',
  TRANSACTIONS = '/profile/transactions',
}

export enum BottomTabScreens {
  BOOKMARKS = '/bookmarks-tab',
  HISTORY = '/history-tab',
  HOME = '/home-tab',
  PROFILE = '/profile-tab'
}

export enum ScreenLabels {
  BOOKMARKS = 'Bookmarks',
  HISTORY = 'History',
  HOME = 'Home',
  PROFILE = 'Profile'
}

export enum DrawerScreens {
  HOME = '/home-drawer',
}

export enum AlertsScreens {
  ALERT_NOTICES = '/alerts/notices',
  ALERTS = '/alerts'
}
