import React from 'react';
import spinnerImage from 'assets/src/images/loading.gif';

type Props = {
  modal?: boolean;
  size?: string;
};

const Spinner = (props: Props) => {
  const { modal, size } = props;
  const iconSize = size === 'small' ? 'w-6 h-6' : 'w-10 h-10';

  const renderSpinner = () => (
    <div className="flex justify-center items-center">
      <img src={spinnerImage} className={`${iconSize} object-contain`} alt="Loading..." />
    </div>
  );

  if (modal) {
    return (
      <div className="fixed inset-0 bg-tranparent flex justify-center items-center z-50">
        {renderSpinner()}
      </div>
    );
  }

  return renderSpinner();
};

export default Spinner;
