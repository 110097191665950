import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Props = {
  onClose: () => void;
  renderContent: () => React.ReactNode;
  visible: boolean;
};

export default function MobileMenu(props: Props) {
  const { visible, onClose, renderContent } = props;

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed top-20 inset-x-0 bottom-0 flex items-start justify-center">
          <Transition.Child
            as={Fragment}
            enter="transition ease-down duration-300"
            enterFrom="transform translate-y-[-100%]"
            enterTo="transform translate-y-0"
            leave="transition ease-up duration-200"
            leaveFrom="transform translate-y-0"
            leaveTo="transform translate-y-[-100%]">
            <Dialog.Panel className="w-full h-[100vh] bg-white p-0 overflow-y-auto">
              <div>{renderContent()}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}