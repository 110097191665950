import { CenterModal, ImageComponent } from '@frontend/web-components';
import { STATIC_TEXT } from '@frontend/common';

// SVG
import { ReactComponent as CloseSVG } from 'assets/src/svgs/close.svg';

type Props = {
  closeModal: () => void;
  message: string;
  visible: boolean;
};

export default function PaymentSuccess(props: Props) {
  const { visible, closeModal, message } = props;

  const renderContent = () => {
    return (
      <div className="flex flex-col items-center justify-center px-6 pt-4 pb-8">
        <div className="flex w-full items-center justify-end">
          <button onClick={closeModal}>
            <CloseSVG />
          </button>
        </div>
        <ImageComponent
          imageSource={require('assets/src/images/payment_success.png')}
          customImageStyles=""
          imageResizeMode="contain"
        />
        <p className="text-3xl font-bold py-4">{STATIC_TEXT.PAYMENT_SUCCESS}</p>
        <p className="text-base font-medium">{message}</p>
      </div>
    );
  };

  return <CenterModal visible={visible} onClose={closeModal} renderContent={renderContent} />;
}
