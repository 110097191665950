import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { STATIC_TEXT } from '@frontend/common';
import { APIResponse } from '../types/common';

export const getUseQueryError = (error: FetchBaseQueryError | SerializedError): string => {
    if ('data' in error) {
        // Handling FetchBaseQueryError
        const errorData = error.data as APIResponse;
        return errorData?.message || STATIC_TEXT.GENERIC_ERROR;
    } else if ('name' in error && 'message' in error) {
        // Handling SerializedError
        return error.message || STATIC_TEXT.GENERIC_ERROR;
    }
    return STATIC_TEXT.GENERIC_ERROR;
}