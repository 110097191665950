import { Outlet } from 'react-router-dom';

import { Header, Footer } from '@frontend/web-components';
import styles from './Layout.module.css';

export default function Layout() {
  return (
    <div className='app'>
      <Header />
      <main className={styles.authMainContainer}>
        <Outlet />
      </main>
    </div>
  );
}
