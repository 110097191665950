export const formatKeyToNames = (key: string) => {
    return key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const formatNumberIndian = (num: number | null, decimal = 2) => {
    if (!num) {
        return '0';
    } else if (num < 1000) {
        // If the number is less than 1000, return it as is
        return num.toFixed(decimal);
    } else if (num < 100000) {
        // If the number is between 10,000 and 99,999, format it as K (thousands)
        const formatNum = (num / 1000).toFixed(decimal);
        if (formatNum === '100') {
            return '1L';
        } else {
            return formatNum + 'K';
        }
    } else if (num < 10000000) {
        // If the number is between 1,00,000 and 99,99,999, format it as L (lakhs)
        const formatNum = (num / 100000).toFixed(decimal);
        if (formatNum === '100') {
            return '1Cr';
        } else {
            return formatNum + 'L';
        }
    } else {
        // If the number is 1,00,00,000 or more, format it as Cr (crores)
        return (num / 10000000).toFixed(decimal) + 'Cr';
    }
}

export function toKeyableText(input: string) {
    // Convert to lowercase and replace non-alphanumeric characters with underscores
    return input.toLowerCase().replace(/[^a-z0-9]+/g, '_');
}