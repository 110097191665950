import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAppSelector, RootState, useGetCreditStatusQuery } from '@frontend/redux';
import {
  AlertsScreens,
  AuthScreens,
  BookmarksScreens,
  HomeScreens,
  NoticeScreens,
  ProfileScreens,
  PropertyScreens,
  STACKS,
  SearchScreens,
} from '@frontend/common';
import { useSetAPICreds } from '@frontend/utils-hooks';
import {
  DashboardScreen,
  SignInScreen,
  SignUpScreen,
  SearchResultScreen,
  BookmarkedNoticesScreen,
  MostViewedNoticesScreen,
  NewspaperNoticesScreen,
  NoticesByTypesScreen,
  SearchHistoryScreen,
  NoticeDetailsScreen,
  MyNoticesScreen,
  BookmarksScreen,
  TransactionsListScreen,
  ProfileScreen,
  AlertsScreen,
  AlertNoticesScreen,
  MyPropertiesScreen,
  AddPropertyScreen,
  SubscriptionPage,
  TodayNoticesScreen,
} from '../screens';

import Layout from './Layout';
import AuthLayout from './AuthLayout';

export default function NavigationWrapper() {
  // Check if the user is logged in using Redux state
  const loggedIn = useAppSelector((state: RootState) => state.auth.token);
  const { baseUrl } = useAppSelector((state) => state.secrets);

  const { isLoading: apiCredsSetting } = useSetAPICreds({
    apiKey: process.env.NX_API_KEY || '',
    baseUrl: process.env.NX_BASE_URL || '',
  });
  // Updates Redux State
  useGetCreditStatusQuery(undefined, {
    skip: !loggedIn,
  });

  if (!baseUrl || apiCredsSetting) {
    // Add Loader
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        {loggedIn ? (
          <Route element={<Layout />}>
            <Route index element={<DashboardScreen />} />
            <Route path={SearchScreens.SEARCH} element={<SearchResultScreen />} />
            <Route
              path={`${NoticeScreens.NOTICE_DETAILS}/:noticeId`}
              element={<NoticeDetailsScreen />}
            />
            <Route path={HomeScreens.MOST_VIEWED_NOTICES} element={<MostViewedNoticesScreen />} />
            <Route
              path={HomeScreens.MOST_BOOKMARKED_NOTICES}
              element={<BookmarkedNoticesScreen />}
            />
            <Route path={HomeScreens.NEWSPAPER_NOTICES} element={<NewspaperNoticesScreen />} />
            <Route path={HomeScreens.NOTICES_BY_TYPES} element={<NoticesByTypesScreen />} />
            <Route path={SearchScreens.HISTORY} element={<SearchHistoryScreen />} />
            <Route path={NoticeScreens.MY_NOTICES} element={<MyNoticesScreen />} />
            <Route path={BookmarksScreens.BOOKMARKS} element={<BookmarksScreen />} />
            <Route path={ProfileScreens.PROFILE} element={<ProfileScreen />} />
            <Route path={ProfileScreens.TRANSACTIONS} element={<TransactionsListScreen />} />
            {/* Route for when propertyId is present */}
            <Route path={`${AlertsScreens.ALERTS}/:propertyId`} element={<AlertsScreen />} />
            {/* Route for when propertyId is not present */}
            <Route path={AlertsScreens.ALERTS} element={<AlertsScreen />} />
            <Route
              path={`${AlertsScreens.ALERT_NOTICES}/:noticeId`}
              element={<AlertNoticesScreen />}
            />
            <Route path={PropertyScreens.MY_PROPERTIES} element={<MyPropertiesScreen />} />
            <Route
              path={`${PropertyScreens.ADD_PROPERTY}/:propertyId`}
              element={<AddPropertyScreen />}
            />
            <Route
              path={`${PropertyScreens.EDIT_PROPERTY}/:propertyId`}
              element={<AddPropertyScreen />}
            />
            <Route path={PropertyScreens.ADD_PROPERTY} element={<AddPropertyScreen />} />
            <Route path={HomeScreens.SUBSCRIPTION} element={<SubscriptionPage />} />
            <Route path={HomeScreens.TODAY_NOTICES} element={<TodayNoticesScreen />} />
            {/* Redirect any non-matched route to the root or login */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        ) : (
          <Route element={<AuthLayout />}>
            <Route index element={<Navigate replace to={AuthScreens.LOGIN} />} />
            <Route path={STACKS.AUTH_STACK}>
              <Route path={AuthScreens.LOGIN} element={<SignInScreen />} />
              <Route path={AuthScreens.SIGN_UP} element={<SignUpScreen />} />
            </Route>
            {/* Redirect any non-matched route to the root or login */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}
