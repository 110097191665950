/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FlatList from 'flatlist-react';

import { ColorPalette, FIELD_LABELS, STATIC_TEXT } from '@frontend/common';
import {
  ReceiptType,
  getUseQueryError,
  useGetCreditStatusQuery,
  useGetCreditTransactionsQuery,
} from '@frontend/redux';
import { Spinner } from '@frontend/web-components';
import { getReceiptItem } from '@frontend/utils-helpers';

// SVG
import { ReactComponent as CalendarSVG } from 'assets/src/svgs/calendar.svg';
import { ReactComponent as AlertSVG } from 'assets/src/svgs/alert.svg';
import { ReactComponent as EyeSVG } from 'assets/src/svgs/eye.svg';
import { ReactComponent as AlertCreditSVG } from 'assets/src/svgs/alert_credit.svg';
import { ReactComponent as ViewCreditSVG } from 'assets/src/svgs/view_credit.svg';

import styles from '../Transactions.module.css';
import Reciept from './Receipt';

export default function TransactionsList() {
  const { data: creditStatus, isLoading: isGettingCreditStatus } = useGetCreditStatusQuery();
  const {
    data: transactions,
    isLoading: isGettingTransactions,
    error,
    refetch: refreshTransactions,
  } = useGetCreditTransactionsQuery();

  const [receiptItem, setOpenReceiptItem] = useState<ReceiptType | null>(null);

  const renderTransactionItem = (item: any) => {
    const consItem = getReceiptItem(item);
    if (!consItem) {
      return null;
    }

    return (
      <div key={item[0].id} className={styles.transactionItemContainer}>
        <div className="flex items-center mb-2">
          <CalendarSVG className="w-[20px] h-[20px]" />
          <h6 className="text-xs px-2">{`${
            FIELD_LABELS.TRANSACTION_DATE
          }: ${consItem.dateOfPurchase.substring(0, 10)}`}</h6>
        </div>
        <div className="flex items-center mb-2">
          <AlertSVG fill="black" className="w-[20px] h-[20px]" />
          <p className="text-xs px-2">{`${FIELD_LABELS.ALERT_CREDITS}: ${consItem.alertCredits}`}</p>
        </div>
        <div className="flex items-center mb-2">
          <EyeSVG className="w-[20px] h-[20px]" />
          <p className="text-xs px-2">{`${FIELD_LABELS.VIEW_CREDITS}: ${consItem.noticeCredits}`}</p>
        </div>
        <p className="text-xs font-semibold">{`Total ${consItem.totalCredits} Credits`}</p>
        <div className="flex items-center justify-between mt-4">
          <p className="text-sm font-semibold">{`${FIELD_LABELS.AMOUNT} ${'\u20B9'}${
            consItem.totalPayment
          }`}</p>
          <button
            className="text-PRIMARY text-sm font-semibold"
            onClick={() => setOpenReceiptItem(consItem)}>
            {FIELD_LABELS.VIEW_RECEIPT}
          </button>
        </div>
      </div>
    );
  };

  const renderCreditStatus = () => {
    return (
      <div className={styles.balanceCreditsContainer}>
        <div className="flex items-center">
          <AlertCreditSVG className="h-[46px] w-[40px]" />
          <p>{`${FIELD_LABELS.ALERT_CREDITS}: ${creditStatus?.data?.alert_credits?.remaning}`}</p>
        </div>
        <div className="flex items-center">
          <ViewCreditSVG className="h-[46px] w-[40px]" />
          <p>{`${FIELD_LABELS.VIEW_CREDITS}: ${creditStatus?.data?.notice_credits?.remaning}`}</p>
        </div>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className="flex justify-center items-center">
        <p className="empty-text">{STATIC_TEXT.NO_TRANSACTIONS}</p>
      </div>
    );
  };

  if (isGettingCreditStatus || isGettingTransactions) {
    return <Spinner modal />;
  }

  if (error) {
    return (
      <div className="error-text">
        <p>{getUseQueryError(error)}</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <h3>{FIELD_LABELS.BALANCE}</h3>
      {renderCreditStatus()}
      <h3>{STATIC_TEXT.RECENT_PURCHASES}</h3>
      <div className={styles.listContainer}>
        <FlatList
          list={transactions ? Object.values(transactions.data) : []}
          renderItem={(item) => renderTransactionItem(item)}
          keyExtractor={(item: any) => item[0].id}
          showsVerticalScrollIndicator={false}
          renderWhenEmpty={renderEmptyMessage}
        />
      </div>
      {receiptItem && (
        <Reciept receiptItem={receiptItem} closeModal={() => setOpenReceiptItem(null)} />
      )}
    </div>
  );
}
