import { useState, useEffect } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useRegisterUserMutation,
  useSendEmailOtpMutation,
  useValidateEmailOtpMutation,
  checkIfValidApiResponse,
  AuthActions
} from '@frontend/redux';
import { useDispatch } from 'react-redux';
import { STATIC_TEXT, CONSTANTS } from '@frontend/common';

type RegisterUserRequest = {
  age?: number;
  device_name: string;
  email: string;
  first_name?: string;
  gender?: string;
  last_name?: string;
  mobile_number: string;
}

const useSignUp = () => {
  const dispatch = useDispatch();
  const [registerUser, { isLoading: isRegistering }] = useRegisterUserMutation();
  const [sendEmailOtp, { isLoading: isSendingEmailOtp }] = useSendEmailOtpMutation();
  const [validateEmailOtp, { isLoading: isValidatingEmailOtp }] = useValidateEmailOtpMutation();

  const [errorMessage, setErrorMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [lastOtpSentCount, setLastOtpSentCount] = useState(0);

  // TEMP: Remove this once the SMS service is ready
  const [apiOtp, setApiOtp] = useState('');

  useEffect(() => {
    // Exit early when we reach 0
    if (lastOtpSentCount === 0) {
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders or unmounts
    const intervalId = setInterval(() => {
      setLastOtpSentCount(prevLastOtpSent => prevLastOtpSent - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [lastOtpSentCount]);

  const sendOtpHandler = async (email: string) => {
    // Reset error message
    setErrorMessage('');
    // Call the API
    const res = await checkIfValidApiResponse(sendEmailOtp, { email });
    if (res.isSuccess && res.data) {
      // console.log('res', res.data); // eslint-disable-line no-console
      const otp = res.data.data?.otp?.token;
      if (process.env.NODE_ENV === 'development') {
        setApiOtp(otp)
      }
      if (res.data.data?.otp?.otp_status) {
        dispatch(AuthActions.setNewUser(res.data.data?.new_user));
        setIsOtpSent(true);
        setLastOtpSentCount(CONSTANTS.RESEND_OTP_TIME);
      }
    } else {
      // Handle error, e.g., show an error message to the user.
      setLastOtpSentCount(0);
      setErrorMessage(res.message || STATIC_TEXT.GENERIC_ERROR);
    }
  };

  const validateOtpHandler = async (email: string, otp: string, registerForm: RegisterUserRequest) => {
    setErrorMessage('');
    const res = await checkIfValidApiResponse(validateEmailOtp, { email, otp });
    setIsOtpSent(false);
    if (res.isSuccess && res.data) {
      // Handle success, e.g., navigate to the next screen, show a success message, etc.
      if (res.data.data?.otp?.otp_status) {
        setIsEmailVerified(true);
        await handleRegister(registerForm);
      } else {
        // Handle error, e.g., show an error message to the user.
        setErrorMessage(STATIC_TEXT.GENERIC_ERROR);
      }
    } else {
      // Handle error, e.g., show an error message to the user.
      const message = res.message || res.data.data?.otp?.message || STATIC_TEXT.GENERIC_ERROR;
      setErrorMessage(message);
    }
  };

  const handleRegister = async (registerForm: RegisterUserRequest) => {
    setErrorMessage('');
    const res = await checkIfValidApiResponse(registerUser, registerForm);
    if (res.isSuccess && res.data) {
      // Handle success
      dispatch(AuthActions.setToken(res.data.data?.token));
    } else {
      // Handle error, e.g., show an error message to the user.
      setErrorMessage(res.message || STATIC_TEXT.GENERIC_ERROR);
    }
  };

  return {
    isOtpSent,
    isEmailVerified,
    errorMessage,
    sendOtpHandler,
    validateOtpHandler,
    handleRegister,
    isSendingEmailOtp,
    isValidatingEmailOtp,
    isRegistering,
    setIsOtpSent,
    lastOtpSentCount,
    apiOtp, // TEMP: Remove this once the SMS service is ready
  };

};

export default useSignUp;