import { parseISO, isBefore, isValid } from 'date-fns';

export const formatDateToYYYYMMDD = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth returns 0-11
    const day = date.getDate();

    // Pad the month and day with leading zeros if needed
    const monthFormatted = month < 10 ? `0${month}` : month;
    const dayFormatted = day < 10 ? `0${day}` : day;

    return `${year}-${monthFormatted}-${dayFormatted}`;
}

export const calculateAge = (dob: string) => {
    if (!dob) { return null; }

    const birthday = new Date(dob);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    const result = Math.abs(ageDate.getUTCFullYear() - 1970);
    return result;
};

export const formatDateStringToHoursDDMMYY = (dateString: string) => {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const year = date.getFullYear().toString().substr(-2); // Get last two digits

    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Format day and month with leading zeros if needed
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${hours}:${formattedMinutes} ${ampm}, ${formattedDay}/${formattedMonth}/${year}`;
}

export function isDateRangeValid(publishDateRange: string[]) {
    const [startDateStr, endDateStr] = publishDateRange;

    // Check if both dates are provided and in the correct format
    if (!startDateStr || !endDateStr || startDateStr.length !== 10 || endDateStr.length !== 10) {
        return false;
    }

    // Parse the strings into Date objects
    const startDate = parseISO(startDateStr);
    const endDate = parseISO(endDateStr);

    // Further validate the date objects
    if (!isValid(startDate) || !isValid(endDate)) {
        return false;
    }

    // Check if the start date is before the end date
    return isBefore(startDate, endDate);
}