export const getOrCreateBrowserId = () => {
    let browserId = localStorage.getItem('browserId');
    if (!browserId) {
      browserId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      localStorage.setItem('browserId', browserId);
    }
    return browserId;
}