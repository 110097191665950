import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReducerKeys } from './keys';

interface SecretsReduxState {
  apiKey: string;
  baseUrl: string;
}

export const initialSecretsState: SecretsReduxState = {
  apiKey: '',
  baseUrl: '',
};

export const secretsSlice = createSlice({
  name: ReducerKeys.SECRETS_SLICE,
  initialState: initialSecretsState,
  reducers: {
    setSecrets: (state, { payload }: PayloadAction<{ apiKey: string; baseUrl: string }>) => {
      state.apiKey = payload.apiKey;
      state.baseUrl = payload.baseUrl;
    },
  },
});

export const { setSecrets } = secretsSlice.actions;
export default secretsSlice.reducer;
