export const transformFiltersToStringParams = (filters: any) => {
    const transformed: any = {};
    Object.keys(filters).forEach((key) => {
        // Check if the array is not empty
        if (filters[key].length > 0) {
            if (Array.isArray(filters[key])) {
                // Join the array elements into a comma-separated string
                transformed[key] = filters[key].join(',');
            } else {
                transformed[key] = filters[key];
            }
        }
    });
    return transformed;
};
