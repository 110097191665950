import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import OtpInput from 'react-otp-input';

import { AuthScreens, STATIC_TEXT, CONSTANTS, FIELD_LABELS, FIELD_PLACEHOLDERS } from '@frontend/common';
import { useNavigate } from 'react-router-dom';

import { useSignIn } from '@frontend/utils-hooks';
import { getOrCreateBrowserId } from '@frontend/utils-helpers';
import { Button } from '@frontend/web-components';
// SVGs
import { ReactComponent as SignInPageBGSVG } from 'assets/src/svgs/sign_in_bg.svg';

import styles from '../Auth.module.css';

export const SignInScreen = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
      otp: '',
    },
  });

  const {
    isOtpSent,
    isNewUser,
    errorMessage,
    sendOtpHandler,
    validateOtpHandler,
    userLoginHandler,
    isSendingOtp,
    isValidatingOtp,
    isUserLogging,
    lastOtpSentCount,
    apiOtp, // TEMP: Remove this once the SMS service is ready
  } = useSignIn();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (apiOtp) {
        setValue('otp', apiOtp);
      }
    }
  }, [apiOtp]);

  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);

  const goToRegisterScreen = () => {
    const phoneNumber = getValues('phoneNumber');
    navigate(AuthScreens.SIGN_UP, { state: { mobile: phoneNumber } });
  };

  const goToDashboard = () => {
    navigate('/');
  };

  const onSubmit = async () => {
    const phoneNumber = getValues('phoneNumber');
    const otp = getValues('otp');
    const deviceName = await getOrCreateBrowserId();
    if (isOtpSent) {
      if (isNewUser === true) {
        validateOtpHandler(phoneNumber, otp, goToRegisterScreen); // Call handleValidateOtp if OTP is sent and it's a new user
      } else {
        userLoginHandler(phoneNumber, otp, deviceName, goToDashboard); // Call handleLogin if OTP is sent and it's not a new user
      }
    } else {
      sendOtpHandler(phoneNumber); // Call handleSendOtp if OTP is not sent
    }
  };

  const renderResendOtp = () => {
    const phoneNumber = getValues('phoneNumber');
    return (
      <div className={styles.otpContainer}>
        {lastOtpSentCount === 0 && (
          <>
            <p className={styles.termsText}>{STATIC_TEXT.OTP_NOT_RECEIVED}</p>
            <button
              onClick={() => sendOtpHandler(phoneNumber)}
              disabled={lastOtpSentCount > 0}
              className={`${styles.termsText} ${styles.emailLink}`}>
              {STATIC_TEXT.RESEND_OTP}
            </button>
          </>
        )}
        {lastOtpSentCount > 0 && (
          <p className={styles.termsText}>{`Resend OTP in ${lastOtpSentCount}s`}</p>
        )}
      </div>
    );
  };

  const renderOtpInput = () => {
    return (
      <>
        <div className={styles.otpContainer}>
          <p>{FIELD_LABELS.ENTER_OTP}</p>
          <Controller
            control={control}
            name="otp"
            rules={{
              required: true,
              minLength: CONSTANTS.OTP_LENGTH,
              maxLength: CONSTANTS.OTP_LENGTH,
            }}
            render={({ field: { onChange, value } }) => (
              <OtpInput
                value={value}
                onChange={onChange}
                numInputs={CONSTANTS.OTP_LENGTH}
                renderInput={(props) => (
                  <input {...props} style={undefined} className={styles.otpInput} />
                )}
                shouldAutoFocus
              />
            )}
          />
        </div>
        {renderResendOtp()}
      </>
    );
  };

  return (
    <div className='overflow-hidden relative'>
      <SignInPageBGSVG className={styles.bgImg} />
      <div className={styles.pageContainer}>
        <div className={styles.titleContainer}>
          <h1>{STATIC_TEXT.HERO_TITLE}</h1>
          <h6>{STATIC_TEXT.HERO_SUBTITLE}</h6>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputCard}>
            <div className={styles.inputCardContent}>
              <h4>{FIELD_LABELS.SIGNUP_LOGIN}</h4>
              <div
                className={`${isFocused ? 'border-black' : 'border-gray-200'} ${
                  styles.phoneInput
                }`}>
                <span>{STATIC_TEXT.INDIA_CODE}</span>
                <Controller
                  control={control}
                  name="phoneNumber"
                  rules={{
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <input
                      className={`${styles.phoneInputField} ${isOtpSent ? 'text-gray-500' : null}`}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      type="tel"
                      maxLength={10}
                      value={value}
                      onChange={onChange}
                      readOnly={isOtpSent}
                      placeholder={FIELD_PLACEHOLDERS.MOBILE}
                    />
                  )}
                />
              </div>
              {isOtpSent && renderOtpInput()}
              {errorMessage && <p className="error-text">{errorMessage}</p>}
              <Button
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
                loading={isOtpSent ? isValidatingOtp || isUserLogging : isSendingOtp}>
                {FIELD_LABELS.CONTINUE}
              </Button>
              <div className={styles.termsText}>
                {STATIC_TEXT.AGREE_OUR_TC}{' '}
                <a
                  href={CONSTANTS.TC_URL}
                  className={styles.emailLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  {STATIC_TEXT.TERMS_AND_CONDITIONS}
                </a>
                <br />
                {STATIC_TEXT.CONTACT_OUR_EMAIL}
                <br />
                <a href={`mailto:${CONSTANTS.CONTACT_EMAIL}`} className={styles.emailLink}>
                  {CONSTANTS.CONTACT_EMAIL}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
