import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoSVG } from 'assets/src/svgs/header_logo.svg';
import { ReactComponent as FacebookSVG } from 'assets/src/svgs/facebook.svg';
import { ReactComponent as TwitterSVG } from 'assets/src/svgs/twitter.svg';
import { ReactComponent as InstagramSVG } from 'assets/src/svgs/instagram.svg';
import { ReactComponent as LinkedInSVG } from 'assets/src/svgs/linkedin.svg';

import styles from './Footer.module.css';
import {
  CONSTANTS,
  FIELD_LABELS,
  HomeScreens,
  PAGE_TITLES,
  ProfileScreens,
  PropertyScreens,
  STATIC_TEXT,
} from '@frontend/common';

function Footer() {
  const navigate = useNavigate();

  const goToHomePage = () => navigate(HomeScreens.DASHBOARD);
  const goToProfile = () => navigate(ProfileScreens.PROFILE);
  const goToSubscriptions = () => navigate(HomeScreens.SUBSCRIPTION);
  const goToAddProperty = () => navigate(PropertyScreens.ADD_PROPERTY);
  const goToTransactions = () => navigate(ProfileScreens.TRANSACTIONS);
  const goToMyProperties = () => navigate(PropertyScreens.MY_PROPERTIES);

  return (
    <footer className="bg-black xl:px-12 py-12 xl:pt-24">
      <div className="w-full px-4 mx-auto max-w-2xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
        <div className="grid gap-12 md:grid-cols-3 xl:grid-cols-4 xl:gap-18">
          <div className="md:col-span-3 xl:col-span-1">
            <button onClick={goToHomePage}>
              <div className="flex flex-col items-center">
                <LogoSVG className="w-full h-12" />
                <a
                  className="my-4 w-full"
                  href={'https://apps.apple.com/in/app/mahaenotice/id6504361708'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../../../../../assets/src/images/download_app_store.png')}
                    alt="App Store"
                    className='w-full'
                  />
                </a>
                <a
                  className="my-4 w-full"
                  href={'https://play.google.com/store/apps/details?id=com.mahaenotice.app'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../../../../../assets/src/images/download_google_play.png')}
                    alt="Play Store"
                    className='w-full'
                  />
                </a>
              </div>
            </button>
          </div>
          <div className="md:col-span-1 xl:col-span-1">
            <ul>
              <li className="mb-4">
                <a
                  className={styles.option}
                  href={CONSTANTS.ABOUT_URL}
                  target="_blank"
                  rel="noreferrer">
                  {FIELD_LABELS.ABOUT_US}
                </a>
              </li>
              <li className="mb-4">
                <a
                  className={styles.option}
                  href={CONSTANTS.TC_URL}
                  target="_blank"
                  rel="noreferrer">
                  {STATIC_TEXT.TERMS_AND_CONDITIONS}
                </a>
              </li>
              <li className="mb-4">
                <a
                  className={styles.option}
                  href={CONSTANTS.PRIVACY_URL}
                  target="_blank"
                  rel="noreferrer">
                  {STATIC_TEXT.PRIVACY_POLICY}
                </a>
              </li>
              <li className="mb-4">
                <a
                  className={styles.option}
                  href={CONSTANTS.CONTACT_URL}
                  target="_blank"
                  rel="noreferrer">
                  {FIELD_LABELS.CONTACT_US}
                </a>
              </li>
            </ul>
          </div>
          <div className="md:col-span-1 xl:col-span-1">
            <ul>
              <li className="mb-4">
                <button className={styles.option} onClick={goToAddProperty}>
                  {FIELD_LABELS.ADD_PROPERTY}
                </button>
              </li>
              <li className="mb-4">
                <button className={styles.option} onClick={goToSubscriptions}>
                  {PAGE_TITLES.BUY_SUBSCRIPTION}
                </button>
              </li>
              <li className="mb-4">
                <button className={styles.option}>Infinite Plan Coming Soon...</button>
              </li>
              <li className="mb-4">
                <button className={styles.option} onClick={goToProfile}>
                  {FIELD_LABELS.MY_ACCOUNT}
                </button>
              </li>
              <li className="mb-4">
                <button className={styles.option} onClick={goToTransactions}>
                  {FIELD_LABELS.MY_TRANSACTIONS}
                </button>
              </li>
              <li className="mb-4">
                <button className={styles.option} onClick={goToMyProperties}>
                  {STATIC_TEXT.MY_PROPERTIES}
                </button>
              </li>
              <li className="mb-4">
                <button className={styles.option}>{FIELD_LABELS.SUPPORT}</button>
              </li>
            </ul>
          </div>
          <div className="md:col-span-3 xl:col-span-1">
            <div>
              <h6 className={styles.sectionHeader}>{FIELD_LABELS.FIND_ONLINE}</h6>
              <div className="flex items-center">
                <button>
                  <FacebookSVG className={styles.socialIcon} />
                </button>
                <button>
                  <TwitterSVG className={styles.socialIcon} />
                </button>
                <button>
                  <InstagramSVG className={styles.socialIcon} />
                </button>
                <button>
                  <LinkedInSVG className={styles.socialIcon} />
                </button>
              </div>
            </div>
            <div className="mt-8">
              <h6 className={styles.sectionHeader}>{FIELD_LABELS.CORPORATE_OFFICE}</h6>
              <p className="text-white text-xs sm:text-sm leading-6 font-normal">
                {CONSTANTS.CONTACT_ADDRESS}
              </p>
              <div className="mt-4">
                <p className="text-white font-medium text-xs">&copy; MAHA eNotice</p>
                <p className="text-white font-medium text-xs">All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
