import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS, GET_NOTICE_DETAILS, USE_CREDIT_NOTICE, SHARE_NOTICE_PDF } from '../../types/endpoints';
import { FetchMethods } from '../../types/common';
import {
  NoticeListResponse,
  NoticeSearchParams,
  NoticeDetailsResponse,
  NoticeDetailsParams,
  SearchFilterResponse,
  NoticeSearchHistoryResponse,
  NoticeShareResponse,
  TodayNoticeList
} from '../../types/notice';
import { MasterDataResponse } from '../../types/master';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';

export const noticeApi = createApi({
  reducerPath: 'noticeApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    getNoticeTypes: builder.query<MasterDataResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.NOTICE_TYPES,
        method: FetchMethods.GET
      }),
    }),
    searchNotices: builder.mutation<NoticeListResponse, NoticeSearchParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.SEARCH_NOTICES,
        method: FetchMethods.GET,
        params
      }),
    }),
    getAdvanceSearchFilters: builder.mutation<SearchFilterResponse, NoticeSearchParams>({
      query: (params) => ({
        url: STATIC_URL_ENDPOINTS.SEARCH_FILTERS,
        method: FetchMethods.GET,
        params
      }),
    }),
    getSearchHistory: builder.query<NoticeSearchHistoryResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.SEARCH_HISTORY,
        method: FetchMethods.GET
      }),
    }),
    noticeDetails: builder.mutation<NoticeDetailsResponse, NoticeDetailsParams>({
      query: ({ noticeId }) => ({
        url: GET_NOTICE_DETAILS(noticeId),
        method: FetchMethods.GET
      }),
    }),
    useCreditNotice: builder.mutation<void, NoticeDetailsParams>({
      query: ({ noticeId }) => ({
        url: USE_CREDIT_NOTICE(noticeId),
        method: FetchMethods.GET
      }),
    }),
    getNoticesList: builder.query<NoticeListResponse, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.GET_NOTICES_LIST,
        method: FetchMethods.GET,
      }),
    }),
    getTodayNoticesList: builder.query<TodayNoticeList, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.TODAY_NOTICES_LIST,
        method: FetchMethods.GET,
      }),
    }),
    shareNoticePDF: builder.mutation<NoticeShareResponse, NoticeDetailsParams>({
      query: ({ noticeId }) => ({
        url: SHARE_NOTICE_PDF(noticeId),
        method: FetchMethods.GET,
      }),
    }),
    clearSearchHistory: builder.query<void, void>({
      query: () => ({
        url: STATIC_URL_ENDPOINTS.CLEAR_HISTORY,
        method: FetchMethods.GET,
      }),
    }),
  }),
});

export const {
  useGetNoticeTypesQuery,
  useSearchNoticesMutation,
  useNoticeDetailsMutation,
  useUseCreditNoticeMutation,
  useGetAdvanceSearchFiltersMutation,
  useGetSearchHistoryQuery,
  useGetNoticesListQuery,
  useGetTodayNoticesListQuery,
  useShareNoticePDFMutation,
  useLazyClearSearchHistoryQuery
} = noticeApi;
