import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { ReducerKeys, authSlice, secretsSlice, searchSlice, subscriptionSlice } from './slices/index';
import { otpApi, authApi, noticeApi, masterApi, propertyApi, bookmarksApi, dashboardApi, alertsApi } from './api/functions';
import { subscriptionApi } from './api/functions/subscriptionService/subscriptionService';

const authConfig = {
  key: ReducerKeys.AUTH_SLICE,
  storage: AsyncStorage,
};

const searchConfig = {
  key: ReducerKeys.SEARCH_SLICE,
  storage: AsyncStorage,
};

export const rootReducer = combineReducers({
  auth: persistReducer(authConfig, authSlice.reducer),
  secrets: secretsSlice.reducer,
  search: persistReducer(searchConfig, searchSlice.reducer),
  subscription: subscriptionSlice.reducer,
  [otpApi.reducerPath]: otpApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [noticeApi.reducerPath]: noticeApi.reducer,
  [masterApi.reducerPath]: masterApi.reducer,
  [propertyApi.reducerPath]: propertyApi.reducer,
  [bookmarksApi.reducerPath]: bookmarksApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  // [mockApi.reducerPath]: mockApi.reducer, // if needed we can add persist to these api calls
});

export default rootReducer;
