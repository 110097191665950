import { createApi } from '@reduxjs/toolkit/query/react';

import { STATIC_URL_ENDPOINTS } from '../../types/endpoints';
import { FetchMethods } from '../../types/common';
import {
  SendOtpResponse,
  SendOtpRequest,
  ValidateOtpRequest,
  ValidateOtpResponse,
  SendEmailOtpRequest,
  SendEmailOtpResponse,
  ValidateEmailOtpRequest,
  ValidateEmailOtpResponse,
} from '../../types/otp';
import { createDynamicBaseQuery } from '../../helper/createBaseQuery';

export const otpApi = createApi({
  reducerPath: 'otpApi',
  baseQuery: createDynamicBaseQuery(),
  endpoints: (builder) => ({
    sendOtp: builder.mutation<SendOtpResponse, SendOtpRequest>({
      query: ({ mobile }) => ({
        url: STATIC_URL_ENDPOINTS.REQUEST_OTP,
        method: FetchMethods.POST,
        body: {
          mobile,
        },
      }),
    }),
    validateOtp: builder.mutation<ValidateOtpResponse, ValidateOtpRequest>({
      query: ({ mobile, otp }) => ({
        url: STATIC_URL_ENDPOINTS.VALIDATE_OTP,
        method: FetchMethods.POST,
        body: {
          mobile,
          otp,
        },
      }),
    }),
    sendEmailOtp: builder.mutation<SendEmailOtpResponse, SendEmailOtpRequest>({
      query: ({ email }) => ({
        url: STATIC_URL_ENDPOINTS.REQUEST_EMAIL_OTP,
        method: FetchMethods.POST,
        body: {
          email,
        },
      }),
    }),
    validateEmailOtp: builder.mutation<ValidateEmailOtpResponse, ValidateEmailOtpRequest>({
      query: ({ email, otp }) => ({
        url: STATIC_URL_ENDPOINTS.VALIDATE_EMAIL_OTP,
        method: FetchMethods.POST,
        body: {
          email,
          otp,
        },
      }),
    }),
  }),
});

export const { useSendOtpMutation, useValidateOtpMutation, useSendEmailOtpMutation, useValidateEmailOtpMutation } = otpApi;
