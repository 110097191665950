import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import FlatList from 'flatlist-react';

import { Button, Spinner } from '@frontend/web-components';
import { Notice } from '@frontend/redux';

import NoticeItem from './NoticeItem';
import styles from '../Notice.module.css';
import { FIELD_LABELS, STATIC_TEXT } from '@frontend/common';

type NoticeListProps = {
  errorMessage?: string;
  isFirstPage?: boolean;
  isLastPage?: boolean;
  loadMoreNotices?: (isReset: boolean) => void;
  loading: boolean;
  loadingMore?: boolean;
  notices: Notice[];
  pagination?: boolean;
};

export default function NoticeList(props: NoticeListProps) {
  // const location = useLocation();
  // const routeParams = location.state || {};
  // const pathname = location.pathname;

  const {
    notices,
    errorMessage,
    loadMoreNotices,
    loading,
    pagination,
    loadingMore,
    isFirstPage,
    isLastPage,
  } = props;

  const handleLoadMoreItems = () => {
    if (pagination && loadMoreNotices) {
      loadMoreNotices(false);
    }
  };

  const renderErrorMessage = () => {
    return (
      <div className="error-text">
        <p>{errorMessage}</p>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className={styles.centerContainer}>
        <p className="empty-text">{STATIC_TEXT.NO_NOTICES_FOUND}</p>
      </div>
    );
  };

  const renderNoticeItem = useCallback((item: Notice) => <NoticeItem key={item.id} item={item} />, []);

  const renderFooter = () => {
    if (!pagination || (pagination && isLastPage) || errorMessage) {
      return null;
    }

    return (
      <div className={styles.centerContainer}>
        <Button onClick={handleLoadMoreItems} loading={loadingMore}>
          {FIELD_LABELS.LOAD_MORE}
        </Button>
      </div>
    );
  };

  if (loading && isFirstPage) {
    return <Spinner modal />;
  }

  return (
    <>
      <div className={styles.listContainer}>
        <FlatList
          list={notices}
          renderItem={(item: Notice) => renderNoticeItem(item)}
          keyExtractor={(item: Notice) => item.id}
          showsVerticalScrollIndicator={false}
          renderWhenEmpty={errorMessage ? renderErrorMessage : renderEmptyMessage}
        />
      </div>
      {renderFooter()}
    </>
  );
}
