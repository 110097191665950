import { configureStore, Middleware } from '@reduxjs/toolkit';
import rootReducer from './root-slice';
import { otpApi, authApi, noticeApi, masterApi, subscriptionApi, propertyApi, bookmarksApi, dashboardApi, alertsApi } from './api/functions';
import { apiLogger } from './api/helper/apiLogger';
import { resetMiddleware } from './api/helper/resetMiddleware';

const apiList = [otpApi, authApi, noticeApi, masterApi, subscriptionApi, propertyApi, bookmarksApi, dashboardApi, alertsApi];
// Define the custom middleware with your API slices
const resetOnLogoutMiddleware: Middleware = resetMiddleware(apiList);

const allMiddlewares: Middleware[] = apiList.map(api => api.middleware).concat([
  resetOnLogoutMiddleware,
  // apiLogger
]);

export default function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(allMiddlewares),
    devTools: process.env.NODE_ENV !== 'production',
  });
  return store;
}
