interface DynamicTextProps {
  replaceArray: Array<{ key: string; value: string }>;
  text: string;
}

/**
 * @param {DynamicTextProps}
 * @returns {string}
 * @description returns changed text
 * @example getDynamicText({
          text: STATIC_TEXT.name,
          replaceArray: [{ key: 'username', value: 'User' }],
        });
 */
export function getDynamicText({ text, replaceArray }: DynamicTextProps) {
  let dynamicText = text;
  replaceArray.forEach((item) => {
    dynamicText = dynamicText.replace(`{${item.key}}`, item.value);
  });
  return dynamicText;
}
